import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { allSupportedLanguages, allSupportedCountries } from "../features/languages/allSupportedLanguages";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../config/localStorageEncryption";

let key = process.env.REACT_APP_AZURE_CONNECTION_KEY;
let endpoint = process.env.REACT_APP_AZURE_WEB_TEXT_API;
let location = process.env.REACT_APP_AZURE_SERVICE_LOCATION;

export async function detectIPAdress() {
  let ip = '';
  try {
    const { data } = await axios({
      url: "https://api.ipgeolocation.io/getip",
      method: "GET",
    });

    ip = data.ip;
  } catch (error) {
    console.log(`Error occured while detecting IP address: ${error}`)
  } finally {
    console.log("returning ip",ip)
    return ip;
  }
}

export async function getUserLocationData() {
  try {
    const { data } = await axios({
      url: `https://api.ipgeolocation.io/ipgeo`,
      method: "GET",
      params: {
        apiKey: process.env.REACT_APP_GEOLOCATION_API_KEY,
        ip: await detectIPAdress(),
      },
    });

  const citiesData = await axios({
    url: `https://countriesnow.space/api/v0.1/countries/cities`,
    method: "POST",
    data: {
      country: data.country_name,
    },
  });
  const currentDate = new Date();
  const userAddressUpdatingTime = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);// one week from now
  const userLocationData = {
    countryName: data.country_name,
    city: data.city,
    state_prov: data.state_prov,
    usercordinates: { latitude: data.latitude, longitude: data.longitude },
    userCountryCities: citiesData.data.data.slice(0,10),
    userAddressUpdatingTime
  };

    return userLocationData;
  } catch (error) {
    console.log(`Error occured while getting user location data: ${error}`)
    return {}
  }
}

export const getUserLocationDetailByName = async (locationDetail,updateUserAddress=false) => {
  const userLocationDetailsAvailable = getLocalStorageItem(
    "userLocationDetails"
  );
  if (!userLocationDetailsAvailable || updateUserAddress) {
    const userAddressInformation = await getUserLocationData();
    setLocalStorageItem("userLocationDetails", userAddressInformation);
    return userAddressInformation[`${locationDetail}`];
  } else {
    return userLocationDetailsAvailable[`${locationDetail}`];
  }
};

export const updateUserLocationDetailWeekly=async()=>{
  let userAddressUpdatingTime=await getUserLocationDetailByName("userAddressUpdatingTime");
  userAddressUpdatingTime=new Date(userAddressUpdatingTime);
  const currentDate = new Date();
  if(userAddressUpdatingTime<currentDate){
    await getUserLocationDetailByName("userAddressUpdatingTime",true)
    return true
  }else{
    return true
  }
}
export const getUserSocketIoRoomId = () =>
  getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")?.userSocketIoRoomId
    : null;

export const setUserLang = () => {

  let countryFromAllowYourLocation = "";

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const apiKey = "AIzaSyAUWDMPVarPjXcb4GsykDwETFzz1FHxmUI";
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;
        
        fetch(geocodingUrl)
          .then((r) => r?.json())
          .then((data) => {
            if(data?.results?.length) {
              const addressComponents = data?.results[0].address_components;
              const countryComponent = addressComponents.find(component => component.types.includes('country'));
              countryFromAllowYourLocation = countryComponent?.long_name
            }
          })
      },
    );
  }

  let userLang = navigator.language.split("-")[0];
  const isSupported = allSupportedLanguages.some((lang) => lang.code === userLang);

  const selectUserLangFromGeoLocationCountryName = getLocalStorageItem('userLocationDetails')?.countryName || countryFromAllowYourLocation;
  let selectLangFromLocationCountry = "";

  if(allSupportedCountries?.length) {
    allSupportedCountries?.forEach((item) => {
      if(item?.countries?.length) {
        item?.countries?.forEach((country) => {
          if(selectUserLangFromGeoLocationCountryName || countryFromAllowYourLocation) {
            if(country === selectUserLangFromGeoLocationCountryName || country === countryFromAllowYourLocation) {
              selectLangFromLocationCountry = item?.code
            }
          }
        })
      }
    })
  }

  if(!localStorage.getItem("userLang")) {
    if(selectUserLangFromGeoLocationCountryName === "Bulgaria") {
      localStorage.setItem("userLang", "bg");
      userLang = "bg"
    } else if(selectLangFromLocationCountry !== "") {
      userLang = selectLangFromLocationCountry;
      localStorage.setItem("userLang", selectLangFromLocationCountry);
    } else {
      userLang = navigator.language.split("-")[0];
      localStorage.setItem("userLang", isSupported ? userLang : "en");
    }

    return userLang;
  }
};

export const getUserLang = () => {
  if (!localStorage.getItem("userLang")) {
    let userLang = setUserLang();
    return userLang;
  } else {
    return localStorage.getItem("userLang");
  }
};

export const getUserInBulgariaDefaultLang = () =>
  localStorage.getItem("userInBulgariaDefaultLang");

export async function translateText(text, targetLang) {
  const body = [
    {
      text,
    },
  ];

  const { data } = await axios({
    baseURL: endpoint,
    url: "/translate",
    method: "post",
    headers: {
      "Ocp-Apim-Subscription-Key": key,
      "Ocp-Apim-Subscription-Region": location,
      "Content-type": "application/json",
      "X-ClientTraceId": uuidv4().toString(),
    },
    params: {
      "api-version": "3.0",
      from: "bg",
      to: `${targetLang}`,
    },
    data: body,
  });

  return data[0].translations[0].text;
}

export async function translateElements(targetLang) {
  const elements = document.getElementsByClassName("translate");
  const translations = JSON.parse(localStorage.getItem("translations")) || [];

  for (let element of elements) {
    if (
      element.tagName.toLowerCase() === "input" &&
      element.hasAttribute("placeholder")
    ) {
      const originalPlaceholder = element.getAttribute("placeholder").trim();

      let translatedPlaceholder = translations.find(
        (t) =>
          t.originalText === originalPlaceholder && t.targetLang === targetLang
      )?.translatedText;

      if (!translatedPlaceholder) {
        if (targetLang !== "bg") {
          translatedPlaceholder = await translateText(
            originalPlaceholder,
            targetLang
          );
        } else {
          translatedPlaceholder = originalPlaceholder;
        }
        translations.push({
          originalText: originalPlaceholder,
          translatedText: translatedPlaceholder,
          targetLang,
        });
        localStorage.setItem("translations", JSON.stringify(translations));
      }

      element.setAttribute("placeholder", translatedPlaceholder);
    } else {
      const originalText = element.textContent.trim();
      let translatedText = translations.find(
        (t) => t.originalText === originalText && t.targetLang === targetLang
      )?.translatedText;

      if (!translatedText) {
        if (targetLang !== "bg") {
          translatedText = await translateText(originalText, targetLang);
        } else {
          translatedText = originalText;
        }

        translations.push({ originalText, translatedText, targetLang });
        localStorage.setItem("translations", JSON.stringify(translations));
      }

      element.textContent = translatedText;
    }
  }
}

export async function translateOnload() {
  try {
    let countryName = await getUserLocationDetailByName("countryName");
    let userInBulgariaDefaultLang = localStorage.getItem(
      "userInBulgariaDefaultLang"
    );
    let userLang = getUserLang();
    checkLocalStorageSpace();

    let shouldTranslate = true;

    if (countryName === "Bulgaria" && !userInBulgariaDefaultLang) {
      shouldTranslate = false;
      localStorage.setItem("userInBulgariaDefaultLang", "true");
      localStorage.setItem("userLang", "bg");
    }

    if (shouldTranslate) {
      await translateElements(userLang);
    }
  } catch (error) {
    console.log(`Errorr occured while translating elements on load: ${error}`);
  }
}

// Function to Change Langage
export async function changeLanguage(targetLang) {
  localStorage.setItem("userLang", targetLang);

  localStorage.setItem("userInBulgariaDefaultLang", "false");

  window.location.reload();

  await translateElements(targetLang);
}

// Check if we have enough space in local storage
export function checkLocalStorageSpace() {
  const totalSpace = 5 * 1024 * 1024; // Total storage space in bytes (e.g., 5 MB)
  const usedSpace = JSON.stringify(localStorage).length * 2; // Approximate used space in bytes

  const remainingSpace = totalSpace - usedSpace;

  if (remainingSpace < 1024) {
    console.log("Local storage is almost full");
  }
}

// Translate Single Element
export async function translateSingleElement(text) {
  let userLang=getUserLang()
  const translatedText = await translateText(text, userLang);

  return translatedText;
}
