import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import instance from "../../config/axiosConfig";


const initialState = {
    properties: [],
    isLoading: false,
}

const getToken = () => Cookie.get("userToken");
const token = getToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

export const createphotographerVirtualTourDraftApi = createAsyncThunk(
    'serviceRequestVirtualTours/createphotographerVirtualTourDraft',
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add("open");
        try {
            const body = {
                sourceVirtualTourId: payload?.virtualTour.virtualTourId,
            }
            const response = await instance.post(`/virtual-tours/photographer/create-draft-virtual-tour`, body, config);
            document.getElementById("loading-indicator").classList.remove("open");
            return response;
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove("open");
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

export const requestReviewApi = createAsyncThunk(
    'serviceRequestVirtualTours/requestReview',
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add("open");
        try {
            const body = {
                virtualTourId: payload.photographerVirtualTourDraft.virtualTourId,
            }
            const response = await instance.post(`/virtual-tours/photographer/request-review-to-seller`, body, config);
            document.getElementById("loading-indicator").classList.remove("open");
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

export const reviewApi = createAsyncThunk(
    'serviceRequestVirtualTours/review',
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add("open");
        try {
            const body = {
                virtualTourId: payload.photographerVirtualTourDraft.virtualTourId,
                review: payload.review,
            }
            const response = await instance.post(`/virtual-tours/seller/review-photographer-virtual-tour`, body, config);
            document.getElementById("loading-indicator").classList.remove("open");
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)


const serviceRequestVirtualToursSlice = createSlice({
    name: 'serviceRequestVirtualTours',
    initialState,
    reducers: {
        resetServiceProperties: (state, action) => {
            return {
                properties: [],
            }
        },
        setProperties: (state, action) => {
            return {
                properties: action.payload,
            }
        },
        setProperty: (state, action) => {
            let {propertyId} = action.payload;
            var properties = state.properties;
            // find index
            let index = properties.findIndex(property => property.propertyId === propertyId);
            // update property
            properties = [
                ...properties.slice(0, index),
                action.payload,
                ...properties.slice(index + 1)
            ]
            return {
                properties: properties,
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createphotographerVirtualTourDraftApi.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(createphotographerVirtualTourDraftApi.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(createphotographerVirtualTourDraftApi.rejected, (state, action) => {
            state.isLoading = false;
            toast.error(action.payload?.error);
        })
        builder.addCase(requestReviewApi.fulfilled, (state, action) => {
            state.isLoading = false;
            toast.success('Review requested successfully');
        })
        builder.addCase(requestReviewApi.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(requestReviewApi.rejected, (state, action) => {
            state.isLoading = false;
            toast.error(action.payload?.error);
        })
        builder.addCase(reviewApi.fulfilled, (state, action) => {
            state.isLoading = false;
            toast.success('Review submitted successfully');
        })
    },
})

export const {
    resetServiceProperties,
   setProperties,
   setProperty,
} = serviceRequestVirtualToursSlice.actions;

export default serviceRequestVirtualToursSlice.reducer;