import { getLocalStorageItem } from "../config/localStorageEncryption";

const activePersonaType = (type) => {
    const activePersona = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser")?.activePersona : null;

    /* Declare persona condition */
    switch (type) {
        case 'is_empty_persona':
            return activePersona === null;

        case 'is_lawyer':
            return activePersona === "lawyer";

        case 'is_photographer':
            return activePersona === "photographer";

        case 'is_virtual_assistant':
            return activePersona === "virtualAssistant" || activePersona === "virtualassistant";

        case 'is_buyer':
            return activePersona === "buyer";

        case 'is_seller':
            return activePersona === "seller";

        case 'active_persona':
            return activePersona;

        default:
            return;
    }
}

export default activePersonaType;