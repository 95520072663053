import Videos from "../assets/uploaded_videos.json";
import Images from "../assets/uploaded_images.json";
import {
  getUserLocationDetailByName,
  getUserLang,
} from "../hooks/useTranslator";
import { allSupportedLanguages } from "../features/languages/allSupportedLanguages";

export const getProperVirtualEducationVideo = () => {
  /**
   * Get user language
   */

  let userLang = getUserLang();
  let isSupported = false;
  let virtualAssistantVideo = Images["video.mp4"];

  getUserLocationDetailByName("userCountry").then((userCountryOfOrigin) => {
    if (!userLang && userCountryOfOrigin === "Bulgaria") {
      userLang = "bg";
    }

    localStorage.setItem("videoLang", userLang);

    /**
     *Check if user langaue is supported
     */

    if (allSupportedLanguages.find((lang) => lang.code === userLang)) {
      isSupported = true;
    }

    /**
     * Get proper video url
     */

    if (isSupported && userCountryOfOrigin !== "Bulgaria") {
      virtualAssistantVideo = Videos[userLang];
    } else if (userCountryOfOrigin === "Bulgaria") {
      virtualAssistantVideo = Images["video.mp4"];
    }
  });

  return virtualAssistantVideo;
};
