import { getLocalStorageItem } from "../../../config/localStorageEncryption";

export const supportedLanguages = [
  {
    code: "fr",
    name: "Français",
    nativeName: "Français",
  },
  {
    code: "fr-CA",
    name: "Français (Canada)",
    nativeName: "Français (Canada)",
  },
  {
    code: "ru",
    name: "Russe",
    nativeName: "Русский",
  },
  {
    code: "de",
    name: "Allemand",
    nativeName: "Deutsch",
  },
  {
    code: "pt-PT",
    name: "Portugais (Portugal)",
    nativeName: "Português (Portugal)",
  },
];

const activePersonna = getLocalStorageItem("user")?.activePersona;

if (activePersonna === "seller") {
  supportedLanguages.push({
    code: "el",
    name: "Grec",
    nativeName: "Ελληνικά",
  });
}
