import React, { Fragment } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Images from "../../../assets/uploaded_images.json";

/* Icons */
import { IoMenuOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";

/* Images */
const AddIconProperty = Images["add-property-icon.svg"];

const SideBar = () => {
    const { t } = useTranslation()
    const [state, setState] = React.useState({
        right: false,
    });

    /* Toggle responsive open menu */
    const toggleDrawer = (anchor, open) => (event) => {
        if ( event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <Fragment>
            {["right"].map((anchor) => (
                <Fragment key={anchor}>
                    <IoMenuOutline color="#00BCD4" className="menu-burger" size={40} onClick={toggleDrawer(anchor, true)} />

                    <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)} >
                        <div className="modal-header">
                            <span onClick={toggleDrawer(anchor, false)} className="closebutton">
                                <AiOutlineClose />
                            </span>
                        </div>

                        <div>
                            <div className="modal-body">
                                <Link to="/" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Начало")}
                                </Link>

                                <Link to="/search-properties" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Търси имот")}
                                </Link>

                                <Link to="/third-party?lawyers" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Професионалисти")}
                                </Link>

                                <Link to="/pricing" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Ценообразуване")}
                                </Link>
                                
                                <Link to="/blog" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Блог")}
                                </Link>

                                <Link to="/login" className="add-property-modal modal-link" style={{ cursor: "pointer" }} onClick={toggleDrawer(anchor, false)}>
                                    <img loading="lazy" src={AddIconProperty} alt="" />

                                    <span>{t("Добави имот")}</span>
                                </Link>

                                {/* <Link to="/login" className="modal-link login-modal" onClick={toggleDrawer(anchor, false)}>
                                    {t("Вход")}
                                </Link>

                                <Link to="/registration-about" className="modal-link register-modal" onClick={toggleDrawer(anchor, false)}>
                                    {t("Регистрация")}
                                </Link> */}
                            </div>
                        </div>
                    </SwipeableDrawer>
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SideBar;