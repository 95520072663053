import { Dialog, Tooltip } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { propertyViewDetails } from "../../../features/common/propertyViewSlice";
import MasonryGallery from "./MasonryGallery/MasonryGallery";
import Images from "../../../assets/uploaded_images.json";
import { t } from "i18next";
import "./PropertyViewModal.css";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GoogleMapReact from "google-map-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMediaQuery as useMediaQuery2 } from "react-responsive";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
const { useStyles } = require("./styles");
const VideoPlayIcon = Images["play.svg"];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapState = ({ propertyViewSlice, statisticsSlice }) => ({
  isOpenModal: propertyViewSlice?.isOpenModal,
  activeProperty: propertyViewSlice?.activeProperty,
  isChrome: statisticsSlice?.isChrome,
});

const CloseModal = Images["close-modal.svg"];
const AbidorsPin = Images["abidorsPin.png"];
const DefaultImage = Images["common-image.jpg"];

function PropertyViewModal() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyVideo, setPropertyVideo] = useState();
  const is527pxReached = useMediaQuery2({ query: "(max-width: 527px)" });
  const is500pxReached = useMediaQuery2({ query: "(max-width: 500px)" });
  const handleClose = () => {
    dispatch(
      propertyViewDetails({
        isOpenModal: false,
      })
    );
  };
  const [shouldPlay, setShouldPlay] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = useRef(null);
  const mapRef = useRef(null);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const { isOpenModal, activeProperty, isChrome } = useSelector(mapState);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoadedData = () => {
    setTimeout(() => {
      setIsVideoLoading(false);
    }, 1000);
    if (videoRef.current) {
      videoRef.current.currentTime = 1;
      if (shouldPlay) {
        videoRef.current.play();
        setShouldPlay(false);
      }
    }
  };

  /* Tooltip title */
  const title = () => {
    let mappingPropertyMedia = [];

    activeProperty?.propertyMedia?.map((item) =>
      item?.type === "image" ? mappingPropertyMedia.push(item) : null
    );

    return (
      <div className="modal-marker-details">
        <img
          loading="lazy"
          src={
            mappingPropertyMedia?.length
              ? mappingPropertyMedia[0]?.documentUrl
              : DefaultImage
          }
          alt=""
        />
      </div>
    );
  };

  const handlePlayPause = () => {
    setPlaying((prev) => {
      return !prev;
    });

    const video = videoRef.current;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
  };

  useEffect(() => {
    if (value === 1 && propertyVideo) {
      const video = videoRef.current;

      const handlePlay = () => {
        setPlaying(true);
      };

      const handlePause = () => {
        setPlaying(false);
      };

      const handleEnded = () => {
        setPlaying(false);
      };

      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);

      return () => {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
      };
    }
  }, [value]);

  /* CENTER MAP */
  const [center, setCenter] = useState({});

 /* Custom marker */
 const Marker = ({ lat, lng }) => {
  return (
      <Tooltip enterTouchDelay={0} classes={{ tooltip: classes.customTooltip }} title={title()}>
          <>
              <div
                  className="marker"
                  style={{
                      backgroundImage: `${zoomCurrect <= 13 ? `url(${AbidorsPin})` : `url("https://d3kuhob3u5bpy2.cloudfront.net/06edf9ab-618c-438c-b1b9-e96533009342.svg")`}`,
                      backgroundPosition: "center",
                      width: "36px",
                      height: "30px",
                      backgroundSize: "contain",
                      backgroundOrigin: 'content-box',
                      backgroundRepeat: "no-repeat",
                      borderRadius: "50%",
                      zIndex: 99999,
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                  }} lat={lat} lng={lng}
              />

              {zoomCurrect <= 13 ? <div className="ping" /> : null}
          </>

      </Tooltip>
  );
};

  const defaultCenter = {
    lat: 42.69751,
    lng: 23.32415,
  };

    const optionsZoom = {
      maxZoom: 100,
      mapTypeControl: true
    };

  /* ZOOM MAP */
  const zoom = 10;

  useEffect(() => {
    let newVal = defaultCenter;

    if (activeProperty?.locationLatitude && activeProperty?.locationLongitude) {
      newVal = {
        lat: Number(activeProperty?.locationLatitude),
        lng: Number(activeProperty?.locationLongitude),
      };
    }

    let images = activeProperty?.propertyMedia
      ?.map((media) => {
        if (media.type === "image") {
          return media?.documentUrl;
        }
      })
      ?.filter((media) => media && media);
    setPropertyImages(images);
    let propertyVideo = activeProperty?.propertyMedia?.find(
      (media) => media.type === "video"
    )?.documentUrl;
    setPropertyVideo(propertyVideo);
    setCenter(newVal);
  }, [isOpenModal]);

  useEffect(() => {
    const { newLat, newLng } = getRandomCoordinatesInRadius(parseFloat(activeProperty?.locationLatitude), parseFloat(activeProperty?.locationLongitude), 300);

    setRandomCoordinates({
        randomLat: newLat,
        randomLng: newLng,
    })
  }, [])

  const [zoomCurrect, setZoomCurrent] = useState(10);
  const [randomCoordinates, setRandomCoordinates] = useState({});

  function getRandomCoordinatesInRadius(lat, lng, radiusInMeters) {
    const earthRadiusInMeters = 6371000; 

    const randomDirection = Math.random() * 2 * Math.PI;
    const randomDistance = Math.random() * radiusInMeters;

    const deltaLatitude = randomDistance * Math.cos(randomDirection) / earthRadiusInMeters;
    const deltaLongitude = randomDistance * Math.sin(randomDirection) / (earthRadiusInMeters * Math.cos(lat * (Math.PI / 180)));

    const newLat = lat + (deltaLatitude * (180 / Math.PI));
    const newLng = lng + (deltaLongitude * (180 / Math.PI));

    return { newLat, newLng };
}

  /* Handle change full scree view icon map and remove text overlay map */
  const handleApiLoaded = (map, maps) => {
      mapRef.current = map;
          new maps.Circle({
              strokeColor: "rgba(1, 35, 36, 1)",
              strokeOpacity: 1,
              strokeWeight: 5,
              fillColor: "rgba(1, 35, 36, 0.4)",
              fillOpacity: 0.2,
              map,
              center: { lat: randomCoordinates.randomLat, lng: randomCoordinates.randomLng },
              radius: 300,
          });

      maps.event.addListener(map, "zoom_changed", () => {
          const zoom = map.getZoom();
          setZoomCurrent(zoom)
      });
  };

  return (
    <Dialog
      disableScrollLock={false}
      onClose={handleClose}
      open={isOpenModal}
      fullWidth={fullScreen}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "xl"}
      PaperProps={{
        style: {
          width: "100%",
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          borderRadius: fullScreen ? "0px" : "10px",
          padding: fullScreen ? "0px" : "10px",
        },
      }}
    >
      <div className="dialog-property-view-modal-content">
        <div className="title-header">
          <h4 className="title">{t("Изглед към имота")}</h4>
          <div className="close-icon" onClick={handleClose}>
            <CloseIcon htmlColor="black" fontSize="15px" />
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("Images")} {...a11yProps(0)} />
              <Tab label={t("Video")} {...a11yProps(1)} />
              <Tab label={t("Map")} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MasonryGallery images={propertyImages} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {propertyVideo ? (
              <div className="property-video">
                <div className="video-play-button-container">
                  {!isPlaying && isChrome && (
                    <img
                      loading="lazy"
                      className="video-play-button"
                      onClick={handlePlayPause}
                      src={VideoPlayIcon}
                      alt=""
                    />
                  )}
                </div>
                {isVideoLoading && (
                  <div
                    className="property-video-loader"
                    style={{
                      backgroundColor: "black",
                      zIndex: 999999,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "700px",
                      width: "100%",
                      position: "absolute",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundPosition: "0%",
                      backgroundImage:
                        `url("${propertyImages[0]}"`,
                    }}
                  >
                    <div className="video-play-button-container">
                      <img
                        loading="lazy"
                        className="video-play-button"
                        src={VideoPlayIcon}
                        alt=""
                      />
                      <div
                        className="circular-loader"
                        onClick={handlePlayPause}
                      >
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress
                            size={
                              is500pxReached ? 47 : is527pxReached ? 58.5 : 90
                            }
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                )}
                <video
                  className="videoContainer"
                  src={propertyVideo}
                  onLoadedData={handleLoadedData}
                  onPlay={() => setPlaying(true)}
                  autoPlay={false}
                  style={{ height: "100%", width: "100%" }}
                  controls
                  ref={videoRef}
                />
              </div>
            ) : (
              <div
                style={{ minHeight: "100%", minWidth: "100%", display: "flex" }}
              >
                {t("Няма наличен видеозапис на имота")}
              </div>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="maps" style={{ borderRadius: '10px', overflow: 'hidden'  }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                center={center}
                language="bg"
                defaultZoom={zoom}
                options={optionsZoom}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}

              >
                <Marker
                  lat={randomCoordinates?.randomLat}
                  lng={randomCoordinates?.randomLng}
                  name={t("Приблизителна локация")}
                />
              </GoogleMapReact>
            </div>
          </CustomTabPanel>
        </Box>
      </div>
    </Dialog>
  );
}

export default PropertyViewModal;
