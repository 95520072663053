import React from 'react'
import Images from "../../../assets/uploaded_images.json";
import "./BookIcon.css"
const BookVA = Images["BookVA.svg"];
function BookIcon({onClick}) {

  return (
    <img src={BookVA} className='book-image-component' onClick={()=>{
      if(onClick){
        onClick()
      }
    }}/>
  )
}

export default BookIcon