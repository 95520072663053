import Cookie from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { setLocalStorageItem } from '../config/localStorageEncryption';

function decodeToken(token) {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    return null;
  }
}


export function isTokenExpired(token) {
  const decodedToken = decodeToken(token);

  if (decodedToken && decodedToken.exp) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTimestamp;
  }

  return true; 
}

export function processSocialLoginResult(result,userCountryName){

  const userToken = result?.token

  
  const {
    activePersona,
    createdAt,
    email:userEmail,
    familyName,
    isActive,
    name,
    phoneNumber,
    profilePicture,
    unsubscribedEmailNotifications,
    userCountry,
    userId
  } = result?.User;

  const activePersonaDetails = result?.activePersonaDetails;
  const authenticatedUser = { userId, activePersona, userEmail, familyName, createdAt, isActive, name, phoneNumber, profilePicture, unsubscribedEmailNotifications,userCountry };
  
  if(!authenticatedUser["userCountry"] && userCountryName!=="" ){
    authenticatedUser["userCountry"] = userCountryName;
  }

  Cookie.set('userToken', userToken, { expires: 7 });
  Cookie.set('activePersona', activePersona);

  setLocalStorageItem('authenticatedUser', authenticatedUser)
  setLocalStorageItem('activePersonaDetails', activePersonaDetails)
  setLocalStorageItem('user', result?.User)

 let URL = "";

 if (process.env.REACT_APP_NODE_ENV === "production") {
   URL = process.env.REACT_APP_PRODUCTION_URL;
 } else {
  URL = process.env.REACT_APP_DEVELOPMENT_URL;
 }
 


  if (userToken) {
    switch (activePersona) {
      case "seller":
        window.location.replace(`${URL}/seller`);
        break;
      case "buyer":
        window.location.replace(`${URL}/buyer`);
        break;
      case "lawyer":
        window.location.replace(`${URL}/lawyer`);
        break;
      case "virtualassistant":
        window.location.replace(`${URL}/virtual`);
        break;
      case "photographer":
        window.location.replace(`${URL}/photographer`);
        break;
      default:
        return;
    } 
  }

}