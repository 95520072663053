import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../config/axiosConfig";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import { setLocalStorageItem } from "../../config/localStorageEncryption";
import { t } from "i18next";

let initialState = {
  activePersona: null,
  isOpenModal: false,
  hasPendingPayments:false,
  canProcceedPayments:false,
  pendingPaymentsCount:false,
  accountProperties:{
    transfersEnabled:false,
    paymentsEnabled:false,
    payoutsEnabled:false,
  },
  stripeAccountId:""
};

const getToken = () => Cookie.get("userToken");
const token = getToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};
export const openStripeAccountSetupLink = createAsyncThunk(
  "user/changeUserEmailsSubscription",
  async (payload, thunkAPI) => {
    try {
      const response = await instance.post(
        "user/stripeAccountSetup",
        payload,
        config
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const checkUserPendingPaymentsAndStripeNotSetUp = createAsyncThunk(
  "lawyer/stripeStatus",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/payment/check-user-pending-payments-and-stripe-account`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const stripeSetupSlice = createSlice({
  name: "stripeAccountSetup",
  initialState,
  reducers: {
    changeStripeAccountSetupDetails: (state, action) => {
      return {
        ...state,
        ...action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openStripeAccountSetupLink.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(openStripeAccountSetupLink.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        toast.dismiss();
        toast.success(t("Твоят имейл абонамент бе актуализиран успешно!"));
      })
      .addCase(openStripeAccountSetupLink.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        state.emailsSubscriptionDetails = [];
        console.error(action.error.message);
        toast.dismiss();
        toast.error("Error Getting the stripe account setup link");
      })
      .addCase(checkUserPendingPaymentsAndStripeNotSetUp.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(checkUserPendingPaymentsAndStripeNotSetUp.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.hasPendingPayments=action.payload.data?.hasPendingPayments
        state.canProcceedPayments=action.payload.data?.canProcceedPayments
      })
      .addCase(checkUserPendingPaymentsAndStripeNotSetUp.rejected, (state, action) => {
        state.loading = "rejected";
        console.log("error found: ");
      });
  },
});

export const { changeStripeAccountSetupDetails } = stripeSetupSlice.actions;
export default stripeSetupSlice.reducer;
