import CryptoJS from 'crypto-js';

const encryptData = (data) => {
 return CryptoJS.AES.encrypt(data,process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();

};

const decryptData = (encryptedData) => {
    return CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString(CryptoJS.enc.Utf8);  
};


export const setLocalStorageItem = (
    storedValueName,
    storedvalue,
    expirable
  ) => {

    let encryptedValue = encryptData(JSON.stringify(storedvalue));
    localStorage.setItem(
      storedValueName,
      expirable
        ? JSON.stringify({
            value: encryptedValue,
            expirable: new Date().getTime() + 1 * 60 * 1000,
          })
        : encryptedValue
    );
  };

export const getLocalStorageItem = (storedValueName) => {
  let encryptedValue = localStorage.getItem(storedValueName);
  if (encryptedValue) {
    let decryptedValue = decryptData(encryptedValue);
    return JSON.parse(decryptedValue);
  } else {
    return null;
  }
};