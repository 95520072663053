import React, { Fragment } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IoMenuOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";

import Cookie from "js-cookie";

const SideBar = () => {
    const { t } = useTranslation();

    const [state, setState] = React.useState({
        right: false,
    });

    /* Toggle responsive open menu */
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    /* Handle logout */
    const handleLogout = () => {
        localStorage.removeItem('activePersonaDetails');
        localStorage.removeItem('authenticatedUser');
        localStorage.removeItem('activePersona');
        localStorage.removeItem('user');
        localStorage.removeItem('currentTokenNotification');
        localStorage.removeItem('virtualAssistantRefLink');
        Cookie.remove('userToken');
        Cookie.remove("activerPersona")
        window.location.pathname = "/login"
    }

    return (
        <Fragment>
            {["right"].map((anchor) => (
                <Fragment key={anchor}>
                    <IoMenuOutline color="#00BCD4" className="menu-burger" size={40} onClick={toggleDrawer(anchor, true)} />

                    <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
                        <div className="modal-header">
                            <span onClick={toggleDrawer(anchor, false)} className="closebutton">
                                <AiOutlineClose />
                            </span>
                        </div>

                        <div>
                            <div className="modal-body">
                                <Link to="/buyer" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Начало")}
                                </Link>

                                <Link to="/buyer/buyer-my-profile" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Моят профил")}
                                </Link>

                                <Link to="/third-party?lawyers" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Професионалисти")}
                                </Link>

                                <Link to="/blog" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Блог")}
                                </Link>

                                <Link to="/search-properties" className="modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("Търси имоти")}
                                </Link>

                                <Link to="/buyer/buyer-my-profile" className="clearfix modal-link" onClick={toggleDrawer(anchor, false)}>
                                    {t("финансиране")}
                                </Link>
                                
                                <button className="modal-link register-modal modal-logout" onClick={handleLogout}>
                                    {t("Изход")}
                                </button>
                            </div>
                        </div>
                    </SwipeableDrawer>
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SideBar;