/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { useTranslation } from "react-i18next";
  import Box from "@mui/material/Box";
  import Menu from "@mui/material/Menu";
  import NotificationsIcon from "@mui/icons-material/Notifications";
  import Badge from "@mui/material/Badge";
  import { Link } from "react-router-dom";
  import "./notification.css";
  import SingleNotification from "./SingleNotification";
  import { NotificationsPaused } from "@mui/icons-material";
  import { messageNotifications, notifications } from "../../../features/user/authSlice";
  import { useDispatch } from "react-redux";
  import { useSelector } from "react-redux";
  import { AiOutlineMessage } from "react-icons/ai";
  const MessageNotification = ({
    handleNotification,
    setOpenNotification,
    openNotificaration,
    messageRef,
  }) => {
    const { currentPage, totalPages, notificationsList, isLoadingNotifications } =
      useSelector((state) => state?.authenticatedUser?.messageNotifications);
  
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
  
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    useEffect(() => {
      const handleScroll = () => {
        if (anchorEl) {
          handleClose();
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [anchorEl]);
  
    const dispatch = useDispatch();
    const [notificationsPage, setNotificationsPage] = useState(1);
    useEffect(() => {
      dispatch(messageNotifications({ notificationsPage ,filters:{notificationType:"atomChatNewMessage"}}));
    }, [notificationsPage]);
  
    const intersectionObserver = useRef();
    const lastNotificationRef = useCallback(
      (notification) => {
        if (intersectionObserver.current) {
          intersectionObserver.current.disconnect();
        }
        intersectionObserver.current = new IntersectionObserver(
          (notifications) => {
            if (notifications[0].isIntersecting && totalPages - currentPage > 0) {
              setNotificationsPage((current) => current + 1);
            }
          }
        );
        if (notification) {
          intersectionObserver.current.observe(notification);
        }
      },
      [notificationsList]
    );
    const handleClickNotification=(notification)=>{
        handleNotification(notification)
      dispatch(messageNotifications({ notificationsPage ,filters:{notificationType:"atomChatNewMessage"}}));
    }
    const content =
      notificationsList?.length === 0
        ? []
        : notificationsList.map((notification, i) => {
            if (notificationsList.length === i + 1) {
              return (
                <SingleNotification
                  ref={lastNotificationRef}
                  key={"notification" + i}
                  notification={notification}
                  handleNotification={handleClickNotification}
                />
              );
            }
            return (
              <SingleNotification
                key={"notification" + i}
                notification={notification}
                handleNotification={handleClickNotification}
              />
            );
          });
 
    return (
      <Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
          }}
          className="notification-icon"
        >
          <Badge
            badgeContent={
              notificationsList?.filter((notification) => !notification?.read)
                ?.length
            }
            color="primary"
          >
            <AiOutlineMessage
              style={{ color: "#0097A7", marginBottom: 5 }}
              onClick={handleClick}
              size={25}
            //   fontSize={"20px"}
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </Badge>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock
          PaperProps={{
            elevation: 0,
            sx: {
              width: "410px",
              height: "250px",
              overflowY: "scroll",
              boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              "&::-webkit-scrollbar": {
                width: "10px",
                background: "#0097A7",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
                borderRadius: "12px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "#0097A7",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="bell notif" ref={messageRef}>
            <Link
              onClick={() => setOpenNotification(!openNotificaration)}
              className="notification-link"
            >
              {
                <div
                  className={
                    openNotificaration
                      ? "notification-list-fade-in"
                      : "notification-list-fade-out"
                  }
                >
                  {notificationsList.length > 0 ? (
                    <div>
                      {content}
                      {isLoadingNotifications ? (
                        <p className="loading-more-notifications">
                          {t("Зареждат се още известия...")}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="no-notifications">
                      <NotificationsPaused
                        style={{ fontSize: 50, color: "#0097A7", opacity: 0.5 }}
                      />
                      <p> {t("Все още нямаш известия")}</p>
                    </div>
                  )}
                </div>
              }
            </Link>
          </div>
        </Menu>
      </Fragment>
    );
  };
  
  export default MessageNotification;
  