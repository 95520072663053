import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { getUserLang } from "../hooks/useTranslator";


(async () => {
    
    i18n
        .use(Backend)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            lng: getUserLang(),
            backend: {
                loadPath: "/locales/{{lng}}.json",
            },

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            react: {
                useSuspense: false,
            },
            keySeparator: '>',
            nsSeparator: '|'
        });
})();

export default i18n;
