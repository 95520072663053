/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Images from "../../../assets/uploaded_images.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./HeaderBuyer.css";

import { useTranslation } from "react-i18next";

import { IconContext } from "react-icons/lib";
import { filtersProperty, geocodeAddressLocation, getPropertyDetails } from "../../../features/property/propertySlice";
import { messageNotifications, notifications, readNotifications } from "../../../features/user/authSlice";

import { buyerRedirectNotification, changeCurrentSelectedOffer, listofsentoffers } from "../../../features/user/buyerSlice";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem, setLocalStorageItem } from "../../../config/localStorageEncryption";
import { getUserLang } from '../../../hooks/useTranslator';

/* Components */
import SideBarMenu from "./HeaderSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import Notifications from "./../../common/Notifications/Notification";
import { listServiceRequests, resetServiceRequest } from "../../../features/common/serviceRequestSlice";
import MessageNotification from "../../common/Notifications/MessageNotification";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";
import CustomPlacesAutocomplete from "../../common/CustomPlacesAutocomplete";

/* Images */
const Vector = Images["vector.svg"];
const Deal = Images["deal.svg"];
const Logo = Images["Logo.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];
const GroupPropertyDetails = Images['GroupPropertyDetails.svg'];

const HeaderBuyer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const location = useLocation();

    const messageRef = useRef(null);
    const messageRef2 = useRef(null);

    const userLanguage = getUserLang();

    const storeAddress = useSelector((state) => state.propertySlice?.filters?.address);
    const personaId = getLocalStorageItem("activePersonaDetails")?.buyerPersonaId;

    const [address, setAddress] = useState("");
    const [openNotificaration, setOpenNotification] = useState(false);
    const [openNotificaration2, setOpenNotification2] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const colorValue = () => ({ color: "#fff" });

    useEffect(() => {
        if (location?.pathname !== "/search-properties") {
            if (storeAddress) {
                dispatch(filtersProperty({
                    municipality: "",
                    address: "",
                }))
            }
        }
    }, [location]);

    useEffect(() => {
        dispatch(buyerRedirectNotification(""));

        const checkIfClickedOutside = (e) => {
            if (openNotificaration && messageRef.current && !messageRef.current.contains(e.target)) {
                setOpenNotification(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openNotificaration]);

    /* Handle click search icon */
    const handleClick = () => {
        navigate("/search-properties");

        dispatch(filtersProperty({
            otherPromises: {},
            propertyTypes: [],
            propertyNeighborhood: [],
            scrollPosition: 0,
            selectCloseTo: [],
            selectConstructionStage: [],
            selectConstructionType: [],
            selectEquipment: [],
            selectFloorType: [],
            selectHeating: [],
            selectOtherPremises: [],
            valueBudget: [0, 500000],
            address: "",
            beforeSelectNeighbothood: "",
            continent: "",
            country: "",
            municipality: "",
            neighborhood: "",
            region: "",
            locationLatitude: "",
            locationLongitude: "",
            sortBy: {},
            maxSquareMeter: "",
            minSquareMeter: "",
            maxYearBuilt: "",
            minYearBuilt: "",
            originalSelectedAddress: ""
        }));

        dispatch(filtersProperty({
            scrollPosition: 0
        }))
    };

    /* Handle select cities */
    const handleSelect = async (value) => {
        let resultAddress = await geocodeAddressLocation(value);

        if (
            !resultAddress?.formatted_address &&
                !resultAddress?.latitude &&
                !resultAddress?.longitude &&
                !resultAddress?.municipality &&
                !resultAddress?.region
        ) {
            toast.error("Please choose another suitable and accurate address");
        } else {
            let newSearchQuery = {
                address: resultAddress?.formatted_address,
                originalSelectedAddress: value
            };
            if (resultAddress?.municipality) {
                newSearchQuery.municipality = resultAddress?.municipality;
            }
            else{
                newSearchQuery.municipality =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhood = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhood =""
            }

            if (resultAddress?.region) {
                newSearchQuery.region = resultAddress?.region;
            }
            else{
                newSearchQuery.region =""
            }

            if (resultAddress?.continent) {
                newSearchQuery.continent = resultAddress?.continent;
            }
            else{
                newSearchQuery.continent =""
            }

            if (resultAddress?.country) {
                newSearchQuery.country = resultAddress?.country;
            }
            else{
                newSearchQuery.country =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhoodSearchQuery = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhoodSearchQuery =""
            }
            setAddress("");

            dispatch(filtersProperty(newSearchQuery));

            navigate("/search-properties");
        }
    };

    /* Handle list of sent offers */
    const handleListofSentOffers = (item, dispatch, location) => {
        let allOffers = [];
        let redirectUrl = "";

        dispatch(listofsentoffers()).then((r) => {
            if (r?.payload?.data?.listOfOffers?.length) {
                const sortedArr = r?.payload?.data?.listOfOffers?.sort((a, b) => b?.propertyOfferId - a?.propertyOfferId);

                allOffers = sortedArr;

                if (allOffers?.length) {
                    allOffers.forEach((offer) => {
                        if (offer?.propertyOfferId === item?.propertyOfferId) {
                            setLocalStorageItem("propertyId", offer?.propertyId);

                            dispatch(getPropertyDetails()).then((r) => {
                                if (r?.payload?.data?.data) {
                                    dispatch(changeCurrentSelectedOffer({
                                        currentSelectedOffer: item?.propertyOfferId,
                                    }));

                                    setLocalStorageItem("buyerOfferProperty", r?.payload?.data?.data);
                                    redirectUrl = "/buyer/dashboard";

                                    dispatch(buyerRedirectNotification(redirectUrl));

                                    if (location?.pathname === "/buyer/dashboard") {
                                        window.location.reload();
                                    }
                                }
                            });
                        }
                    });
                }
            }
        });
    };

    /* Handle click notification */
    const handleClickNotification = (item) => {
        let redirectUrl = "";

        if (item?.notificationType === "rejectedOffer") {
            redirectUrl = "/buyer/buyer-my-profile";
        } else if (item?.notificationType === "acceptedOffer") {
            redirectUrl = "/buyer/buyer-my-profile?my-offer";
        } else if (item?.notificationType === "receivedBadge") {
            redirectUrl = "/search-properties";
        } else if (item?.notificationType === "expiredOffer") {
            redirectUrl = "/buyer/buyer-my-profile?my-offer";
        } else if(item?.notificationType === "sellerPublicReview") {
            redirectUrl = `/buyer/public-profile?userId=${getLocalStorageItem("activePersonaDetails")?.userId}`
        } else if (item?.notificationType === "foreclosedAnotherBuyer") {
            redirectUrl = "/buyer/buyer-my-profile";
        } else if (item?.notificationType === "foreclosedAvailableAgain") {
            redirectUrl = `/property-details?${item?.propertyId}`;
        } else if (item?.notificationType === "acceptedViewingRequest") {
            handleListofSentOffers(item, dispatch, location);
        } else if (item?.notificationType === "sellerChangeDateViewing") {
            handleListofSentOffers(item, dispatch, location);
        } else if (item?.notificationType === "finalSaleAmount") {
            handleListofSentOffers(item, dispatch, location);
        } else if (item?.notificationType === "signedPrecontract") {
            handleListofSentOffers(item, dispatch, location);
        } else if (item?.notificationType === "lawyerAcceptedInquiry") {
            dispatch(listServiceRequests({ customerId: personaId, customerType: "buyer" }));
            dispatch(resetServiceRequest());

            redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
        } else if (item?.notificationType === "lawyerAcceptedPayment") {
            dispatch(listServiceRequests({ customerId: personaId, customerType: "buyer" }));
            dispatch(resetServiceRequest());

            redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
        } else if (item?.notificationType === "lawyerMarkPerformed") {
            dispatch(listServiceRequests({ customerId: personaId, customerType: "buyer" }));
            dispatch(resetServiceRequest());

            redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
        } else if (item?.notificationType === "approvedPayCommission") {
            handleListofSentOffers(item, dispatch, location);
        } else if ( item?.notificationType === "lawyerRejectedJobRequest" || item?.notificationType === "lawyerCancelledJobRequest" || item?.notificationType === "vaRejectedJobRequest" || item?.notificationType === "vaCancelledJobRequest") {
            if (item?.senderPersona === "lawyer") {
                redirectUrl =
                    "/third-party/lawyer-profile/" + item?.senderActivePersonaId;
            }

            if (item?.senderPersona === "photographer") {
                redirectUrl =
                    "/third-party/photographer-profile/" + item?.senderActivePersonaId;
            }

            if (item?.senderPersona === "virtualassistant") {
                redirectUrl =
                    "/third-party/virtual-assistant-profile/" +
                    item?.senderActivePersonaId;
            }
        } 
        else if(item?.notificationType==="virtualAssistantAgreed"){
            redirectUrl = `/seller/profile${item?.senderPersona ? `?providerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
        }
        else if (item?.notificationType === "atomChatNewMessage") {
            if (item?.senderPersona === "lawyer" || item?.senderPersona === "virtualassistant") {
                redirectUrl = `/buyer/buyer-my-profile${item?.senderPersona ? `?providerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
            } else if (item?.senderPersona === "seller") {
                redirectUrl = `/buyer/dashboard${item?.propertyOfferId ? `?propertyOfferId=${item?.propertyOfferId}` : ""}`
            }
        }

        dispatch(readNotifications(item?.notificationId)).then((r) => {
            if (r?.payload?.data?.status) {
                setOpenNotification(false);
                dispatch(notifications());
                dispatch(messageNotifications({ filters: { notificationType: "atomChatNewMessage" } }));
            }
        });

        dispatch(buyerRedirectNotification(redirectUrl));
    };

    /* Handle focus input search filed */
    const handleFocus = () => {
        setIsFocused(true)
    };

    /* Handle unfocus input search filed */
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <header className="header-buyer">
            <IconContext.Provider value={colorValue}>
                <nav className="navbar">
                    <div className="clearfix" style={{ width: "100%" }}>
                        <Link to="/buyer" className="navbar-logo">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <ul className="nav-menu">
                            <li className="nav-item-lang-switcher">
                                <LangSwitcher />
                            </li>

                            <li className="nav-item">
                                <Link to="/buyer" className={location?.pathname === "/buyer" ? "nav-links active" : "nav-links"}> {t("Начало")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/third-party?lawyers" className={location?.pathname === "/third-party" ? "nav-links active" : "nav-links"}> {t("Професионалисти")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/blog" className={location?.pathname === "/blog" ? "nav-links active" : "nav-links"}> {t("Блог")} </Link>
                            </li>
                        </ul>

                        <form className={isFocused ? "search focus-input-filed" : "search"}>
                            <CustomPlacesAutocomplete value={validateInput(address) || ""} onChange={setAddress} onSelect={handleSelect} className="search">
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <img src={HeaderSearchIcon} className="search-icon" onClick={handleClick} alt="" />

                                        <input className="search-field" {...getInputProps({ placeholder: t("Град") })}
                                            style={{
                                                fontSize: "18px",
                                                fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                color: "rgba(186, 186, 186, 1);",
                                                border: "none !important",
                                                outline: "none"
                                            }}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                        />

                                        <div className="autocomplete-dropdown-container-home">
                                            {loading ? <div>...loading</div> : null}

                                            {suggestions.map((suggestion,key) => {
                                                    return (
                                                        <div  key={key} 
                                                     {...getSuggestionItemProps(suggestion, key)}>
                                                            {suggestion.placePrediction.text.toString()}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )}
                            </CustomPlacesAutocomplete>
                        </form>

                        <div className="right-part">
                            {location?.pathname === "/dashboard" ||
                                location?.pathname === "/questioneery" ? (
                                <div className="account-group">
                                    <Link to="/" className="clearfix link">
                                        <img loading="lazy" src={Deal} alt="" />

                                        <p className="title">{t("Сделка")}</p>
                                    </Link>
                                </div>
                            ) : null}

                            <div className="account-group">
                                <Link to="/buyer/buyer-my-profile" className="clearfix link">
                                    <img loading="lazy" src={Vector} alt="" />

                                    <p className="title">{t("финансиране")}</p>
                                </Link>
                            </div>

                            <div className="right-part-mobile">
                                <div className="buyer-lang-switcher">
                                    <LangSwitcher />
                                </div>

                                <div className="notification-icon">
                                    <Notifications handleNotification={handleClickNotification} setOpenNotification={setOpenNotification} openNotificaration={openNotificaration} messageRef={messageRef} />
                                </div>

                                <div className="notification-icon">
                                    <MessageNotification handleNotification={handleClickNotification} setOpenNotification={setOpenNotification2} openNotificaration={openNotificaration2} messageRef={messageRef2} />
                                </div>

                                <div className="bell" style={{ position: 'relative' }}>
                                    <ProfileMenu />
                                </div>

                                <div className="sidebar-menu-icon">
                                    <SideBarMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                { location?.pathname === "/property-details"
                    ?   <div className="section-fixed">
                            <img src={GroupPropertyDetails} alt="" />
                            <p className="text"> {t("Изпратете предварителна оферта, съобразена с вашия бюджет, и започнете разговора!")} </p>
                        </div>
                    :   null
                }
            </IconContext.Provider>
        </header>
    );
};

export default HeaderBuyer;