import React, { useState, useEffect, useRef  } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.module.css";
/* Libraries */
import {
    Dialog,
    TextField,
    Checkbox,
    DialogContent,
    FormControlLabel,
    Button,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import moment from "moment";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    Link,
    Image,
    BlobProvider,
} from "@react-pdf/renderer";
import { toast } from "react-toastify";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { getUserLang } from "../../../hooks/useTranslator";

/* CSS */
import "./Contract.css";

/* Images */
import Images from "../../../assets/uploaded_images.json";

import Simsun from '../Fonts/SimSun.ttf';
import Jaycons from '../Fonts/Gen Jyuu Gothic Monospace Bold.ttf';
import FA from '../Fonts/(A) Arslan Wessam A (A) Arslan Wessam A.ttf';

/* Services */
import {
    contractDetails,
    commonSignContractWithAbidors,
    commonSignContractWithAbidorsLawyer,
    commonSignContractWithAbidorsPhotographer,
    commonSignContractWithAbidorsVirtualAssistant,
} from "../../../features/common/contractSlice";
import { handleContractBasedOnPersona } from "../../../contracts/properContract";
import { getLocalStorageItem, setLocalStorageItem } from "../../../config/localStorageEncryption";

/* Register font */
Font.register({
    family: "Roboto",
    format: "truetype",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

/* Register Chinese font */
Font.register({ 
    family: 'SimSun', 
    src: Simsun,
    format: "truetype",
});

/* Register Japanese font */
Font.register({
    family: 'Jaycons',
    src: Jaycons,
    format: "truetype",
});

/* Register Arabic and Persian font */
Font.register({
    family: 'PersianFont',
    src: FA,
    format: "truetype",
});
  
/* Pdf styles */
const styles = StyleSheet.create({
    title: {
        marginBottom: "20px",
        fontSize: "30px",
    },
    container: {
        fontFamily: "Roboto",
        textAlign: "justify",

        backgroundColor: "#fff",
        color: "#000",
        padding: "30px 25px 20px 25px",
    },
    containerChinese: {
        fontFamily: "SimSun",
        textAlign: "justify",

        backgroundColor: "#fff",
        color: "#000",
        padding: "30px 25px 20px 25px",
    },
    containerJapanese: {
        fontFamily: "Jaycons",
        textAlign: "justify",

        backgroundColor: "#fff",
        color: "#000",
        padding: "30px 25px 20px 25px",
    },
    containerFA: {
        fontFamily: "PersianFont",
        textAlign: "justify",

        backgroundColor: "#fff",
        color: "#000",
        padding: "30px 25px 20px 25px",
    },
    section: {
        margin: "3px 6px",
        fontSize: 11,
    },
    sectionCenter: {
        marginBottom: "10px",
        fontSize: 13,
        textAlign: "center",
    },
    textTag: {
        marginBottom: "20px",
        textAlign: "justify",
        textIndent: "50px",
    },
    textTagCenter: {
        textAlign: "center",
        marginBottom: "15px",
    },
    textTagIndent: {
        textIndent: "100px",
        textAlign: "justify",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    titleSub: {
        marginBottom: "15px",
    },
    textTagUnder: {
        marginTop: "20px",
        marginBottom: "20px",
        textAlign: "justify",
        textIndent: "50px",
    },
    imageLeft: {
        width: "151px",
        height: "120px",
    },
    imageRight: {
        width: "16px",
        height: "16px",
    },
    wrapper: {
        textAlign: "left",
    },
    leftColumn: {
        flexDirection: "column",
        flexGrow: 1,
    },
    rightColumn: {
        flexDirection: "column",
        flexGrow: 3,
        alignItems: "flex-end",
        justifySelf: "flex-end",
        marginTop: "-120px",
        textAlign: "left",
    },
    translate: {
        fontFamily: "Roboto",
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const mapState = ({ contractSlice }) => ({
    activePersona: contractSlice?.contractDetails?.activePersona,
    isOpenModal: contractSlice?.contractDetails?.isOpenModal,
});

let isNotIndividualFieldInputList = [
    "phone",
    "nameSeller",
];

let isIndividualFieldInputList = [
    "nameLegalEntity",
    "eik",
    "addressLegalEntity",
    "representative",
    "nameSeller",
];

const closeIcon = Images["close.svg"];
const CheckTrue = Images["check-true.svg"];
const CloseModal = Images["close-modal.svg"];

const Contract = () => {
    const dispatch = useDispatch();
    const { activePersona, isOpenModal } = useSelector(mapState);

    const [contactModalOpen, setContactModalOpen] = useState(isOpenModal);
    const [successSignContract, setSuccessSignContract] = useState(false);
    const [isIndividual, setIsIndividual] = useState(true);
    const [fieldInputList, setFieldInputList] = useState(isNotIndividualFieldInputList);
    const [activeInputField, setActiveInputField] = useState("name");
    const userLang = getUserLang();

    const { contractService, alsoKnown, sideParagraphs, subjectContracts, commissionReward, rightsAndObligations, termOfContract, finalProvisions } = handleContractBasedOnPersona(activePersona);

    const { t } = useTranslation();

    const contentRef = useRef(null);

    const activeUserTest = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser") : {};

    useEffect(() => {
        if (activePersona) {
            setContactModalOpen(isOpenModal);
        }
    }, [activePersona, isOpenModal]);

    const date = moment().format("DD.MM.YYYY");

    const [values, setValues] = useState({});
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "checkbox") {
            setIsChecked(e?.target?.checked);
        }

        setValues((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSubmitForm = (e, blob, url) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (isIndividual) {
            if (!values?.phone) {
                return toast.error(t("Моля въведете телефонен номер"));
            }
        } else {
            if (!values?.nameLegalEntity) {
                return toast.error(t("Моля въведете име на фирмата (точка 1)"));
            }

            if (values?.eik?.length === 0) {
                return toast.error(t("Моля, въведете валиден ЕИК (точка 1)"));
            }

            if (!values?.addressLegalEntity) {
                return toast.error(t("Моля, въведете адрес (точка 1)"));
            }

            if (!values?.representative) {
                return toast.error(t("Моля, въведете име на представителя (точка 1)"));
            }
        }

        if (!values?.nameSeller) {
            return toast.error(t("Моля въведете имена си на (точка 5.5)"));
        }

        if (!isChecked) {
            return toast.error(t("Моля, съгласете се с условията на договора"));
        }

        const file = new File([blob], "contract.pdf", {
            type: "application/pdf",
            lastModified: new Date().getTime(),
        });

        let reader = new FileReader();

        reader.onloadend = function () {
            test(file, reader.result);
        };

        reader.readAsDataURL(file);
    };

    const test = (file, result) => {
        let formdata = new FormData();

        formdata.append("contractDocument", file);

        let requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        let activePersonaUserDetails = "";

        if (activePersona) {
            activePersonaUserDetails = getLocalStorageItem("authenticatedUser")?.activePersona;
        }

        if (activePersonaUserDetails === "seller" || activePersonaUserDetails === "buyer") {
            dispatch(commonSignContractWithAbidors(requestOptions)).then((r) => {
                if (r?.payload?.data?.result) {
                    dispatch(contractDetails({
                        activePersona: getLocalStorageItem("authenticatedUser")?.activePersona,
                        isOpenModal: false,
                    }))

                    let serializedState = getLocalStorageItem("activePersonaDetails")

                    serializedState["isSignedContract"] = true;

                    let result = serializedState;

                    setLocalStorageItem("activePersonaDetails", result);

                    setSuccessSignContract(true);
                    setTimeout(()=>{
                        window.location.reload()
                    },1000)
                }
            });
        } else if (activePersonaUserDetails === "lawyer") {
            dispatch(commonSignContractWithAbidorsLawyer(requestOptions)).then(
                (r) => {
                    if (r?.payload?.status === 200) {
                        dispatch(contractDetails({
                            activePersona: getLocalStorageItem("authenticatedUser")?.activePersona,
                            isOpenModal: false,
                        }))

                        let serializedState = getLocalStorageItem("activePersonaDetails")

                        serializedState["isSignedContract"] = true;

                        let result = serializedState;

                        setLocalStorageItem("activePersonaDetails", result);

                        setSuccessSignContract(true);
                        setTimeout(()=>{
                            window.location.reload()
                        },1000)
                    }
                }
            );
        } else if (activePersonaUserDetails === "photographer") {
            dispatch(commonSignContractWithAbidorsPhotographer(requestOptions)).then(
                (r) => {
                    if (r?.payload?.status === 200) {
                        dispatch(contractDetails({
                            activePersona: getLocalStorageItem("authenticatedUser")?.activePersona,
                            isOpenModal: false,
                        }))

                        let serializedState = getLocalStorageItem("activePersonaDetails")

                        serializedState["isSignedContract"] = true;

                        let result = serializedState;

                        setLocalStorageItem("activePersonaDetails", result);

                        setSuccessSignContract(true);
                        setTimeout(()=>{
                            window.location.reload()
                        },1000)
                    }
                }
            );
        } else if (activePersonaUserDetails === "virtualassistant" || activePersonaUserDetails === "virtualAssistant") {
            dispatch(commonSignContractWithAbidorsVirtualAssistant(requestOptions))
                .then((r) => {
                    if (r?.payload?.status === 200) {
                        dispatch(contractDetails({
                            activePersona: getLocalStorageItem("authenticatedUser")?.activePersona,
                            isOpenModal: false,
                        }))

                        let serializedState = getLocalStorageItem("activePersonaDetails")

                        serializedState["isSignedContract"] = true;

                        let result = serializedState;

                        setLocalStorageItem("activePersonaDetails", result);

                        setSuccessSignContract(true);
                        setTimeout(()=>{
                            window.location.reload()
                        },1000)
                    }
                });
        }
    };

    const handleCloseModal = () => {
        dispatch(
            contractDetails({
                isOpenModal: false,
            })
        );
    };

    /* EmptyDocument component (pdf) */
    const EmptyDocument = () => {
        return (
            <Document>
                <Page wrap size="A4" style={userLang === "lzh" ? styles.containerChinese : userLang === "ja" ? styles.containerJapanese : userLang === "fa" ? styles.containerFA : userLang === "ar" ? styles.containerFA : styles.container}>
                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.title} break> {t("ДОГОВОР")} </Text>

                        <Text style={styles.titleSub} break> {t(contractService)} </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t("Днес")} {date}г. 
                            {t("се сключи този договор")} {" "}
                            {t(contractService)}, 
                            {t("между")}:
                            {" "}
                        </Text>

                        <Text style={styles.textTag} break>
                            {t("1. “АБИДОРС“ ЕООД, ЕИК 206354883, със седалище и адрес на управление: гр. София 1111, Столична община, район Слатина, ж.к. Гео Милев, ул. „Професор Георги Павлов“ № 38, сграда 31, офис 38, представлявано от управителя Борис Христов Борисов, наричано “Абидорс“ ЕООД, от една страна и")}
                        </Text>

                        <Text style={styles.textTag}></Text>

                        {isIndividual ? (
                            <Text style={styles.textTag} break>
                                {" "}
                                {
                                    activeUserTest?.phoneNumber
                                        ?   `2.  ${activeUserTest?.name} ${activeUserTest?.familyName} ${t("с телефонен номер:")} ${activeUserTest?.phoneNumber} ${t("и и-мейл адрес:")} ${activeUserTest?.userEmail}, ${t("наричан/а")} ${t(alsoKnown)}, ${t("от друга страна")}`
                                        :   `2.  ${activeUserTest?.name} ${activeUserTest?.familyName} ${t("с телефонен номер:")} ${values?.phone} ${t("и и-мейл адрес:")} ${activeUserTest?.userEmail}, ${t("наричан/а")} ${t(alsoKnown)}, ${t("от друга страна")}`
                                }
                            </Text>
                        ) : (
                            <Text style={styles.textTag} break>
                                {" "}
                                2. {values?.nameLegalEntity},
                                {t("вписано в Търговския регистър при Агенция по вписванията с ЕИК")} {values?.eik},
                                {t("със седалище и адрес на управление:")} {values?.addressLegalEntity},
                                {t("представлявано от")} {" "} {values?.representative},
                                {t("наричано за краткост")} {t(alsoKnown)}{" "}
                                {t("от друга страна")}
                            </Text>
                        )}

                        <Text style={styles.textTagUnder} break> {t("СТРАНИТЕ КАТО ВЗЕХА ПРЕДВИД, ЧЕ:")} </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t( "А/ “Абидорс“ ЕООД е изградило и управлява онлайн базирана платформа Abidors за осъществяване на автоматизирани сделки за покупко – продажба на недвижими имоти достъпна на интернет адрес:" )}  www.abidors.com;
                            {" "}
                        </Text>

                        {
                            sideParagraphs?.length
                                ?   sideParagraphs.map((item, key) => {
                                        return (
                                            <Text key={key} style={{ marginBottom: "25px", textAlign: "justify", textIndent: "50px" }} break >
                                                {" "}
                                                {t(item)}
                                                {" "}
                                            </Text>
                                        );
                                    })
                                :   null
                        }
                    </View>

                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.textTagUnder} break> {t("СЕ СПОРАЗУМЯХА ЗА СЛЕДНОТО:")} </Text>

                        <Text style={[styles.textTagCenter, styles.translate]} break> {t("І. ПРЕДМЕТ НА ДОГОВОРА")} </Text>

                        {subjectContracts?.length
                            ? subjectContracts.map((item, key) => {
                                return (
                                    <Text key={key} style={styles.textTag} break> {t(item)} </Text>
                                );
                            })
                            : null}
                    </View>

                    <View wrap style={styles.sectionCenter}>
                        <Text style={styles.textTagCenter} break>
                            {" "}
                            {t("ІІ. КОМИСИОННО ВЪЗНАГРАЖДЕНИЕ")} 
                            {" "}
                        </Text>

                        {
                            commissionReward?.length
                                ?   commissionReward.map((item, key) => {
                                        return (
                                            <>
                                                <Text key={key} style={styles.textTag} break> {t(item?.mainPoint)} </Text>

                                                {
                                                    item?.subPoints?.length
                                                        ?   item.subPoints.map((subItem, k) => {
                                                                return <Text key={k} style={styles.textTagIndent} break> {t(subItem)} </Text>;
                                                            })
                                                        :   null
                                                }
                                            </>
                                        );
                                })
                            :   null
                        }

                        <Text style={styles.textTag}> {t("Определеното съгласно настоящия раздел комисионно възнаграждение е без включен данък добавена стойност.")} </Text>
                    </View>

                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.textTagCenter} break>
                            {" "}
                            {t("ІІІ. ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ")}
                            {" "}
                        </Text>

                        {rightsAndObligations?.length
                            ? rightsAndObligations.map((item, key) => {
                                return (
                                    <Text key={key} style={styles.textTag} break> {t(item)} </Text>
                                );
                            })
                            : null}
                    </View>

                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.textTagCenter} break> {t("ІV. СРОК НА ДОГОВОРА. ПРЕКРАТЯВАНЕ")} </Text>

                        {termOfContract?.length
                            ? termOfContract.map((item, key) => {
                                return (
                                    <Text key={key} style={styles.textTag} break> {t(item)} </Text>
                                );
                            })
                            : null}
                    </View>

                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.textTagCenter} break>
                            {" "}
                            {t("V. ЗАКЛЮЧИТЕЛНИ РАЗПОРЕДБИ")}
                            {" "}
                        </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t("5.1. Неразделна част от настоящия договор са Общите условия на “Абидорс„ ЕООД, публикувани на интернет адрес:")}
                            {" "}

                            <Link> www.abidors.com </Link>

                            {t(finalProvisions?.additionalText)}
                        </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t("5.2. Настоящият договор може да бъде изменян и допълван по взаимно съгласие на страните, изразено в писмена форма.")}
                            {" "}
                        </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t("5.3. Всички спорове, породени по предмета на този договор или отнасящи се до него, включително споровете, породени или отнасящи се до неговото тълкуване, недействителност, неизпълнение или прекратяване, ще се решават с преговори и по споразумение между страните, а когато това се окаже невъзможно споровете се решават компетентния български съд.")}
                            {" "}
                        </Text>

                        <Text style={styles.textTag} break>
                            {" "}
                            {t("5.4. За неуредените в настоящия договор и в Общите условия на “Абидорс“ ЕООД въпроси се прилагат съответните разпоредби на действащото законодателство на РБългария.")}
                            {" "}
                        </Text>
                    </View>

                    <View wrap={false} style={styles.sectionCenter}>
                        <Text style={styles.textTag} break>
                            {t("Този договор се състави и подписа в два еднообразни екземпляра – по един за всяка от страните по него.")}
                        </Text>

                        <View style={styles.leftColumn}>
                            <p style={styles.imageLeft}></p>
                        </View>

                        <View style={styles.rightColumn}>
                            <Text style={{ marginBottom: "15px", marginRight: "130px" }}>
                                {" "}
                                {t(alsoKnown)}:
                                {" "}
                            </Text>

                            <Text style={{ marginBottom: "50px", marginTop: '-12px' }}> {values?.nameSeller} </Text>

                            <View style={styles.checkboxContainer}>
                                <Image src="/test.png" style={styles.imageRight} />
                                <Text> {t("Съгласен съм с условията в този договор")} </Text>
                            </View>
                        </View>
                    </View>

                    <Text
                        break
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            </Document>
        );
    };

    const handleChangeIndividual = (event) => {
        setIsIndividual(event?.target?.checked);
        isIndividual ? setActiveInputField(isIndividualFieldInputList[0]) : setActiveInputField(isNotIndividualFieldInputList[0]);
        isIndividual ? setFieldInputList(isIndividualFieldInputList) : setFieldInputList(isNotIndividualFieldInputList);
    };

    const handleCloseSuccessSignContract = () => {
        dispatch(contractDetails({
            activePersona: getLocalStorageItem("authenticatedUser")?.activePersona,
            isOpenModal: false,
        }))

        setSuccessSignContract(false);
    };

    const changeSuccessSignContractText = () => {
        if (activePersona) {
            if (getLocalStorageItem("user")) {
                if (!getLocalStorageItem("user")?.isRestricted) {
                    switch (activePersona) {
                        case "buyer":
                            return t("Договорът беше успешно подписан и вече може да продължиш с насрочването на огледа");

                        case "seller":
                            return t("Договорът беше успешно подписан и вече може да продължиш с насрочването на огледа");

                        default:
                            return t("Честито! Профилът ти е вече активен. Добави услуги на него, така че да представиш себе си пред бъдещите си клиенти");
                    }
                }

                return t("Договорът е успешно попълнен и изпратен. Ще бъде прегледан, а след като профилът ти бъде одобрен, ще имаш достъп до функционалностите на сайта");
            }
        }
    };

    const handleActiveField = (arrowType) => {
        if (arrowType === "next") {
            if ( fieldInputList.indexOf(activeInputField) === fieldInputList?.length - 1) {
                return;
            }

            setActiveInputField(fieldInputList[fieldInputList.indexOf(activeInputField) + 1]);

            document.getElementById(fieldInputList[fieldInputList.indexOf(activeInputField) + 1])?.scrollIntoView({ behavior: "smooth", block: "center" });
        }

        if (arrowType === "prev") {
            if (fieldInputList.indexOf(activeInputField) === 0) {
                return;
            }

            setActiveInputField(fieldInputList[fieldInputList.indexOf(activeInputField) - 1]);

            document.getElementById(fieldInputList[fieldInputList.indexOf(activeInputField) - 1])?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const scrollDown = () => {
        if (contentRef.current) {
            contentRef.current.scrollBy({
                top: 350, // Скролира с 200 пиксела надолу
                behavior: 'smooth', // Плавен ефект на скролиране
            });
        }
    }

    return (
        <>
            <Dialog
                className="seller-home-contractModal"
                disableScrollLock={true}
                open={contactModalOpen}
                maxWidth={false}
                scroll={"body"}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown={true}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                        setContactModalOpen(false);
                    }
                }}
            >
                <div className="closeIcon">
                    <img loading="lazy" src={closeIcon} alt="" onClick={handleCloseModal} />
                </div>

                <div className="content">
                    <div className="content-steps">
                        <div className="steps">
                            <div className="step">
                                <div className="step-number">
                                    <span >{fieldInputList.indexOf(activeInputField) + 1}/</span>
                                    <span>{fieldInputList.length}</span>
                                </div>
                            </div>
                        </div>

                        <div className="content-arrow">
                            <BsArrowDownCircle
                                size={30}
                                style={{ margin: '0 10px' }}
                                onClick={() => handleActiveField("next")}
                            />
                            <BsArrowUpCircle
                                size={30}
                                style={{ margin: '0 10px' }}
                                onClick={() => handleActiveField("prev")}
                            />
                        </div>
                    </div>

                    <div className="contract-seller">
                        <DialogContent className="content">
                            <div className="contract-body" ref={contentRef}>
                                <h1 className="title"> {t("ДОГОВОР")}</h1>

                                <p className="title-sub">
                                    <span>{t(contractService)}</span>
                                </p>

                                <p style={{ textIndent: "50px", marginBottom: "10px", textAlign: "justify" }}>
                                    <span> {t("Днес")} </span>

                                    <span>
                                        <TextField value={date} name="date" style={{ padding: "0 5px", width: "102px", marginTop: "-3px", pointerEvents: "none" }} />
                                        <span>, {t("се сключи този договор")} </span>
                                        <span>{t(contractService)}</span>,
                                        <span> {t("между")}:</span>
                                    </span>
                                </p>

                                <p style={{ textIndent: "50px", marginBottom: "5px", textAlign: "justify" }}>
                                    {t("1. “АБИДОРС“ ЕООД, ЕИК 206354883, със седалище и адрес на управление: гр. София 1111, Столична община, район Слатина, ж.к. Гео Милев, ул. „Професор Георги Павлов“ № 38, сграда 31, офис 38, представлявано от управителя Борис Христов Борисов, наричано “Абидорс“ ЕООД, от една страна и")}
                                </p>

                                <p style={{ marginBottom: "20px", textAlign: "justify", textDecoration: "underline", textUnderlinePosition: "under" }}>
                                    <span> {t("Юридическо лице")} </span>

                                    <Switch checked={isIndividual} onChange={handleChangeIndividual} style={{ verticalAlign: "middle" }} />

                                    <span> {t("Физическо лице")} </span>
                                </p>

                                <div>
                                    {isIndividual ? (
                                        <>
                                            <div>
                                                {
                                                    !activeUserTest?.phoneNumber
                                                        ?   <>
                                                                <p style={{ display: "inline-block", marginBottom: 0 }}> 2. </p>
                                                                <span> <b> {activeUserTest?.name} {activeUserTest?.familyName} </b> </span>
                                                                <span> {t("с телефонен номер:")} {activeUserTest?.phoneNumber} </span>

                                                                <p style={{ display: 'inline-block' }}>
                                                                    <span> {t("и и-мейл адрес:")} {activeUserTest?.userEmail}, </span>
                                                                    <span> {t("наричан/а")} {t(alsoKnown)} {t("от друга страна")} </span>
                                                                </p>
                                                            </>
                                                        :   <>
                                                                <p style={{ display: "inline-block", marginBottom: 0 }}> 2. </p>
                                                                <span> <b> {activeUserTest?.name} {activeUserTest?.familyName} </b> </span>
                                                                <span> {t("с телефонен номер:")} </span>

                                                                <div style={{ display: "inline-block", width: "50%" }}>
                                                                    <TextField
                                                                        name="phone"
                                                                        type="number"
                                                                        id="phone"
                                                                        placeholder={t("Телефон")}
                                                                        onChange={handleChange}
                                                                        style={{ padding: "0 5px", width: "100%", marginTop: "-6px" }}
                                                                        InputProps={{
                                                                            style: { borderRadius: "8px", color: "#9D9D9D", fontSize: "16px", fontWeight: "700", backgroundColor: "#EDEDED" }
                                                                        }}
                                                                        inputProps={{
                                                                            style: {
                                                                                color: "rgba(0, 0, 0, 0.6)",
                                                                                fontSize: "18px",
                                                                                lineHeight: "24px",
                                                                                fontFamily: "Source Sans Pro",
                                                                                fontWeight: "400",
                                                                                outline: activeInputField === "name" ? "2px solid #00bcd4" : "none"
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>

                                                                <p style={{ display: 'inline-block' }}>
                                                                    <span> {t("и и-мейл адрес:")} {activeUserTest?.userEmail}, </span>
                                                                    <span> {t("наричан/а")} {t(alsoKnown)} {t("от друга страна")} </span>
                                                                </p>
                                                            </>
                                                }

                                                <p style={{ display: "inline-block" }}>,</p>
                                            </div>

                                            <span>
                                                {t("наричан/а")} {t(alsoKnown)} {t("от друга страна")}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ display: "inline-block", marginBottom: "10px" }}>
                                                <TextField
                                                    name="nameLegalEntity"
                                                    id="nameLegalEntity"
                                                    onChange={handleChange}
                                                    style={{ padding: "0 5px", width: "100%", marginTop: "-6px" }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "8px",
                                                            color: "#9D9D9D",
                                                            fontSize: "16px",
                                                            fontWeight: "700",
                                                            backgroundColor: "#EDEDED",
                                                        },
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            color: "rgba(0, 0, 0, 0.6)",
                                                            fontSize: "18px",
                                                            lineHeight: "24px",
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: "400",
                                                            outline: "none !important",
                                                        },
                                                    }}
                                                />
                                            </div>
                                            ,
                                            <span> {t("вписано в Търговския регистър при Агенция по вписванията с ЕИК")} </span>

                                            <div style={{ display: "inline-block", width: "73.5%" }}>
                                                <TextField
                                                    name="eik"
                                                    id="eik"
                                                    onChange={handleChange}
                                                    style={{ padding: "0 5px", marginTop: "-6px", width: "100%" }}
                                                    inputProps={{
                                                        inputMode: "numeric",
                                                        style: {
                                                            color: "rgba(0, 0, 0, 0.6)",
                                                            fontSize: "18px",
                                                            lineHeight: "24px",
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: "400",
                                                            outline: "none !important",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "8px",
                                                            color: "#9D9D9D",
                                                            fontSize: "16px",
                                                            fontWeight: "700",
                                                            backgroundColor: "#EDEDED",
                                                        },
                                                    }}
                                                />
                                            </div>

                                            <p style={{ display: "inline-block" }}>,</p>

                                            <div>
                                                <p style={{ display: "inline-block" }}>
                                                    {" "}
                                                    {t("със седалище и адрес на управление:")}{" "}
                                                </p>

                                                <div style={{ display: "inline-block", width: "48%" }}>
                                                    <TextField
                                                        name="addressLegalEntity"
                                                        id="addressLegalEntity"
                                                        onChange={handleChange}
                                                        style={{ padding: "0 5px", width: "100%", marginTop: "-6px", marginBottom: "5px" }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                                color: "#9D9D9D",
                                                                fontSize: "16px",
                                                                fontWeight: "700",
                                                                backgroundColor: "#EDEDED",
                                                                outline: activeInputField === "addressLegalEntity" ? "2px solid #00bcd4" : "none"
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                color: "rgba(0, 0, 0, 0.6)",
                                                                fontSize: "18px",
                                                                lineHeight: "24px",
                                                                fontFamily: "Source Sans Pro",
                                                                fontWeight: "400",
                                                                outline: activeInputField === "numberOfpersonalId" ? "2px solid #00bcd4" : "none"
                                                            },
                                                        }}
                                                    />
                                                </div>

                                                <p style={{ display: "inline-block" }}>,</p>
                                            </div>

                                            <div>
                                                <p style={{ display: "inline-block" }}>
                                                    {" "}
                                                    {t("представлявано от")}{" "}
                                                </p>

                                                <div style={{ display: "inline-block", width: "73.5%" }}>
                                                    <TextField
                                                        name="representative"
                                                        id="representative"
                                                        onChange={handleChange}
                                                        style={{ padding: "0 5px", width: "100%", marginTop: "-6px" }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                                color: "#9D9D9D",
                                                                fontSize: "16px",
                                                                fontWeight: "700",
                                                                backgroundColor: "#EDEDED",
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                color: "rgba(0, 0, 0, 0.6)",
                                                                fontSize: "18px",
                                                                lineHeight: "24px",
                                                                fontFamily: "Source Sans Pro",
                                                                fontWeight: "400",
                                                                outline:
                                                                    activeInputField === "representative"
                                                                        ? "2px solid #00bcd4"
                                                                        : "none",
                                                            },
                                                        }}
                                                    />
                                                </div>

                                                <p style={{ display: "inline-block" }}>,</p>
                                            </div>

                                            <span>
                                                {t("наричано за краткост")} {t(alsoKnown)}{" "}
                                                {t("от друга страна")}
                                            </span>
                                        </>
                                    )}
                                </div>

                                <p style={{ textIndent: "50px", marginBottom: "10px", textAlign: "justify" }}>
                                    <span>{t("СТРАНИТЕ КАТО ВЗЕХА ПРЕДВИД, ЧЕ:")}</span>
                                </p>

                                <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                    <span>
                                        {t( "А/ “Абидорс“ ЕООД е изградило и управлява онлайн базирана платформа Abidors за осъществяване на автоматизирани сделки за покупко – продажба на недвижими имоти достъпна на интернет адрес:" )}
                                    </span>{" "}

                                    <a href="www.abidors.com" target="_blank" style={{ color: "inherit", textDecoration: "none" }}> www.abidors.com </a>
                                </p>

                                {sideParagraphs?.length
                                    ? sideParagraphs.map((item, key) => {
                                        return (
                                            <p key={key} style={{ textIndent: "50px", textAlign: "justify", marginBottom: key === sideParagraphs?.length - 1 ? "5px" : "" }}>
                                                <span>{t(item)}</span>
                                            </p>
                                        );
                                    })
                                    : null}
                                
                                <p style={{ textIndent: "50px", marginBottom: "20px" }}> {t("СЕ СПОРАЗУМЯХА ЗА СЛЕДНОТО:")} </p>

                                <div style={{ marginBottom: "20px" }}>
                                    <h3 style={{ textAlign: "center", marginBottom: "20px" }}> {t("І. ПРЕДМЕТ НА ДОГОВОРА")} </h3>

                                    {subjectContracts?.length
                                        ? subjectContracts.map((item, key) => {
                                            return (
                                                <p key={key} style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                                    <span>{t(item)}</span>
                                                </p>
                                            );
                                        })
                                        : null}
                                </div>

                                <div style={{ marginBottom: "20px" }}>
                                    <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                                        {" "}
                                        {t("ІІ. КОМИСИОННО ВЪЗНАГРАЖДЕНИЕ")}{" "}
                                    </h3>

                                    {commissionReward?.length
                                        ? commissionReward.map((item, key) => {
                                            return (
                                                <>
                                                    <p
                                                        key={key}
                                                        style={{
                                                            textIndent: "50px",
                                                            textAlign: "justify",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {t(item?.mainPoint)}
                                                    </p>

                                                    {item?.subPoints?.length
                                                        ? item.subPoints.map((subItem, k) => {
                                                            return (
                                                                <p key={k} style={{ textIndent: "100px", textAlign: "justify", marginBottom: "5px" }}> {t(subItem)} </p>
                                                            );
                                                        })
                                                        : null}
                                                </>
                                            );
                                        })
                                        : null}

                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        {t("Определеното съгласно настоящия раздел комисионно възнаграждение е без включен данък добавена стойност.")}
                                    </p>
                                </div>

                                <div style={{ marginBottom: "20px" }}>
                                    <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                                        {" "}
                                        {t("ІІІ. ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ")}{" "}
                                    </h3>

                                    {rightsAndObligations?.length
                                        ? rightsAndObligations.map((item, key) => {
                                            return (
                                                <p key={key} style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                                    {t(item)}
                                                </p>
                                            );
                                        })
                                        : null}
                                </div>

                                <div style={{ marginBottom: "20px" }}>
                                    <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                                        {" "}
                                        {t("ІV. СРОК НА ДОГОВОРА. ПРЕКРАТЯВАНЕ")}{" "}
                                    </h3>

                                    {termOfContract?.length
                                        ? termOfContract.map((item, key) => {
                                            return (
                                                <p key={key} style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}> {t(item)} </p>
                                            );
                                        })
                                        : null}
                                </div>

                                <div style={{ marginBottom: "20px" }}>
                                    <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                                        {" "}
                                        {t("V. ЗАКЛЮЧИТЕЛНИ РАЗПОРЕДБИ")}{" "}
                                    </h3>

                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        <span> {t("5.1. Неразделна част от настоящия договор са Общите условия на “Абидорс“ ЕООД, изпратени от Купувача оферти за покупка на недвижими имоти.")} </span>

                                        <a href="www.abidors.com" target="_blank" style={{ color: "inherit", textDecoration: "none" }}> www.abidors.com; </a>
                                        <span>{t(finalProvisions?.additionalText)}</span>
                                    </p>

                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        {t("5.2. Настоящият договор може да бъде изменян и допълван по взаимно съгласие на страните, изразено в писмена форма.")}
                                    </p>

                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        {t("5.3. Всички спорове, породени по предмета на този договор или отнасящи се до него, включително споровете, породени или отнасящи се до неговото тълкуване, недействителност, неизпълнение или прекратяване, ще се решават с преговори и по споразумение между страните, а когато това се окаже невъзможно споровете се решават компетентния български съд.")}
                                    </p>

                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        {t("5.4. За неуредените в настоящия договор и в Общите условия на “Абидорс“ ЕООД въпроси се прилагат съответните разпоредби на действащото законодателство на РБългария.")}
                                    </p>
                                </div>

                                <div style={{ marginBottom: "50px" }}>
                                    <p style={{ textIndent: "50px", textAlign: "justify", marginBottom: "5px" }}>
                                        {t("Този договор се състави и подписа в два еднообразни екземпляра – по един за всяка от страните по него.")}
                                    </p>
                                </div>

                                <div className="cols cols-full">
                                    <div className="col col6of12">

                                    </div>

                                    <div className="col col6of12">
                                        <p style={{ marginBottom: "10px", textTransform: "uppercase" }}>
                                            {" "}
                                            {t(alsoKnown)}:{" "}
                                        </p>

                                        <TextField
                                            name="nameSeller"
                                            id="nameSeller"
                                            value={values?.nameSeller}
                                            onChange={handleChange}
                                            style={{ marginBottom: "50px", padding: "0 5px", width: "74%", marginTop: "-6px" }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "8px",
                                                    color: "#9D9D9D",
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    backgroundColor: "#EDEDED",
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    color: "rgba(0, 0, 0, 0.6)",
                                                    fontSize: "18px",
                                                    lineHeight: "24px",
                                                    fontFamily: "Source Sans Pro",
                                                    fontWeight: "400",
                                                    outline: activeInputField === "nameSeller" ? "2px solid #00bcd4" : "none"
                                                },
                                            }}
                                        />

                                        <FormControlLabel
                                            control={<Checkbox name="checkbox" onChange={handleChange} value={isChecked} style={{ marginRight: "0", color: "#000" }} />}
                                            label={t("Съгласен съм с условията в този договор")}
                                        />
                                    </div>
                                </div>

                                <div className="button-container" style={{ textAlign: 'center', marginTop: '50px' }}>
                                    <BlobProvider document={<EmptyDocument />}>
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <Button className="button" onClick={(e) => handleSubmitForm(e, blob, url)}>
                                                    <span> {t("Подпиши")} </span>
                                                </Button>
                                            );
                                        }}
                                    </BlobProvider>

                                    {/* <PDFDownloadLink document={<EmptyDocument />} fileName="somename.pdf">
                                        {({ blob, url, loading, error }) =>
                                            <Button className="button">
                                                <span> {t("Подпиши")} </span>
                                            </Button>
                                        }
                                    </PDFDownloadLink> */}

                                    <Button className="button" onClick={handleCloseModal}>
                                        <span> {t("Затвори")} </span>
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>

                    <div className="button-container">
                        <Button className="button" onClick={scrollDown}>
                            <span> {t("Виж повече")} </span>
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={successSignContract}
                disableScrollLock={true}
                onClose={handleCloseSuccessSignContract}
                className="dialog-profile-offer-accepted"
                maxWidth="md"
                PaperProps={{
                    style: {
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                    },
                }}
            >
                <div className="dialog-profile-offer-accepted-content">
                    <img loading="lazy" src={CheckTrue} className="check-icon" alt="" />

                    <div className="title-header">
                        <h4 className="title"> {t("Честито!")} </h4>
                    </div>

                    <img loading="lazy"
                        src={CloseModal}
                        className="close-icon"
                        alt=""
                        onClick={handleCloseSuccessSignContract}
                    />

                    <DialogContent className="content">
                        <p className="sub-title"> {changeSuccessSignContractText()} </p>

                        <div className="actions">
                            <Button
                                className="button-close"
                                onClick={handleCloseSuccessSignContract}
                            >
                                {t("Затвори")}
                            </Button>
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
};

export default Contract;