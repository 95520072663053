/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import "./ProfileMenu.css";

import Cookie from "js-cookie";

import Images from "../../../assets/uploaded_images.json";
import { getLocalStorageItem } from '../../../config/localStorageEncryption.js';
import { changeActivePersona } from "../../../features/common/mutiprofile.Slice";

import { getBuyerProfile } from '../../../features/user/buyerSlice.js';
import { getSellerProfile } from '../../../features/user/sellerSlice.js';
import { lawyerProfile } from '../../../features/user/lawyerSlice.js';
import { getPhotographerProfile } from '../../../features/user/photographerSlice.js';
import { getVirtualAssistantProfile } from '../../../features/user/virtualAssistantSlice.js';

import { changeBuyerCurrentServiceRequest, changeSellerCurrentServiceRequest } from "../../../features/common/serviceRequestSlice";

const Account = Images["account-header.svg"];
const ProfileImageSVG = Images["ProfileImageSVG.svg"];
const CarbonViewFilled = Images["carbonView-filled.svg"];

const ProfileMenu = ({ personaId }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
   
    const activeUser = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser") : {};
    const activePersonaDetails = getLocalStorageItem("activePersonaDetails") ? getLocalStorageItem("activePersonaDetails") : {};

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [profilePicture, setProfilePicture] = useState("");

    /* Handle open menu profile */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /* Handle close menu profile */
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [activeProfilePersona, setActiveProfilePersona] = useState("")

    useEffect(() => {
        if(anchorEl) {
            if(activeUser?.activePersona === "buyer") {
                setActiveProfilePersona(`${t("Акаунт на купувач")}`);
    
                dispatch(getBuyerProfile(activePersonaDetails?.userId))
                    .then((r) => {
                        if (r?.payload?.data) {
                            setProfilePicture(r?.payload?.data?.activePersona?.profilePicture);
                        }
                    })
            } else if(activeUser?.activePersona === "seller") {
                setActiveProfilePersona(`${t("Акаунт на продавач")}`);
    
                dispatch(getSellerProfile(activePersonaDetails?.userId))
                    .then((r) => {
                        if (r?.payload?.data) {
                            setProfilePicture(r?.payload?.data?.activePersona?.profilePicture);
                        }
                    })
            } else if(activeUser?.activePersona === "lawyer") {
                setActiveProfilePersona(`${t("Акаунт на адвокат")}`);

                dispatch(lawyerProfile(personaId))
                    .then(r => {
                        if (r?.payload?.data) {
                            setProfilePicture(r?.payload?.data?.profilePicture);
                        }
                    });
            } else if(activeUser?.activePersona === "virtualassistant") {
                setActiveProfilePersona(`${t("Акаунт на асистент")}`);

                dispatch(getVirtualAssistantProfile(personaId))
                    .then((r) => {
                        if (r?.payload?.data) {
                            setProfilePicture(r?.payload?.data?.profilePicture)
                        }
                    });
            } else  if(activeUser?.activePersona === "photographer") {
                setActiveProfilePersona(`${t("Акаунт на фотограф")}`);

                dispatch(getPhotographerProfile(personaId))
                    .then((r) => {
                        if (r?.payload?.data) {
                            setProfilePicture(r?.payload?.data?.profilePicture);
                        }
                    });
            }
        }

        const handleScroll = () => {
            if (anchorEl) {
                handleClose();
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [anchorEl]);

    /* Handle open profile */
    const handleOpenProfile = () => {
        let redirectUrl = "";

        if(activeUser?.activePersona === "buyer") {
           redirectUrl = "/buyer/buyer-my-profile?my-offer";
        } else if(activeUser?.activePersona === "seller") {
            redirectUrl = "/seller/profile";
        } else if(activeUser?.activePersona === "lawyer") {
            redirectUrl = `/lawyer/profile/${personaId}`;
        } else if(activeUser?.activePersona === "virtualassistant") {
            setActiveProfilePersona(`${t("Акаунт на асистент")}`)
            redirectUrl = `/virtual/my-profile/${personaId}`
        } else  if(activeUser?.activePersona === "photographer") {
            setActiveProfilePersona(`${t("Акаунт на фотограф")}`)
            redirectUrl = `/photographer/my-profile/${personaId}`;
        }

        navigate(redirectUrl);
    }

    /* Handle logout function */
    const handleLogout = () => {
        localStorage.removeItem("activePersonaDetails");
        localStorage.removeItem("authenticatedUser");
        localStorage.removeItem("activePersona");
        localStorage.removeItem("user");
        localStorage.removeItem("currentTokenNotification");
        localStorage.removeItem("virtualAssistantRefLink");

        Cookie.remove('userToken');
        Cookie.remove("activerPersona");

        window.location.pathname = "/login";
    };

    /* Handle change persona */
    const handleChangePersona = (persona) => {
        let data = {
            activePersona: persona
        };

        dispatch(changeActivePersona(data))
            .then(() => {
                dispatch(changeBuyerCurrentServiceRequest({}))
                dispatch(changeSellerCurrentServiceRequest({}))
            });
    }

    return (
        <Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", cursor: "pointer" }}>
                <Badge>
                    <img loading="lazy" src={Account} onClick={handleClick} className="icon" alt="" />
                </Badge>
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                disableScrollLock
                MenuListProps={{
                    disablePadding: true,
                    paddingRight: '0 !important',
                    width: 'calc(100% + 0) !important'
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "366px",
                        height: "707px",
                        boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15) !important',
                        marginTop: '10px',
                        paddingBottom: '0 !important',
                       
                        "&::-webkit-scrollbar": {
                            width: "10px",
                            background: "#0097A7",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "#fff",
                            borderRadius: "12px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: "10px",
                            background: "#0097A7",
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <div className="profile-menu">
                    <div className="content">
                        <img src={profilePicture !== "" && profilePicture !== "test" && profilePicture !== null ? profilePicture : ProfileImageSVG} alt="" className="profile-image" />
                        
                        <p className="profile-name"> {activeUser?.name} {""} {activeUser?.familyName} </p>
                        <p className="profile-email"> {activeUser?.userEmail} </p>
                        
                        <p className="profile-account"> {activeProfilePersona} </p>

                        <div className="button-view" onClick={handleOpenProfile}>
                            <img src={CarbonViewFilled} alt="" />
                            <p className="text"> {t("Виж")} </p>
                        </div>

                        <p className="also-you-can"> {t("Също така можете")} </p>

                        { activeUser?.activePersona !== "buyer"
                            ?   <Button className="button" onClick={() => handleChangePersona("buyer")}> {t("Продължи като купувач")} </Button>
                            :   null
                        }

                        { activeUser?.activePersona !== "seller"
                            ?   <Button className="button" onClick={() => handleChangePersona("seller")}> {t("Продължи като продавач")} </Button>
                            :   null
                        }

                        { activeUser?.activePersona !== "lawyer"
                            ?   <Button className="button" onClick={() => handleChangePersona("lawyer")}> {t("Продължи като адвокат")} </Button>
                            :   null
                        }

                        { activeUser?.activePersona !== "photographer"
                            ?   <Button className="button" onClick={() => handleChangePersona("photographer")}> {t("Продължи като фотограф")} </Button>
                            :   null
                        }

                        { activeUser?.activePersona !== "virtualassistant"
                            ?   <Button className="button" onClick={() => handleChangePersona("virtualassistant")}> {t("Продължи като асистент")} </Button>
                            :   null
                        }
                    </div>

                    <Button className="button-logout" onClick={handleLogout}> {t("Изход")} </Button>
                </div>
            </Menu>
        </Fragment>
    );
};

export default ProfileMenu;