import Cookie from "js-cookie";
import { setLocalStorageItem } from "../../../config/localStorageEncryption";
import {listPropertyOffers} from "../../../features/property/propertySlice";
import {sellerRedirectNotification} from "../../../features/user/sellerSlice";

export   const handleLogout = () => {

    localStorage.removeItem("activePersonaDetails");
    localStorage.removeItem("authenticatedUser");
    localStorage.removeItem("activePersona");
    localStorage.removeItem("user");
    localStorage.setItem("currentTokenNotification", "");
    localStorage.removeItem("virtualAssistantRefLink");
    Cookie.remove('userToken');
    Cookie.remove("activerPersona")
    window.location.pathname="/login"

  };

export const dispatchPropertyOffers = (item,dispatch,location)=>{
    let redirectUrl = "";
     
    dispatch(listPropertyOffers(item?.propertyId)).then((r) => {

        if (r?.payload?.data?.length) {

          r?.payload?.data?.forEach((offer) => {

            if (offer?.propertyOfferId === item?.propertyId) {
                setLocalStorageItem("offer", offer);
              }

          });

          redirectUrl = `/seller/dashboard`;

          dispatch(sellerRedirectNotification(redirectUrl));

          if (location?.pathname === "/seller/dashboard") {
            window.location.reload();
          }

        }
      });

}