import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogContent } from "@material-ui/core";

import Images from "../../../assets/uploaded_images.json";
import Videos from "../../../assets/uploaded_videos.json";

import { multiprofileDetails } from "../../../features/common/mutiprofile.Slice";

import { getProperVirtualEducationVideo } from "../../../helpers/getProperVirtualEducationVideo"
import { allSupportedLanguages } from "../../../features/languages/allSupportedLanguages"

import "./VAEducationVideoModal.css";

const CloseModal = Images["close-modal.svg"];
const VideoEnglish = Videos["en"];
const VideoPlayIcon = Images["play.svg"];

const mapState = ({ multiprofileSlice }) => ({
    showVAEducationVideo: multiprofileSlice?.multiprofileDetails?.showVAEducationVideo,
});

const VAEducationVideoModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { showVAEducationVideo } = useSelector(mapState);

    const [isEnglish, setIsEnglish] = useState(false)

    const [currentVideoUrl, setCurrentVideoUrl] = useState(getProperVirtualEducationVideo())
    const [languageNativeName, setLanguageNativeName] = useState('')
    const [shouldPlay, setShouldPlay] = useState(false)

    const videoLang = localStorage.getItem("videoLang")

    const [isPlaying, setPlaying] = useState(true);

    const videoRef = useRef(null);

    const findLanguageNativeName = () => {
        const language = allSupportedLanguages.find((lang) => lang.code === videoLang);

        setLanguageNativeName(language.nativeName);
        setCurrentVideoUrl(Videos[language?.code])
    }

    const changeVideo = (newVideoUrl) => {
        setCurrentVideoUrl(newVideoUrl)
        setShouldPlay(true)
    }

    const handleLoadedData = () => {
        findLanguageNativeName()

        if (videoRef.current && shouldPlay) {
            videoRef.current.play()
            setShouldPlay(false)
        }
    }

    const handleClose = () => {
        dispatch(multiprofileDetails({
            showVAEducationVideo: false,
        }));
    };

    useEffect(() => {
        if (showVAEducationVideo) {
            const delay = 100;
            const timeoutId = setTimeout(() => {
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [showVAEducationVideo]);

    const handlePlayPause = () => {
        setPlaying((prev) => {
            return !prev;
        });
        const video = videoRef.current;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
    };

    useEffect(() => {
        const video = videoRef?.current;

        const handlePlay = () => {
            setPlaying(false);
        };

        const handlePause = () => {
            setPlaying(false);
        };

        const handleEnded = () => {
            setPlaying(false);
        };

        video?.addEventListener("play", handlePlay);
        video?.addEventListener("pause", handlePause);
        video?.addEventListener("ended", handleEnded);
        return () => {
            video?.removeEventListener("play", handlePlay);
            video?.removeEventListener("pause", handlePause);
            video?.removeEventListener("ended", handleEnded);
        };
    }, []);

    const handleListenFromEnglish = () => {
        setIsEnglish(true)
        changeVideo(VideoEnglish)
        setPlaying(true)
    }

    const handleBacktoOriginalLanguage = () => {
        changeVideo(getProperVirtualEducationVideo())
        setIsEnglish(false)
        setPlaying(true)
    }

    return (
        <Dialog
            disableScrollLock={true}
            className="dialog-common-multi-profile"
            onClose={handleClose}
            open={showVAEducationVideo}
            fullWidth={true}
            maxWidth="md"
            PaperProps={{
                style: {
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                },
            }}
        >
            <div className="dialog-va-education">
                <div className="title-header">
                    <h4 className="title">{t("Обучително видео")}</h4>
                    <img loading="lazy"
                        src={CloseModal}
                        className="close-icon"
                        alt=""
                        onClick={handleClose}
                    />
                </div>
                <DialogContent className="content">
                    <p className="sub-title">
                        {t("Гледайте видеото и научете всичко за платформата. Когато решите, че сте готови, вземете теста. Има 8 въпроса. Очакваме от вас 8 верни отговора. Давате ни ги и започвате да действате.")}
                    </p>

                    <div className="video">
                        <div className="video-play-button-container">
                            {!isPlaying && (
                                <img loading="lazy"
                                    className="video-play-button"
                                    onClick={handlePlayPause}
                                    src={VideoPlayIcon}
                                    alt=""
                                />
                            )}
                        </div>
                        <video ref={videoRef}
                            key={currentVideoUrl}
                            controls controlsList="nodownload"
                            onLoadedData={handleLoadedData}
                            src={currentVideoUrl}
                            className="video-play" autoPlay>
                        </video>
                    </div>

                    <div className="actions">
                        {isEnglish ?
                            <button className="video-option-button" onClick={handleBacktoOriginalLanguage}>{languageNativeName}</button>
                            : videoLang !== "en" && <button className="video-option-button" onClick={handleListenFromEnglish}>English</button>
                        }
                        <Button onClick={handleClose} className="button-back">
                            {t("Назад")}
                        </Button>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
};

export default VAEducationVideoModal;
