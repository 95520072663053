import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import instance from '../../config/axiosConfig'

let initialState = {
    sentOffers: null,
    listedProperties: null,
    serviceRequests: null,
    soldProperties: null,
    isChrome:false
}


export const getAbidorsStatistics = createAsyncThunk(
    `/configurations/get-abidors-statistics`,
    async (payload, thunkAPI) => {
        try {
            const response = await instance.get(`/configurations/get-abidors-statistics`);
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });


const statisticsSlice = createSlice({
    name: `statistics`,
    initialState,
    reducers: {
        getAbidorsStatisticsData: (state, action) => {
            return {
                ...state,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAbidorsStatistics.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(getAbidorsStatistics.fulfilled, (state, { payload }) => {
                state.loading = 'loaded'
                state.soldProperties = payload.data.find(statistic=>statistic?.statName==="soldProperties")?.statValue
                state.sentOffers=payload.data.find(statistic=>statistic?.statName==="sentOffers")?.statValue
                state.serviceRequests=payload.data.find(statistic=>statistic?.statName==="serviceRequests")?.statValue
                state.listedProperties=payload.data.find(statistic=>statistic?.statName==="listedProperties")?.statValue
                if(navigator.userAgent.toLowerCase().indexOf("chrome")!==-1){
                    state.isChrome=true
                }

            })
            .addCase(getAbidorsStatistics.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                state.soldProperties = null;
                state.listedProperties=null;
                state.serviceRequests=null;
                state.sentOffers=null;
                console.error(action.error.message)
            });

    }
})

export const { getAbidorsStatisticsData } = statisticsSlice.actions;
export default statisticsSlice.reducer