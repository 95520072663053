import React, { Fragment } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AiOutlineClose } from "react-icons/ai";
import { IoMenuOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Images from "../../../assets/uploaded_images.json";
import Cookie from "js-cookie";

const SideBar = () => {
    const { t } = useTranslation();

    const [state, setState] = React.useState({
        right: false,
    });

    /* Toggle responsive open menu */
    const toggleDrawer = (anchor, open) => (event) => {
        window.scrollTo(0, 0);
        
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    /* Handle logout */
    const handleLogout = () => {
        localStorage.removeItem("activePersonaDetails");
        localStorage.removeItem("authenticatedUser");
        localStorage.removeItem("activePersona");
        localStorage.removeItem("user");
        localStorage.removeItem("currentTokenNotification");
        localStorage.removeItem("virtualAssistantRefLink");
        Cookie.remove('userToken');
        Cookie.remove("activerPersona")
        window.location.pathname = "/login";
    };

    return (
        <Fragment>
            {["right"].map((anchor) => (
                <Fragment key={anchor}>
                    <div className="bell user-menu">
                        <IoMenuOutline color="#00BCD4" className="menu-burger" size={40} onClick={toggleDrawer(anchor, true)} />
                    </div>

                    <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
                        <div className="modal-header">
                            <span onClick={toggleDrawer(anchor, false)} className="closebutton">
                                <AiOutlineClose />
                            </span>
                        </div>

                        <div>
                            <div className="modal-body">
                                <Link to="/seller" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Начало")} </Link>
                                <Link to="/seller/profile" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Моят профил")} </Link>
                                <Link to="/third-party?lawyers" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Професионалисти")} </Link>
                                <Link to="/blog" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Блог")} </Link>

                                <div className="add-property clearfix" style={{ cursor: "pointer" }} onClick={toggleDrawer(anchor, false)}>
                                    <Link to="/seller/post-an-add/information" className="modal-link"> {t("Добави имот")} </Link>
                                </div>

                                <Link to="/seller/my-adds" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Моите обяви")} </Link>
                                <Link to="/search-properties" className="modal-link" onClick={toggleDrawer(anchor, false)}> {t("Разгледай имоти")} </Link>

                                <button className="modal-link register-modal modal-logout" onClick={handleLogout}> {t("Изход")} </button>
                            </div>
                        </div>
                    </SwipeableDrawer>
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SideBar;