import { Suspense, useEffect } from "react";
import "./LazyWrapper.css"; // Import your CSS file
import Images from "../../assets/uploaded_images.json"
import ReactGA4 from "react-ga4"
const AbidorsPin =Images["abidorsPin.png"]
const LazyWrapper = ({ children }) => {
  useEffect(()=>{
    if(process.env.REACT_APP_NODE_ENV !== "development"){
      ReactGA4.send({hitType:"pageview",page:window.location.pathname})
    }
  },[window.location.pathname])
  return (
    <Suspense
      fallback={
        <div className="loading-container">
          <img loading="lazy"
            src={AbidorsPin}
            alt="loading..."
            className="loading-image"
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default LazyWrapper;
