/* eslint-disable no-control-regex */
export function escapeHTML(str){
    return str.replace(/[<>"&]/g,function(match){
        return{
            '<':'&lt;',
            '>':'&gt;',
            '"':'&quot;',
            '&':'&amp;',
            '~':'&tilde;',
            '±':'&plusmn;',
            '×':'&times;',
            '÷':'&divide;',

        }[match]
    })
}


// validate and sanitize user input to avoid malicious injection

export const validateInput = (input) => {
    let userInput = input;

    // Regular expression excluding some characters that might be considered malicious
    const maliciousContentRegex = /^(?=.*\b(SELECT|INSERT|UPDATE|DELETE|CREATE|DROP|ALTER|EXEC|UNION|AND|OR|WHERE)\b).*$/gi;

    if (maliciousContentRegex.test(input)) {
        userInput = "";
    }

    return userInput;
}
