import contractInformationSeller from "../contracts/sellerContract.json";
import contractInformationBuyer from "../contracts/buyerContract.json";
import contractInformationVirtualAssistant from "../contracts/virtualAssistant.json";
import contractInformationLawyer from "../contracts/lawyerContract.json";
import contractInformationPhotographer from "../contracts/photographerContract.json";

export const handleContractBasedOnPersona = (type) => {

    switch (type) {
        case 'buyer':
            return contractInformationBuyer;
        case 'lawyer':
            return contractInformationLawyer;
        case 'photographer':
            return contractInformationPhotographer;
        case 'virtualAssistant':
            return contractInformationVirtualAssistant;
        case 'virtualassistant':
            return contractInformationVirtualAssistant;
        case 'seller':
            return contractInformationSeller;
        default:
            return {
                contractService: "",
                alsoKnown: "",
                sideParagraphs: "",
                subjectContracts: "",
                commissionReward: "",
                rightsAndObligations: "",
                termOfContract: "",
                finalProvisions: ""
            };
    }
}