import React, { Fragment, forwardRef } from 'react'
import moment from "moment";

const  SingleNotification=forwardRef(({notification,handleNotification},ref)=>{
    
   const notificationBody=(<Fragment>
    <div
      className="notification-row clearfix"
      style={{
        fontWeight: !notification?.read ? "bold" : "normal",
      }}
      onClick={() => handleNotification(notification)}
    >
      <div className="left-part">
        <p className="notification-title translate">
          {notification?.title}
        </p>
        <p className="notification-content translate">
          {notification?.content}
        </p>
      </div>

      <div className="right-part">
        <p className="notification-date">
          {moment(notification?.dateCreated)?.format(
            "DD.MM.YYYY HH:mm"
          )}
        </p>
      </div>
    </div>
    <hr style={{ margin: 0 }} />
  </Fragment>)
  const content=ref?<div ref={ref} >{notificationBody}</div>:<div>{notificationBody}</div>
  return content

})

export default SingleNotification