import axios from 'axios'
import { t } from 'i18next';
import { toast } from 'react-toastify';
import Cookie from "js-cookie";

const userLang= localStorage.getItem("userLang") || navigator.language;
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': userLang,
  }
});
const handleLogout = (errorMessage) => {
  localStorage.removeItem("activePersonaDetails");
  localStorage.removeItem("authenticatedUser");
  localStorage.removeItem("activePersona");
  localStorage.removeItem("user");
  localStorage.removeItem("currentTokenNotification");
  localStorage.removeItem("virtualAssistantRefLink");
  Cookie.remove('userToken');
  Cookie.remove("activePersona");

  // Check if the current page is not the login page
  if (window.location.pathname !== "/login") {
    // Redirect to login page with error message as query parameter
    window.location.href = `/login?error=${encodeURIComponent(errorMessage)}`;
  } else {
    // If already on the login page, just show the error
    toast.error(errorMessage);
  }
};

instance.interceptors.response.use(
  response => response, // Simply return the response if no error
  error => {
    // Check if the error is due to file size limit
    if (error.response && error.response.status === 413) {
      // Handle the file size error
      // You can customize the error message or perform any action needed
      toast.error(t('Имате право да качвате видео до 200mb'));
      document.getElementById("loading-indicator").classList.remove('open');
    }
    if (error.response && error.response.status === 403) {
      const message=error.response?.data?.Message||error?.response?.data?.errors?.join(", ")||t("Неразрешено")
      handleLogout(message);
    }
    return Promise.reject(error);
    // // For other errors, simply return the error to trigger.catch()
  }
);

export default instance