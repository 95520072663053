export const routeLoader = (componentImport, maxRetry = 3) => {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        if (maxRetry === 0) {
          reject(error);
        }
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
          //   window.location.reload();
          componentImport()
            .then((component) => {
              resolve(component);
            })
            .catch((error) => {
              routeLoader(componentImport, --maxRetry);
            });
        }
      });
})};