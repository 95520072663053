import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserLocationDetailByName } from "../../hooks/useTranslator";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

const initialState = {
  propertyOffers: [],
  propertyList: [],
  promotedPropertyList: [],
  propertyListWithPromotion: [],
  propertyId: "",
  chosenPropertyDetails: {},
  error: null,
  isChangesSaved: true,
  loading: "idle",
  filters: {},
  propertyHover: "",
  locationPrev: {},
};

export const listProperties = createAsyncThunk(
  "property/listProperties",
  async (data, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let properPage = 1;

      if (data?.page) {
        properPage = data?.page;
      }

      const response = await instance.get(
        `/property?page=${properPage}&size=50`,
        config
      );
      return await response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getListOfNeighborhoods = createAsyncThunk(
  "property/getListOfNeighborhoods",
  async (neighborhood, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `municipality/queryName?name=${neighborhood}`,
        config
      );
      return await response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const listPromotedProperties = createAsyncThunk(
  "property/listPromotedProperties",
  async (propertyPage, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let url = "property/pro/promotedproperties";

      let region = await getUserLocationDetailByName("city");

      const municipality = await getUserLocationDetailByName("state_prov");

      if (region && municipality) {
        url = `/property/pro/promotedproperties?municipality=${municipality}&page=${propertyPage}&size=6&region=${region}`;
      }

      const response = await instance.get(`${url}`, config);

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const propertyMedia = createAsyncThunk(
  "property/propertyMediaId",
  async (propertyMediaId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.delete(
        `propertyMedia/${propertyMediaId}`,
        config
      );
      return await response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const listPropertyOffers = createAsyncThunk(
  "property/listPropertyOffers",
  async (id, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const propertyId = id || getLocalStorageItem("propertyId");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (!propertyId) {
        return;
      }

      const response = await instance.get(
        `seller/getallpropertyoffersbypropertyid/${propertyId}`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPropertyDetails = createAsyncThunk(
  "property/getPropertyDetails",
  async (propId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const propertyId = getLocalStorageItem("propertyId") || propId;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(`property/${propertyId}`, config);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getОffersbypropertyid = createAsyncThunk(
  "property/getoffersbypropertyid",
  async (propertyId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `property/getoffersbypropertyid/${propertyId}`,
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "property/deleteProperty",
  async (propertyId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.delete(`property/${propertyId}`, config);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPropertyWithFilters = createAsyncThunk(
  "property/getPropertyWithFilters",
  async (data, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let url = `property/filtered?`;

      if (Object?.keys(data)?.length) {
        Object.keys(data).forEach((value, index) => {
          let item = data[value];
          let paramName = value;
          let isFirstParam = false;
          let diffFilter = "";

          if (index === 0) {
            isFirstParam = true;
          }

          if (paramName === "page") {
            return;
          }

          if (Array.isArray(item)) {
            if (paramName === "valueBudget") {
              diffFilter = `${
                data?.valueBudget[0] || data?.valueBudget[0] === 0
                  ? `minPrice=${data?.valueBudget[0]}&`
                  : ""
              }${
                data?.valueBudget[1]
                  ? `maxPrice=${
                      data?.valueBudget[1] === 500000
                        ? 1000000000
                        : data?.valueBudget[1]
                    }`
                  : ""
              }`;
            } else {
              let stringData = data[value].map((val) => `"${val}"`).join(",");

              item = [stringData];
            }
          }

          if (
            (item && !Array.isArray(item)) ||
            (Array.isArray(item) && (item[0] || item[1]))
          ) {
            if (diffFilter) {
              url = url + `${isFirstParam ? "" : "&"}${diffFilter}`;
            } else {
              if (paramName === "mainSearchQuery" && item !== "") {
                url =
                  url +
                  `${isFirstParam ? "" : "&"}${paramName}=${encodeURI(item)}`;
              } else if (paramName === "originalSelectedAddress" && item !== "") {
                url =
                  url +
                  `${isFirstParam ? "" : "&"}${paramName}=${encodeURI(item)}`;
              }
              else if (paramName === "neighborhoodSearchQuery" && item !== "") {
                url =
                  url +
                  `${isFirstParam ? "" : "&"}${paramName}=${encodeURI(item)}`;
              }
              else if (paramName === "municipality") {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
              } else if (paramName === "region") {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
              } else if (paramName === "country") {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
              } else if (paramName === "continent") {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
              } else if (
                paramName === "minSquareMeter" ||
                paramName === "maxSquareMeter" ||
                paramName === "sortField" ||
                paramName === "sortOrder"
              ) {
                if (paramName === "sortField" && item === "isSold") {
                  url = url + `${isFirstParam ? "" : "&"}${item}=true`;
                } else {
                  url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
                }
              } else if (
                paramName === "constructionStage" ||
                paramName === "furnishing" ||
                paramName === "neighborhood" ||
                paramName === "propertyType" ||
                paramName === "constructionType" ||
                paramName === "closeTo" ||
                paramName === "heating"
              ) {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=[${item}]`;
              } else {
                url = url + `${isFirstParam ? "" : "&"}${paramName}=${item}`;
              }
            }
          }
        });
      }

      let properPage = 1;

      if (data?.page) {
        properPage = data?.page;
      }

      // url = url + `&isListed=approved&isActive=true&page=${properPage}&size=30`;
      url = url + `&isListed=approved&page=${properPage}&size=30`;

      const response = await instance.get(url, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const propertyProHandlePromoteProperty = createAsyncThunk(
  "property/pro/handle-promote-property",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/property/pro/handle-promote-property",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const propertyProConfirmPropertyPromotion = createAsyncThunk(
  "property/pro/handle-promote-property",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/property/pro/confirm-property-promotion",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getPropertyOffers = createAsyncThunk(
  "property/getPropertyOffers",
  async (id, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const activePersona =
        getLocalStorageItem("authenticatedUser")?.activePersona;

      let url = "getallpropertyoffersbypropertyid";

      if (activePersona === "buyer") {
        url = "getpropertyofferbyid";
      }

      const response = await instance.get(
        `${activePersona}/${url}/${id}`,
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPromotionPackages = createAsyncThunk(
  "property/getPromotionPackages",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `property/pro/promotionpackages`,
        payload,
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const promotionPackagesProperty = createAsyncThunk(
  "property/promotionPackagesProperty",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `property/pro/promotionpackagesbypropertyid`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPromotionProperties = createAsyncThunk(
  "property/getPromotionProperties",
  async (data, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let properPage = 1;

      if (data?.page) {
        properPage = data?.page;
      }

      const response = await instance.get(
        `/property/pro/filterwithproproperties?page=${properPage}&size=50`,
        config
      );
      return await response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePropertyImagesOrder = createAsyncThunk(
  "property/updatePropertyImagesOrder",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/propertyMedia/change-position-of-property-media`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePropertyActiveStatus = createAsyncThunk(
  "property/updatePropertyActiveStatus",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.put(
        `/property/${payload?.propertyId}`,
        {
          isActive: payload?.isActive,
        },
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sharePropertyByEmail = createAsyncThunk(
  "property/sharePropertyByEmail",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/property/share-property/${payload?.propertyId}`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    chosenProperty: (state, action) => {
      return {
        ...state,
        propertyId: action?.payload,
      };
    },
    propertyHover: (state, action) => {
      return {
        ...state,
        propertyHover: action?.payload,
      };
    },
    handleGlobalChanges: (state, action) => {
      return {
        ...state,
        isChangesSaved: action?.payload,
      };
    },
    filtersProperty: (state, action) => {
      if (action.payload?.continent) {
        return {
          ...state,
          filters: {
            ...state.filters,
            country: "",
            continent: "",
            region: "",
            municipality: "",
            neighborhood: "",
            locationLatitude: "",
            locationLongitude: "",
            originalSelectedAddress: "",
            ...action?.payload,
          },
        };
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...action?.payload,
          },
        };
      }
    },
    locationPrev: (state, action) => {
      return {
        ...state,
        locationPrev: {
          ...state.locationPrev,
          ...action?.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPropertyOffers.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(listPropertyOffers.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.propertyOffers = [...payload.data];
      })
      .addCase(listPropertyOffers.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error fetching property offers";
        state.propertyOffers = [];
      })
      .addCase(listProperties.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(listProperties.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.propertyList = action.payload.data;
        return state;
      })
      .addCase(listProperties.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        // state.property = []
        console.error(action.error.message);
      })
      .addCase(getPromotionProperties.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPromotionProperties.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.propertyListWithPromotion = action.payload.data;
        return state;
      })
      .addCase(getPromotionProperties.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        // state.property = []
        console.error(action.error.message);
      })
      .addCase(getPropertyDetails.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPropertyDetails.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.chosenPropertyDetails = payload?.data;
      })
      .addCase(getPropertyDetails.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting property data";
        state.chosenPropertyDetails = {};
      })
      .addCase(getPropertyWithFilters.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPropertyWithFilters.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.propertyList = payload?.data;
      })
      .addCase(getPropertyWithFilters.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting property data";
        state.propertyList = {};
      })
      .addCase(listPromotedProperties.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(listPromotedProperties.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        if (payload?.data?.result) {
          payload.data.result = arrangeProperties(payload.data.result);
        }
        state.promotedPropertyList = payload?.data;
      })
      .addCase(listPromotedProperties.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting property data";
        state.promotedPropertyList = {};
      })
      .addCase(getPropertyOffers.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPropertyOffers.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.propertyOffers = payload?.data;
      })
      .addCase(getPropertyOffers.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting property data";
        state.propertyOffers = [];
      })
      .addCase(updatePropertyImagesOrder.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updatePropertyImagesOrder.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.propertyOffers = payload?.data;
      })
      .addCase(updatePropertyImagesOrder.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting property data";
        state.propertyOffers = [];
      });
  },
});

export const {
  chosenProperty,
  handleGlobalChanges,
  propertyHover,
  filtersProperty,
  locationPrev,
} = propertySlice.actions;

export default propertySlice.reducer;


export const geocodeAddressLocation = async (address) => {
  try {
    document.getElementById("loading-indicator").classList.add("open");
    const response = await instance.post(
      `/configurations/geocode-address`,
      {
          address: address
      }
    );
    document.getElementById("loading-indicator").classList.remove("open");
    return response.data;
  } catch (error) {
    document.getElementById("loading-indicator").classList.remove("open");
    return toast.error(error?.response?.data?.message);
  }
};

const arrangeProperties = (propertyArr) => {
  const transformedArray = [];

  const mid = Math.ceil(propertyArr.length / 2);

  for (let i = 0; i < mid; i++) {
    transformedArray.push(propertyArr[i]);
    if (i + mid < propertyArr.length) {
      transformedArray.push(propertyArr[i + mid]);
    }
  }

  return transformedArray;
};

export const visitProperty = createAsyncThunk(
  "property/visitProperty",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const propertyId =
        getLocalStorageItem("propertyId") || payload?.propertyId;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          recaptchatoken: payload?.recaptchatoken,
        },
      };

      const response = await instance.get(
        `property/visit-property/${propertyId}`,
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSanitizedMainSearchQuery = (
  municipality,
  region,
  country,
  continent,
  locationAddress
) => {
  // Case-insensitive regular expression to match the country, continent, municipality, and region
  // var countryRegex = new RegExp(country, "gi");
  // var continentRegex = new RegExp(continent, "gi");
  // var municipalityRegex = new RegExp(municipality, "gi");
  // var regionRegex = new RegExp(region, "gi");

  // // Use the replace method to remove matches
  // var resultAddress = locationAddress
  //   .replace(countryRegex, "")
  //   .replace(continentRegex, "")
  //   .replace(municipalityRegex, "")
  //   .replace(regionRegex, "");

  // // Check if the result address is empty or contains only spaces and commas
  // if (/^[\s,]*$/.test(resultAddress)) {
  //   resultAddress = "";ij
  // } else {
  //   // Trim leading and trailing spaces and commas
  //   resultAddress = resultAddress.replace(/^[\s,]+|[\s,]+$/g, "");
  // }

  // return resultAddress;
  return locationAddress;
};
