import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  tooltip: {
    fontSize: "15px",
  },
  customTooltip: {
    margin: "0px !important",
    padding: "0px !important",
  },
  priceNumber: {
    display: "inline-block",
    padding: "4px 8px",
    background: "#E0F7FA",
    borderRadius: "4px",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "32px",
    textTransform: "uppercase",
    color: "#000000",
    width: "170px",
    border: "none",
  },
  error: {
    border: "1px solid #F32013",
  },
});
