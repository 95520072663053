import React from "react";

/* Libraries */
import { Button, Tooltip, Typography } from "@material-ui/core";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

/* Icons and styles */

import Images from "../../../assets/uploaded_images.json";

import "./PropertyCardIsStillActive.css";

import { useTranslation } from "react-i18next";
import { setLocalStorageItem } from "../../../config/localStorageEncryption";
import { chosenProperty, updatePropertyActiveStatus } from "../../../features/property/propertySlice";

const House = Images["home-city-outline.svg"];
const Area = Images["floorArea.svg"];
const DefaultImage = Images["common-image.jpg"];
const Bed = Images["bed.svg"];
const Shower = Images["bathroom.svg"];
const Info = Images["tooltip.svg"];
const Floor = Images["floor.svg"];

const ProperyCardIsStillActive = ({ closeToast, ...propertyInformation }) => {

  const { index, property } = propertyInformation;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const formatMoney = (amount) =>
    (Math.round(amount * 100) / 100).toLocaleString();

  /* HANDLE CHANGE EDIT PROPERTY SELLER */

  const handleStillActive = () => {
    dispatch(updatePropertyActiveStatus({
      propertyId: property?.propertyId,
      isActive: true
    }))
  };

  const handleDeactivate = () => {
    dispatch(updatePropertyActiveStatus({
      propertyId: property?.propertyId,
      isActive: false
    }))
  };

  const properImageURL = (count = false) => {
    let imagesArray = [];


    console.log({
      'property?.propertyMedia': property?.propertyMedia
    })

    property?.propertyMedia?.forEach((item) => {
      if (item?.type === "image") {
        imagesArray.push(item);
      }
    });

    if (count) {
      return imagesArray?.length;
    }

    return imagesArray[0]?.documentUrl;
  };

  const selectProperty = (e) => {
    e?.preventDefault();

    setLocalStorageItem("propertyId", property?.propertyId);
    setLocalStorageItem("propertyIdWithRefreshPage", property?.propertyId);

    dispatch(chosenProperty(property?.propertyId));

    navigate(`/property-details?${property?.propertyId}`);

    window.scrollTo(0, 0);
  };


  return (
    <div key={index} container className="card-property-is-still-active">
      <div className="card-item card-content">
        <img loading="lazy"
          className="main-image"
          src={properImageURL(true) ? properImageURL() : DefaultImage}
          onClick={selectProperty}
          alt=""
        />
        <div className="card-text-content">
          <div className="tl">
            <Typography className="price" variant="h4" onClick={selectProperty}>
              {property?.price ? `€ ${formatMoney(property?.price)}` : "-"}
            </Typography>

            <div className="info-box">
              <div className="icon-group">
                <img loading="lazy"  className="icon" src={Area} alt="" />
                {property?.squareMeter ? (
                  <span>
                    {property?.squareMeter} {property?.areaUnitType === "m2" ? <span>m<sup>2</sup></span> : <span>ft<sup>2</sup></span>}
                  </span>
                ) : null}
              </div>
              <div className="icon-group">
                <img loading="lazy"  className="icon" src={Bed} alt="" />
                <span>{property?.noOfBedroom || "-"}</span>
              </div>
              <div className="icon-group">
                <img loading="lazy"  className="icon" src={Shower} alt="" />
                <span>{property?.noOfBath || "-"}</span>
              </div>
              <div className="icon-group">
                <img loading="lazy"  className="icon" src={Floor} alt="" />
                <span>{property?.floor || "-"}</span>
              </div>
            </div>

            <div className="location-details" title={property?.locationAddress}>
              <img loading="lazy"  className="icon" src={House} alt="" />
              <Tooltip placement="top" title={property?.locationAddress} enterTouchDelay={0}>
                <h6 className="text" variant="h6">
                  {property?.locationAddress}
                </h6>
              </Tooltip>
            </div>
          </div>

          <div
            className="property-size-and-id"
          >
            {property?.pricePerSquareMeter ? (
              <div className="square-meters">
                <Typography className="text" variant="h6">
                  €{Math.round(property?.pricePerSquareMeter)}/{property?.areaUnitType === "m2" ? <span>m<sup>2</sup></span> : <span>ft<sup>2</sup></span>}
                </Typography>
                <Tooltip
                  enterTouchDelay={0}
                  placement="top"
                  title={t("Цена на квадратен метър")}
                >
                  <img loading="lazy"  src={Info} alt="" />
                </Tooltip>
              </div>
            ) : null}

            <Typography className="offer-text" variant="h6">
              {t("Обява")} № {property?.propertyId}
            </Typography>
          </div>



        </div>
      </div>
      <div>
        <hr className="separator" />
      </div>


      <div className="action-container">
        <span> {t("Имотът все още ли е активен?")} </span>
        <div className="btns-container">
          <Button
            variant="contained"
            className="btn-promote"
            onClick={handleStillActive}
          >
            {t("Все още активен")}
          </Button>
          <Button
            variant="text"
            className="btn-change"
            onClick={handleDeactivate}
          >
            {t("Деактивирай")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProperyCardIsStillActive;
