import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";
import { toast } from "react-toastify";
import { updateVirtualTourApiThunkFn } from "../../utils";
import { getPanoPreview } from "../../helpers/panolensHelpers";

const initialState = {
  isLoading: false,
  panoramas: [],
  floorPlan: "",
  currentPanoIndex: null,
  virtualTourId: null,
  virtualTourName: null,
  viewer: null,
  newSpot: null,
  newPanorama: null,
};

const getToken = () => Cookie.get("userToken");
const token = getToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

export const updateVirtualTourAPI = createAsyncThunk(
  "virtualTour/updateVirtualTourAPI",
  updateVirtualTourApiThunkFn
);

export const getVirtualTour = createAsyncThunk(
  "virtualTour/getVirtualTour",
  async (payload, thunkAPI) => {
    try {
      const response = await instance.get(`/virtual-tours/${payload}`, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const virtualTourSlice = createSlice({
  name: "virtualTour",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setVirtualTour: (state, action) => {
      let { virtualTourId, virtualTourName, panoramas, floorPlan } =
        action?.payload;
      if (panoramas.length > 0) {
        panoramas = panoramas.map((pano) => {
          pano = getPanoPreview(pano);
          return {
            ...pano,
          };
        });
      }
      state.virtualTourId = virtualTourId;
      state.virtualTourName = virtualTourName;
      state.panoramas = panoramas;
      state.floorPlan = floorPlan?.src || floorPlan;
      state.currentPanoIndex =
      panoramas.length > 0
        ? panoramas[panoramas.length - 1].imagePid
        : null;
    },
    setVirtualTourId: (state, action) => {
      state.virtualTourId = action.payload;
    },
    setCurrentPanoIndex: (state, action) => {
      state.currentPanoIndex = action.payload;
    },
    addPanorama: (state, action) => {
      const { panorama } = action.payload;
      state.panoramas.push(panorama);
    },
    deletePano: (state, action) => {
      const { panoId } = action.payload;
      state.panoramas = state.panoramas.filter((pano) => pano.id !== panoId);
    },
    updatePanorama: (state, action) => {
      const { panoId, panorama } = action.payload;
      state.panoramas = state.panoramas.map((pano) =>
        pano.id === panoId ? panorama : pano
      );
    },
    updateFloorPlan: (state, action) => {
      const { floorPlan } = action.payload;
      state.floorPlan = floorPlan;
    },
    updateVirtualTour: (state, action) => {
      const { virtualTourName } = action.payload;
      state.virtualTourName = virtualTourName;
    },
    setPanoramas: (state, action) => {
      let newPanoramas = action.payload
      state.panoramas = newPanoramas;
      state.currentPanoIndex =
      newPanoramas.length > 0
        ? newPanoramas[newPanoramas.length - 1].imagePid
        : null;
    },
    setNewSpot: (state, action) => {
      state.newSpot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVirtualTour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVirtualTour.fulfilled, (state, action) => {
        state.isLoading = false;
        let { virtualTourId, virtualTourName, panoramas, floorPlan } =
          action.payload.data;
        state.virtualTourId = virtualTourId;
        state.virtualTourName = virtualTourName;
        // get pano preview
        panoramas = panoramas.map((pano) => {
          pano = getPanoPreview(pano);
          return {
            ...pano,
          };
        });
        state.panoramas = panoramas;
        state.floorPlan = floorPlan && floorPlan;
        state.currentPanoIndex =
          panoramas.length > 0
            ? panoramas[panoramas.length - 1].imagePid
            : null;
      })
      .addCase(getVirtualTour.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload.error);
      })
      .addCase(updateVirtualTourAPI.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVirtualTourAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        const {
          isAddingPanorama,
          isUpdatePanorama,
          isDeleting,
          isInfospot,
          isFloorplan,
          isTourName,
          newPanoramas,
          floorPlan,
          tourName,
        } = action.meta.arg;
        const { data } = action.payload.data;
        if (isAddingPanorama) {
          let { panoramas } = data[0];
          state.currentPanoIndex = panoramas[panoramas.length - 1].imagePid;
          if (panoramas.length > 0) {
            panoramas = panoramas.map((pano) => {
              pano = getPanoPreview(pano);
              return {
                ...pano,
              };
            });
          }
          state.panoramas = panoramas;
          toast.success("Scene added successfully", { position: "top-center" });
        }
        if (isUpdatePanorama) {
          toast.success("Scene name updated successfully", {
            position: "top-center",
          });
        }
        if (isDeleting) {
          if (isDeleting === "panorama") {
            state.currentPanoIndex =
              newPanoramas.length > 0
                ? newPanoramas[newPanoramas.length - 1].imagePid
                : null;
            state.panoramas = newPanoramas.length > 0 ? newPanoramas : [];
            toast.success("Scene deleted successfully", {
              position: "top-center",
            });
          }
          if (isDeleting === "infospot") {
            toast.success("Spot deleted successfully", {
              position: "top-center",
            });
          }
        }
        if (isInfospot) {
          state.newSpot = data;
          toast.success("Spot added successfully", { position: "top-center" });
        }
        if (isFloorplan) {
          state.floorPlan = floorPlan;
          toast.success(
            `Floorplan ${floorPlan ? "added" : "removed"} successfully`,
            { position: "top-center" }
          );
        }
        if (isTourName) {
          state.virtualTourName = tourName;
          toast.success("Name updated successfully", {
            position: "top-center",
          });
        }
      })
      .addCase(updateVirtualTourAPI.rejected, (state, action) => {
        state.isLoading = false;
        const {
          isSorting,
        } = action.meta.arg;
        if (!isSorting) toast.error(action.payload.error);
      });
  },
});

export const {
  reset,
  setVirtualTour,
  addPanorama,
  deletePano,
  updatePanorama,
  updateFloorPlan,
  updateVirtualTour,
  setCurrentPanoIndex,
  setViewer,
  setVirtualTourId,
  setPanoramas,
  setNewSpot
} = virtualTourSlice.actions;

export default virtualTourSlice.reducer;
