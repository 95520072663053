import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import instance from '../../config/axiosConfig';

const initialState = {
    success:false,
    challenge_ts:"",
    hostname:"",
    score:0.0,
    action:"",
    loading:"idle"
}


export const getRecaptchaScore = createAsyncThunk(

"configurations/verify-recaptcha-token",

async (payload,thunkAPI) => {

    try{
    const {recaptchaToken} =  payload

    const body ={
        recaptchaToken
    }

    const response = await instance.post("/configurations/verify-recaptcha-token",body)

    return response

    }catch(error){

    return thunkAPI.rejectWithValue({ error: error.message })

    }
}
)


const googleRecaptchaSlice = createSlice({
    name:"recaptcha",
    initialState,
    reducers:{},
    extractReducers:(builder)=>{
       builder
       .addCase(getRecaptchaScore.pending,(state)=>{
        state.loading ="loading"
        state.success=false
        state.challenge_ts=""
        state.hostname=""
        state.score=0.0
        state.action=""
       })
       .addCase(getRecaptchaScore.fulfilled,(state,action)=>{
        state.loading ="loaded"
        state.success = action?.payload?.data?.success
        state.challenge_ts = action.payload?.date?.challenge_ts
        state.hostname = action?.payload?.data?.hostname
        state.score = action?.payload?.data?.score
        state.action = action.payload.action
        
       })
       .addCase(getRecaptchaScore.rejected,(state,action)=>{
        state.loading = 'rejected'
        state.error = action.payload
        state.success=false
        state.challenge_ts=""
        state.hostname=""
        state.score=0.0
        state.action=""
       })
    }
})

export default googleRecaptchaSlice.reducer