import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isPromote:false,
    isEdit:false,

}


export const sellerActionSlice = createSlice({
    name:"sellerAction",
    initialState,
    reducers:{
        goTopromotionTab:(state)=>{
            state.isPromote =  true
            state.isEdit = false
        },
        goToInformationTab:(state)=>{
            state.isEdit = true
            state.isPromote = false
        }
    }
})


export const {
    goToInformationTab,
    goTopromotionTab
} =  sellerActionSlice.actions

export default sellerActionSlice.reducer