import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";

import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

const initialState = {
  loading: "idle",
  isLoading: false,
  isError: false,
  profileUpdateSuccess: false,
  error: null,
  virtualAssistantActivePersona: null,
  virtualAssistantProfile: null,
  singleVirtualAssistant: null,
  allBuyerSellerRequests: null,
  virtualAssistantSections: [],
  sectionCreateSuccess: false,
  testQuestions: [],
  certified: false,
  vACertifications: [],
  notificationRedirect: "",
  stripeStatus: null,
  referralData:{}
};

const getToken = () => Cookie.get("userToken");

export const getVirtualAssistantStripeStatus = createAsyncThunk(
  "virtualAssistant/stripeStatus",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/payment/stripeStatus`, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Return a single virtual Assistant profile information
export const getVirtualAssistantProfile = createAsyncThunk(
  "virtualAssistant/getVirtualAssistantProfile",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/virtual-assistants/${payload}`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const viewVirtualAssistantStripeDashbaord = createAsyncThunk(
  "virtualAssistant/viewStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/payment/loginLink`, config);

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "buyer/createStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const virtualAssistantsProfileDetails = getLocalStorageItem("activePersonaDetails");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/virtual-assistants/${virtualAssistantsProfileDetails?.virtualAssistantId}/stripe`,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return toast.error(error?.response?.data?.message);
    }
  }
);

export const updateVirtualAssistantProfile = createAsyncThunk(
  "virtualAssistant/updateVirtualAssistantProfile",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const { virtualAssistantId, formData } = payload;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.put(
        `/virtual-assistants/${virtualAssistantId}`,
        formData,
        config
      );
      thunkAPI.dispatch(getVirtualAssistantProfile(virtualAssistantId));
      return response;
    } catch (error) {
      if (
        error?.response?.data?.errors[0] ===
        "value too long for type character varying(255)"
      ) {
        return toast.error(
          "Описанието е твърде дълго не трябва да превишава 1000 символа"
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTestQuestions = createAsyncThunk(
  "virtualAssistant/getTestQuestions",
  async (_, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let virtualAssistantId;

      if (getLocalStorageItem("activePersonaDetails")) {
        virtualAssistantId = getLocalStorageItem("activePersonaDetails")?.virtualAssistantId;
      }
      const response = await instance.get(
        `virtual-assistants/${virtualAssistantId}/quiz`,
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const certifyVirtualAssistant = createAsyncThunk(
  "virtualAssistant/certifyVirtualAssistant",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { virtualAssistantId, score, isPassed } = payload;
      const data = { score, isPassed };
      const response = await instance.post(
        `/virtual-assistants/${virtualAssistantId}/certifications`,
        data,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getVirtualAssistantCertifications = createAsyncThunk(
  "virtualAssistant/getVirtualAssistantCertifications",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const virtualAssistantId = payload;
      const response = await instance.get(
        `/virtual-assistants/${virtualAssistantId}/certifications`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Change the profile photo of a virtual Assistant
export const updateProfilePicture = createAsyncThunk(
  "virtualAssistant/updateProfilePicture",
  async (payload, thunkAPI) => {
    try {
      const { virtualAssistantId, picture } = payload;
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.put(
        `/virtual-assistants/${virtualAssistantId}/images`,
        picture,
        config
      );
      thunkAPI.dispatch(getVirtualAssistantProfile(virtualAssistantId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteProfilePicture = createAsyncThunk(
  "seller/deleteProfilePic",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const personaId =getLocalStorageItem("activePersonaDetails")?.virtualAssistantId;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { fileType: "profilePicture" },
      };

      const response = await instance.delete(
        `/virtual-assistants/${personaId}/images`,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getVirtualAssistantSections = createAsyncThunk(
  "virtualAssistant/getVirtualAssistantSections",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const virtualAssistantId = payload;
      const response = await instance.get(
        `/virtual-assistants/${virtualAssistantId}/sections`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createVirtualAssistantSection = createAsyncThunk(
  "virtualAssistant/createVirtualAssistantSection",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { virtualAssistantId, sectionData } = payload;
      const response = await instance.post(
        `/virtual-assistants/${virtualAssistantId}/sections`,
        sectionData,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateVirtualAssistantSection = createAsyncThunk(
  "virtualAssistant/updateVirtualAssistantSection",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const { virtualAssistantId, sectionID, sectionData } = payload;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.put(
        `/virtual-assistants/${virtualAssistantId}/sections/${sectionID}`,
        sectionData,
        config
      );
      thunkAPI.dispatch(getVirtualAssistantSections(virtualAssistantId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSingleVirtualAssistantSection = createAsyncThunk(
  "virtualAssistant/getSingleVirtualAssistantSection",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const { virtualAssistantId, sectionID } = payload;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/virtual-assistants/${virtualAssistantId}/sections/${sectionID}`,
        config
      );
      thunkAPI.dispatch(getVirtualAssistantSections(virtualAssistantId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteVirtualAssistantSection = createAsyncThunk(
  "virtualAssistant/deleteVirtualAssistantSection",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const { virtualAssistantId, sectionID } = payload;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.delete(
        `/virtual-assistants/${virtualAssistantId}/sections/${sectionID}`,
        config
      );
      thunkAPI.dispatch(getVirtualAssistantSections(virtualAssistantId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getVirtualAssistantReferralData = createAsyncThunk(
  "virtualAssistant/get-referral-data",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/virtual-assistants/get-referral-data`,
        {},
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const virtualAssistantSlice = createSlice({
  name: "virtualAssistant",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetProfileUpdateState: (state) => {
      state.profileUpdateSuccess = false;
    },
    resetSectionCreateState: (state) => {
      state.sectionCreateSuccess = false;
    },
    virtualAssistantRedirectNotification: (state, action) => {
      return {
        ...state,
        notificationRedirect: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVirtualAssistantProfile.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getVirtualAssistantProfile.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.virtualAssistantProfile = action.payload.data;
      })
      .addCase(getVirtualAssistantProfile.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
        console.error(action.error.message);
      })

      .addCase(updateVirtualAssistantProfile.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updateVirtualAssistantProfile.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.profileUpdateSuccess = true;
      })
      .addCase(updateVirtualAssistantProfile.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(getTestQuestions.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getTestQuestions.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.testQuestions = action.payload.data;
      })
      .addCase(getTestQuestions.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
        console.error(action.error.message);
      })

      .addCase(certifyVirtualAssistant.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(certifyVirtualAssistant.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.certified = true;
      })
      .addCase(certifyVirtualAssistant.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
        console.error(action.error.message);
      })

      .addCase(getVirtualAssistantCertifications.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getVirtualAssistantCertifications.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.vACertifications = action.payload.data;
      })
      .addCase(getVirtualAssistantCertifications.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(getVirtualAssistantSections.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getVirtualAssistantSections.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.virtualAssistantSections = action.payload.data;
      })
      .addCase(getVirtualAssistantSections.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(createVirtualAssistantSection.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createVirtualAssistantSection.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.sectionCreateSuccess = true;
      })
      .addCase(createVirtualAssistantSection.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(updateVirtualAssistantSection.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updateVirtualAssistantSection.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.sectionCreateSuccess = true;
      })
      .addCase(updateVirtualAssistantSection.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(deleteVirtualAssistantSection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVirtualAssistantSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sectionCreateSuccess = true;
      })
      .addCase(deleteVirtualAssistantSection.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        state.isError = true;
      })
      .addCase(getVirtualAssistantStripeStatus.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getVirtualAssistantStripeStatus.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.stripeStatus = action.payload.data.result;
      })
      .addCase(getVirtualAssistantStripeStatus.rejected, (state, action) => {
        state.loading = "rejected";
        console.log("error found: ");
      })
      .addCase(getVirtualAssistantReferralData.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getVirtualAssistantReferralData.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.referralData = action.payload.data.result;
      })
      .addCase(getVirtualAssistantReferralData.rejected, (state, action) => {
        state.loading = "rejected";
        console.log("error found: ");
      });
  },
});

export const {
  reset,
  resetProfileUpdateState,
  resetSectionCreateState,
  virtualAssistantRedirectNotification,
} = virtualAssistantSlice.actions;
export default virtualAssistantSlice.reducer;
