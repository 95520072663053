import * as PANOLENS from "panolens"

export const getPanoPreview = (pano) => {
  const img = new Image();
  if(pano.imageUrl.includes("blob") || pano.imageUrl.includes("base64") || pano.imageUrl.includes("data") ){
    img.src = pano.imageUrl;
  }else {
    img.crossOrigin = "anonymous";
    img.src = pano.imageUrl + "?random=" + Math.random();
  }
  const newPanorama = new PANOLENS.ImagePanorama(img);
  return {
    ...pano,
    id: pano?.panoramaId,
    panorama: newPanorama,
    panoramaName: pano?.panoramaName,
  };
};