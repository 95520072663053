import React, { useEffect } from "react";
import { handleLogout } from "../../Header/HeaderSeller/functions";
import Cookie from "js-cookie";

import { getLocalStorageItem } from "../../../config/localStorageEncryption";

function AuthChecker() {
    useEffect(() => {
        const token = Cookie.get('userToken');
        const activePersona = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser")?.activePersona : null;

        if (!token && activePersona) {
            handleLogout()
        }
    }, []);

    return <div />;
}

export default AuthChecker;