const io = require("socket.io-client");

const  baseURL= process.env.REACT_APP_API_BASE_URL;

const socket = io.connect(baseURL,{
    autoConnect: false
});


const joinRoom = (roomID) => {
    socket.emit("join_room", roomID);
};

module.exports={
    socket,
    joinRoom,
}