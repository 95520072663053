import React, { useEffect, useRef, useState } from "react";
import "./VAHomeSection.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../config/localStorageEncryption";
import Images from "../../../assets/uploaded_images.json";
import Videos from "../../../assets/uploaded_videos.json";
import { getProperVirtualEducationVideo } from "../../../helpers/getProperVirtualEducationVideo";
import { allSupportedLanguages } from "../../../features/languages/allSupportedLanguages";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const VideoPlayIcon = Images["play.svg"];

const mapState = ({ statisticsSlice }) => ({
    isChrome: statisticsSlice.isChrome,
});

function VAHomeSection({ hideBanner, onlyVideo }) {
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const videoRef = useRef(null);

    const [isPlaying, setPlaying] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState(getProperVirtualEducationVideo());

    const { isChrome } = useSelector(mapState);
    const [shouldPlay, setShouldPlay] = useState(false);
    const isMobileReached = useMediaQuery({ query: "(max-width: 600px)" });
    const is527pxReached = useMediaQuery({ query: "(max-width: 527px)" });
    const is500pxReached = useMediaQuery({ query: "(max-width: 500px)" });
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const videoLang = localStorage.getItem("videoLang");

    useEffect(() => {
        const video = videoRef.current;

        const handlePlay = () => {
            setPlaying(true);
        };

        const handlePause = () => {
            setPlaying(false);
        };

        const handleEnded = () => {
            setPlaying(false);
        };

        video.addEventListener("play", handlePlay);
        video.addEventListener("pause", handlePause);
        video.addEventListener("ended", handleEnded);

        return () => {
            video.removeEventListener("play", handlePlay);
            video.removeEventListener("pause", handlePause);
            video.removeEventListener("ended", handleEnded);
        };
    }, []);

    const findLanguageNativeName = () => {
        const language = allSupportedLanguages.find((lang) => lang.code === videoLang);
        setCurrentVideoUrl(Videos[language?.code]);
    };

    const handleLoadedData = () => {
        findLanguageNativeName();

        setTimeout(() => {
            setIsVideoLoading(false);
        }, 1000);

        if (videoRef.current) {
            videoRef.current.currentTime = 1;

            if (shouldPlay) {
                videoRef.current.play();
                setShouldPlay(false);
            }
        }
    };

    const handlePlayPause = () => {
        setPlaying((prev) => {
            return !prev;
        });

        const video = videoRef.current;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
    };

    const setPersona = (personna) => {
        if (location?.search) {
            let splitLocationUrlGetRefLink = location?.search.split("?ref=");
            setLocalStorageItem("virtualAssistantRefLink", splitLocationUrlGetRefLink[1]);
        }

        setLocalStorageItem("activePersona", personna);
        navigate("/registration");
    };

    return (
        <>
            {
                onlyVideo
                    ?   <div className="video">
                            <div className="video-play-button-container">
                                {!isPlaying && isChrome && (
                                    <img loading="lazy" className="video-play-button" onClick={handlePlayPause} src={VideoPlayIcon} alt="" />
                                )}
                            </div>

                            {isVideoLoading && (
                                <div className="video-loader">
                                    <div className="video-play-button-container">
                                        {/* <img loading="lazy" className="video-play-button" src={VideoPlayIcon} alt="" /> */}
                                        <div className="circular-loader" onClick={handlePlayPause}>
                                            <Box sx={{ display: "flex" }}>
                                                <CircularProgress size={is500pxReached ? 47 : is527pxReached ? 58.5 : 90} />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <video
                                ref={videoRef}
                                controls
                                controlsList="nodownload"
                                key={currentVideoUrl}
                                onLoadedData={handleLoadedData}
                                onPlay={() => setPlaying(true)}
                                // className="video-play-"
                                autoPlay={false}
                                style={{
                                    height: isMobileReached ? "250px" : hideBanner ? "revert" : "700px",
                                    borderRadius: '30px',
                                    width: "1000px"
                                }}
                            >
                                <source type="video/mp4" src={currentVideoUrl} />
                            </video>
                        </div>
                    :   <div className="VAHomeSection" style={{
                            ...hideBanner && {
                                margin: 0,
                                marginTop: "20px"
                            }
                        }}>
                            {
                                !hideBanner && (
                                    <div className="description-container">
                                        <h3>{t("Присъединете се към нас като виртуален асистент в недвижими имоти")}</h3>
                                        <p>
                                            {t(
                                                "Платформа за допълнителни доходи и постоянен поток от приходи по целия свят. Твоето присъствие не е необходимо по време на огледите на имота, което осигурява гъвкавост. Привличай клиенти чрез препоръки и положителни ревюта в профила си. Като виртуален асистент, ще напътстваш клиентите си през сделките на платформата, но не и с правни въпроси. Стреми се към 5-звездни ревюта на твоя публичен профил. За всяка подписана сделка чрез Abidors получаваш комисионна от 0.2%, която се удвоява, ако си нает от двете страни. Няма времеви ограничения за прием на запитване от клиент, но първият виртуален асистент приел, става такъв за сделката . С най-добри пожелания и успехи в пътешествието наречено Abidors!"
                                            )}
                                        </p>
                                        <button onClick={() => setPersona("virtualAssistant")}>
                                            {t("Започнете своето пътуване като виртуален асистент")}
                                        </button>
                                    </div>
                                )
                            }
                            <div className="video">
                                <div className="video-play-button-container">
                                    {!isPlaying && isChrome && (
                                        <img
                                            loading="lazy"
                                            className="video-play-button"
                                            onClick={handlePlayPause}
                                            src={VideoPlayIcon}
                                            alt=""
                                        />
                                    )}
                                </div>
                                {isVideoLoading && (
                                    <div className="video-loader">
                                        <div className="video-play-button-container">
                                            <img
                                                loading="lazy"
                                                className="video-play-button"
                                                src={VideoPlayIcon}
                                                alt=""
                                            />
                                            <div className="circular-loader" onClick={handlePlayPause}>
                                                <Box sx={{ display: "flex" }}>
                                                    <CircularProgress
                                                        size={is500pxReached ? 47 : is527pxReached ? 58.5 : 90}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <video
                                    ref={videoRef}
                                    controls
                                    controlsList="nodownload"
                                    key={currentVideoUrl}
                                    onLoadedData={handleLoadedData}
                                    onPlay={() => setPlaying(true)}
                                    className="video-play"
                                    autoPlay={false}
                                    style={{
                                        height: isMobileReached ? "250px" : hideBanner ? "revert" : "700px",
                                        width: "100%",
                                        objectFit: ""
                                    }}
                                >
                                    <source type="video/mp4" src={currentVideoUrl} />
                                </video>
                            </div>
                        </div>
            }
        </>
        
    );
}

export default VAHomeSection;
