import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from '../../config/axiosConfig';
import Cookie from 'js-cookie'

const SLICE_URL = '/services';

const getToken = () => Cookie.get('userToken');
const token = getToken();
const config = {
    headers: { Authorization: `Bearer ${token}` }
}

const initialState = {
    serviceDetails: [],
    serviceList: [],
    isError: false,
    isSuccess: false,
    isSaveSuccess: false,
    showIsSuccess: false,
    isLoading: false,
    message: "",
}

export const listServices = createAsyncThunk(
    "service/listServices",
    async (payload, thunkAPI) => {
        try {
            const { type, personaID } = payload;
			const response = await instance.get(`${SLICE_URL}?serviceType=${type}&personaID=${personaID}`, config);
    		return response.data;
        } catch (error) {
            const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
					error.message ||
					error.toString();
    
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createService = createAsyncThunk(
	"service/createService",
	async (payload, thunkAPI) => {
        // payload: { serviceType: 'legal/photography', requestBody: { } }
		try {
            const { serviceType, requestBody } = payload;
			const response = await instance.post(`${SLICE_URL}?serviceType=${serviceType}`, requestBody, config);
			return response.data;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
					error.message ||
					error.toString();
    
            return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getSingleService = createAsyncThunk(
	"service/getSingleService",
	async (payload, thunkAPI) => {
		try {
			const response = await instance.get(`${SLICE_URL}/${payload}`, config);
			return response.data;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
					error.message ||
					error.toString();
    
            return thunkAPI.rejectWithValue(message);
		}
	}
);

export const updateService = createAsyncThunk(
    "service/updateService",
    async (payload, thunkAPI) => {
        // payload: { serviceID, type, formData }
        try {
            const { serviceID, type, formData } = payload;
			const response = await instance.put(`${SLICE_URL}/${serviceID}?serviceType=${type}`, formData, config);
            // thunkAPI.dispatch(listServices({payload})); // input correct values for payload.
			return response.data;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
					error.message ||
					error.toString();
    
            return thunkAPI.rejectWithValue(message);
		}
    }
)

export const deleteService = createAsyncThunk(
    "service/deleteService",
    async (payload, thunkAPI) => {
        // payload: serviceID
        try {
            const { serviceId, serviceType } = payload;
			const response = await instance.delete(`${SLICE_URL}/${serviceId}?serviceType=${serviceType}`, config);
            // thunkAPI.dispatch(listServices({payload})); // input correct values for payload.
			return response.data;
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
					error.message ||
					error.toString();
    
            return thunkAPI.rejectWithValue(message);
		}
    }
)

export const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
		resetService: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isSaveSuccess = false;
			state.showIsSuccess = false;
			state.isError = false;
			state.message = '';
		},
	},
    extraReducers: (builder) => {
        builder
            .addCase(listServices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(listServices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.serviceList = action.payload;
            })
            .addCase(listServices.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(createService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSaveSuccess = true;
                state.serviceDetails = action.payload;
            })
            .addCase(createService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(getSingleService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSingleService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.showIsSuccess = true;
                state.serviceDetails = action.payload;
            })
            .addCase(getSingleService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(updateService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(deleteService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSaveSuccess = true;
            })
            .addCase(deleteService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    }
});

export const { 
    resetService
} = serviceSlice.actions;

export default serviceSlice.reducer;