import { createSlice } from '@reduxjs/toolkit';

let initialState = {
        activeProperty: null,
        isOpenModal: false,
        isViewingImageModalOpen:false,
        activePropertyImage:null
}

const propertyViewSlice = createSlice({
    name: "emailssubscription",
    initialState,
    reducers: {
        propertyViewDetails: (state, action) => {
            return {
                ...state,
                    ...action?.payload
            }
        },
    },
    
})

export const { propertyViewDetails } = propertyViewSlice.actions;
export default propertyViewSlice.reducer