/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Images from "../../../assets/uploaded_images.json";
import {
  changeStripeAccountSetupDetails,
  checkUserPendingPaymentsAndStripeNotSetUp,
} from "../../../features/common/stripeSetupSlice";
import "./StripeSetupModal.css";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../config/localStorageEncryption";
import { toast } from "react-toastify";
import { createStripeAccount as createStripeAccountPhotographer } from "../../../features/user/photographerSlice";
import { createStripeAccount as createStripeAccountLawyer } from "../../../features/user/lawyerSlice";
import { createStripeAccount as createStripeAccountVirtuaAssistant } from "../../../features/user/virtualAssistantSlice";
import { useMediaQuery } from "react-responsive";
const CloseModal = Images["close-modal.svg"];

const mapState = ({ stripeAccountSetupSlice }) => ({
  isOpenModal: stripeAccountSetupSlice?.isOpenModal,
  hasPendingPayments: stripeAccountSetupSlice?.hasPendingPayments,
  canProcceedPayments: stripeAccountSetupSlice?.canProcceedPayments,
});

const StripeSetupModal = () => {
  const isMobilePhone = useMediaQuery({ query: "(max-width: 780px)" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpenModal, hasPendingPayments, canProcceedPayments } =
    useSelector(mapState);
  const [isModified, setIsModified] = useState(0);
  const authenticatedUser = getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")
    : {};
  const activePersona = authenticatedUser?.activePersona;
  const handleClose = () => {
    dispatch(
      changeStripeAccountSetupDetails({
        isOpenModal: false,
      })
    );
  };
  const handleContinue = () => {
    if (activePersona === "lawyer") {
      dispatch(createStripeAccountLawyer()).then((r) => {
        if (r?.payload?.data) {
          const localStorageActivePersonaDetails = getLocalStorageItem(
            "activePersonaDetails"
          );
          localStorageActivePersonaDetails["stripeAccountId"] =
            r?.payload?.data?.accountID;
          setLocalStorageItem(
            "activePersonaDetails",
            localStorageActivePersonaDetails
          );
          if (isMobilePhone) {
            window.location.href = r?.payload?.data?.redirectUrl;
          } else {
            window.open(r?.payload?.data?.redirectUrl);
          }
        }
      });
    } else if (activePersona === "photographer") {
      dispatch(createStripeAccountPhotographer()).then((r) => {
        if (r?.payload?.data) {
          const localStorageActivePersonaDetails = getLocalStorageItem(
            "activePersonaDetails"
          );
          localStorageActivePersonaDetails["stripeAccountId"] =
            r?.payload?.data?.accountID;
          setLocalStorageItem(
            "activePersonaDetails",
            localStorageActivePersonaDetails
          );
          if (isMobilePhone) {
            window.location.href = r?.payload?.data?.redirectUrl;
          } else {
            window.open(r?.payload?.data?.redirectUrl);
          }
        }
      });
    } else if (activePersona === "virtualassistant") {
      dispatch(createStripeAccountVirtuaAssistant()).then((r) => {
        if (r?.payload?.data) {
          const localStorageActivePersonaDetails = getLocalStorageItem(
            "activePersonaDetails"
          );
          localStorageActivePersonaDetails["stripeAccountId"] =
            r?.payload?.data?.accountID;
          setLocalStorageItem(
            "activePersonaDetails",
            localStorageActivePersonaDetails
          );
          if (isMobilePhone) {
            window.location.href = r?.payload?.data?.redirectUrl;
          } else {
            window.open(r?.payload?.data?.redirectUrl);
          }
        }
      });
    }
  };
  useEffect(() => {
    if (
      activePersona === "lawyer" ||
      activePersona === "photographer" ||
      activePersona === "virtualassistant"
    ) {
      dispatch(checkUserPendingPaymentsAndStripeNotSetUp());
    }
  }, []);

  useEffect(() => {
    if (hasPendingPayments && !canProcceedPayments) {
      dispatch(
        changeStripeAccountSetupDetails({
          isOpenModal: true,
        })
      );
    }
  }, [hasPendingPayments, canProcceedPayments]);
  return (
    <Dialog
      disableScrollLock={true}
      className="dialog-common-stripe-setup-modal"
      onClose={handleClose}
      open={isOpenModal}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          width: "600px",
        },
      }}
    >
      <div className="dialog-common-stripe-setup-modal-content">
        <div className="title-header">
          <h4 className="title">{t("Изисква се акаунт за плащане")}</h4>
          <img
            loading="lazy"
            src={CloseModal}
            className="close-icon"
            alt=""
            onClick={handleClose}
          />
        </div>

        <DialogContent className="content">
          <p className="sub-title">
            {t(
              "Има предстоящо плащане. За да получите вашите средства, моля, създайте платежната си сметка. Това ще гарантира, че вашите настоящи и бъдещи плащания се обработват сигурно и своевременно."
            )}
          </p>
          <div className="actions">
            <Button onClick={handleContinue} className="button-continue">
              {t("Настройте сметка за плащане")}
            </Button>
            <Button onClick={handleClose} className="button-back">
              {t("Назад")}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default StripeSetupModal;
