import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import "./ProgressBar.css";
import { t } from "i18next";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            height: 15,
            borderRadius: 20,
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          fontSize={20}
          variant="body2"
          color="text.secondary"
          style={{
            marginTop: "20px",
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const mapState = ({ progressSlice }) => ({
  uploadProgress: progressSlice?.uploadProgress,
  isProgressBarVisible: progressSlice?.isProgressBarVisible,
  type: progressSlice?.type,
});

export default function ProgressBar() {
  const { uploadProgress, isProgressBarVisible, type } = useSelector(mapState);
  return isProgressBarVisible ? (
    <div className="progress-main-container">
      <div className="coverer"></div>
      <div className="progress-bar-container">
        {type === "propertyListing" && (
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={uploadProgress} />
          </Box>
        )}
        {type !== "updateAvailable" && (
          <span>
            {t(
              "Моля, имайте търпение, докато качваме вашите снимки/видео. Може да отнеме повече време, ако качите повече снимки"
            )}
          </span>
        )}
        {type !== "updateAvailable" && (
          <span>
            {t(
              "Моля, имайте търпение, докато качваме вашите снимки/видео. Може да отнеме повече време, ако качите повече снимки"
            )}
          </span>
        )}{" "}
        {type === "updateAvailable" && (
          <span>
            {t(
              "Актуализациите се извличат..."
            )}
          </span>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
