/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import "./LangSwitcher.css";
import {
  changeLanguage,
  getUserLang,
  getUserLocationDetailByName,
  translateOnload,
} from "../../../hooks/useTranslator";
import { userUpdatePrefferedLanguage } from "../../../features/user/authSlice";
import { allSupportedLanguages } from "../../../features/languages/allSupportedLanguages";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../config/localStorageEncryption";

export const LangSwitcher = () => {
  const dropdownRef = useRef(null);
  const [selectedLang, setSelectedLang] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const supportedLanguages = allSupportedLanguages;
  const dispatch = useDispatch();

  const activePersona = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser")?.activePersona : null;

  const userLang = getUserLang();
  const langValue = selectedLang ? selectedLang.code : userLang;

  const getLanguageByCode = (code) => {
    return supportedLanguages.find((lang) => lang.code === code);
  };

  useEffect(() => {
    const defaultLang =
      getLanguageByCode(userLang) ||
      supportedLanguages.find((lang) => lang.code === "bg");
    setSelectedLang(defaultLang);

    // Check if user is in Bulgaria and if the language is not Bulgarian
    getUserLocationDetailByName("countryName").then((countryName) => {
      if (countryName !== "Bulgaria" && userLang !== "bg") {
        translateOnload();
      }
    });
  }, []);

  const handleLanguageChange = (lang) => {
    if(activePersona) {
      dispatch(userUpdatePrefferedLanguage(lang.code)).then(() => {
        setSelectedLang(lang);
        changeLanguage(lang.code);
      });
    } else {
      setSelectedLang(lang);
        changeLanguage(lang.code);
    }
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const handleLanguageItemClick = (langCode) => {
    handleLanguageChange(getLanguageByCode(langCode));
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(userLang === "bg" || userLang === "ru") {
      document.body.classList.add('manrope-class')
    } else {
      document.body.classList.add('poppins-class')
    }
  }, [userLang])

  return (
    <div className="lang-switcher" style={{ maxHeight: "200px !important" }}>
      <div style={{ position: "relative" }} ref={dropdownRef}>
        <div
          onClick={toggleDropdown}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginRight: "4px",
          }}
        >
          <img
            loading="lazy"
            style={{
              borderRadius: '2px',
              marginTop:
                supportedLanguages.find((lang) => lang.code === langValue)
                  ?.codeForFlag === "bg"
                  ? "1px"
                  : "5px",
              width: "35px",
            }}
            src={`https://flagcdn.com/w80/${supportedLanguages
              .find((lang) => lang.code === langValue)
              ?.codeForFlag?.toLowerCase()}.png`}
            alt={langValue}
          />
          {isDropdownOpen ? (
            <MdArrowDropUp
              style={{ color: "black", marginTop: "5px", zIndex: 2 }}
            />
          ) : (
            <MdArrowDropDown
              style={{ color: "black", marginTop: "5px", zIndex: 2 }}
            />
          )}
        </div>

        {isDropdownOpen && (
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: "-80%",
              zIndex: 1000,
              width: "200px",
            }}
          >
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
                width: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#fff",
              }}
            >
              {supportedLanguages.map((lang) => (
                <div
                  key={lang.code}
                  onClick={() => handleLanguageItemClick(lang.code)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor:
                      lang.code === langValue ? "#f0f0f0" : "transparent",
                  }}
                >
                  <img
                    loading="lazy"
                    style={{
                      marginRight: "4px",
                      marginTop: lang?.codeForFlag === "bg" ? "-8px" : "-3px",
                      width: "35px",
                    }}
                    src={`https://flagcdn.com/w80/${lang?.codeForFlag?.toLowerCase()}.png`}
                    alt={lang.code}
                  />
                  {lang.nativeName}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
