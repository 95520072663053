import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";
import { toast } from "react-toastify";

const initialState = {
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
};

export const getPersonaTransactions = createAsyncThunk(
  "user/transaction",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const { properPage, size } = payload;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/persona-transactions?page=${properPage}&size=${size}`,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      toast.error(error?.response?.data?.message);
    }
  }
);

export const personaTransactionsSlice = createSlice({
  name: "personaTransactions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPersonaTransactions.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPersonaTransactions.fulfilled, (state, action) => {
        state.loading = "loaded";
        const { currentPage, totalPages, totalItems } = action?.payload?.data;
        state.currentPage = currentPage;
        state.totalPages = totalPages;
        state.totalItems = totalItems;
      })
      .addCase(getPersonaTransactions.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "error getting persona transactions data";
      });
  },
});

export default personaTransactionsSlice.reducer;
