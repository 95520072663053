import React from "react";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import Favicon from "react-favicon";
import Images from "./assets/uploaded_images.json"

import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import { fetchToken } from "./firebase"; 
const FaviconPNG = Images["favicon.png"];
const root =  ReactDom.createRoot(document.getElementById("root"))
import "./index.css"
// Register the service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function (registration) {
            console.log('Service Worker registered with scope:', registration.scope);

            // Wait for the service worker to be ready
            return navigator.serviceWorker.ready;
        })
        .then(function (registration) {
            console.log('Service Worker is active and ready.');

            // Now that the service worker is ready, fetch the FCM token
            fetchToken((token) => {
            });
        })
        .catch(function (err) {
            console.log('Service Worker registration failed:', err);
    });
}
  
root.render(
	<Provider store={store}>
		<Favicon url={FaviconPNG} />
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</BrowserRouter>
	</Provider>
);
