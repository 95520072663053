import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { propertyViewDetails } from "../../../../features/common/propertyViewSlice";
import Images from "../../../../assets/uploaded_images.json";
import "./PropertyImageViewModal.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const CloseModal = Images["close-modal.svg"];
const mapState = ({ propertyViewSlice }) => ({
  isViewingImageModalOpen: propertyViewSlice?.isViewingImageModalOpen,
  activePropertyImage: propertyViewSlice?.activePropertyImage,
  activeProperty: propertyViewSlice?.activeProperty,
});
function PropertyImageViewModal() {
  const dispatch = useDispatch();
  const { isViewingImageModalOpen, activePropertyImage, activeProperty } =
    useSelector(mapState);
  const handleClose = () => {
    dispatch(
      propertyViewDetails({
        isViewingImageModalOpen: false,
        activePropertyImage: null,
      })
    );
  };
  const [propertyImages, setPropertyImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClickPreviousImage = () => {
    let imagesLength=propertyImages?.length;
    setCurrentIndex(currentIndex===0?imagesLength-1:currentIndex-1)
  };
  const handleClickNextImage = () => {
    let imagesLength=propertyImages?.length;
    setCurrentIndex(currentIndex===imagesLength-1?0:currentIndex+1)
  };
  useEffect(() => {
    let images = activeProperty?.propertyMedia
      ?.map((media) => {
        if (media.type === "image") {
          return media?.documentUrl;
        }
      })
      ?.filter((media) => media && media);
      
      setCurrentIndex(images?.indexOf(activePropertyImage))

    setPropertyImages(images);
  }, [activePropertyImage]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        handleClickPreviousImage();
      } else if (event.key === 'ArrowRight') {
        handleClickNextImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);

  return (
    <Dialog
      disableScrollLock={true}
      onClose={handleClose}
      open={isViewingImageModalOpen}
      fullWidth={true}
      fullScreen={true}
      PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          width: "100%",
        },
      }}
    >
      <div className="dialog-property-image-view-modal-content">
        <div className="close-icon" onClick={handleClose}>
          <CloseIcon htmlColor="black" fontSize="15px" />
        </div>
        <div onClick={handleClickPreviousImage} className="previous-image-icon">
          <ArrowBackIosIcon htmlColor="white" fontSize="15px" />
        </div>
        <div onClick={handleClickNextImage} className="next-image-icon">
          <ArrowForwardIosIcon htmlColor="white" fontSize="15px" />
        </div>
       {propertyImages && <img loading="lazy"
          style={{
            objectFit: "contain",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
          src={propertyImages[currentIndex]}
        />}
      </div>
    </Dialog>
  );
}

export default PropertyImageViewModal;
