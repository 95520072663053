import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookie from 'js-cookie'
import instance from '../../config/axiosConfig';
import { toast } from 'react-toastify';
import { getLocalStorageItem } from '../../config/localStorageEncryption';

const initialState = {
    loading: 'idle',
    profileUpdateSuccess: false,
    error: null,
    lawyerActivePersona: null,
    lawyerProfileDetails: null,
    lawyerServices: null,
    lawyerReviews: null,
    notificationRedirect: "",
    stripeStatus: null

}

const getToken = () => Cookie.get('userToken')

export const getLawyerStripeStatus = createAsyncThunk(
    'lawyer/stripeStatus',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/payment/stripeStatus`, config
            )
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


// Return a single lawyer profile information
export const lawyerProfile = createAsyncThunk(
    'lawyer/lawyerProfile',
    async (payload, thunkAPI) => {
        
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/lawyers/${payload}`, config
            )
           
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const viewLawyerStripeDashbaord = createAsyncThunk(
    'lawyer/viewStripeAccount',
    async (_, thunkAPI) => {
        try {
            document.getElementById("loading-indicator").classList.add('open');

            const token = Cookie.get('userToken');

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/payment/loginLink`, config
            )

            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return toast.error(error?.response?.data?.message)
        }
})

export const createStripeAccount = createAsyncThunk(
    'buyer/createStripeAccount',
    async (_, thunkAPI) => {
        try {
            document.getElementById("loading-indicator").classList.add('open');
            const token = Cookie.get('userToken');

            const lawyerProfileDetils = getLocalStorageItem("activePersonaDetails");

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/lawyers/${lawyerProfileDetils?.lawyerPersonaId}/stripe`, config
            )

            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return toast.error(error?.response?.data?.message)
        }
    })

// Update lawyer profile information
export const lawyerUpdateProfile = createAsyncThunk(
    'lawyer/lawyerUpdateProfile',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const { lawyerID, formData } = payload;
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.put(
                `/lawyers/${lawyerID}`, formData, config
            )
            thunkAPI.dispatch(lawyerProfile(lawyerID));
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const createLawyerService = createAsyncThunk(
    'lawyer/createLawyerService',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            await instance.post(
                `/lawyer-service/createService`, payload, config
            )
            return await instance.get(
                `/lawyer-service/getAllLawyerServices`, payload.lawyerId, config
            )
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

export const getAllLwyerServices = createAsyncThunk(
    'lawyer/getAllLwyerServices',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/lawyer-service/getAllLawyerServices`, payload, config
            )
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

export const updateLawyerService = createAsyncThunk(
    'lawyer/updateLawyerService',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.post(
                `/lawyer-service/updateLawyerService`, payload, config
            )
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

export const deleteLawyerService = createAsyncThunk(
    'lawyer/deleteLawyerService',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            await instance.post(
                `/lawyer-service/deleteLawyerService`, payload, config
            )
            return payload;
            // return await instance.get(
            // 	`/lawyer-service/getAllLawyerServices`, payload.lawyerId, config
            // )
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Return all reviews specific to a lawyer
export const getLawyerReviews = createAsyncThunk(
    'lawyer/getLawyerReviews',
    async (payload, thunkAPI) => {
        try {
            const token = getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get(
                `/reviews/?type=lawyer&typeID=${payload}`, config
            )
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Change the profile photo of a lawyer
export const updateProfilePicture = createAsyncThunk(
    'lawyer/updateProfilePicture',
    async (payload, thunkAPI) => {
        try {
            const { lawyerID, picture } = payload;
            const token = getToken();
            
            const config = {
                headers: { Authorization: `Bearer ${token}`,
                "Content-Type":"multipart/form-data"
            },
            }
            const response = await instance.put(
                `/lawyers/${lawyerID}/images`, picture, config
            )

            thunkAPI.dispatch(lawyerProfile(lawyerID)); // input correct values for payload.
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const deleteProfilePicture = createAsyncThunk(
    'seller/deleteProfilePic',
	async (_,thunkAPI)=>{
		try{
			const token = Cookie.get('userToken');
			const personaId = getLocalStorageItem("activePersonaDetails")?.lawyerPersonaId;
			
            const config = {
				headers: { Authorization: `Bearer ${token}` },
				params:{fileType:"profilePicture"}
			}

			const response = await instance.delete(
			`/lawyers/${personaId}/images`, config
		)
		return response
		} catch (error){
			return toast.error(error?.response?.data?.message)
		}
	}
);



const lawyerSlice = createSlice({
    name: 'lawyer',
    initialState,
    reducers: {
        reset: (state) => initialState,
        resetProfileUpdateState: (state) => {
            state.profileUpdateSuccess = false;
        },
        lawyerRedirectNotification: (state, action) => {
            return {
                ...state,
                notificationRedirect: action?.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(lawyerProfile.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(lawyerProfile.fulfilled, (state, action) => {
                state.loading = 'loaded'
                state.lawyerProfileDetails = action.payload.data
            })
            .addCase(lawyerProfile.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message)
            })

            .addCase(lawyerUpdateProfile.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(lawyerUpdateProfile.fulfilled, (state, action) => {
                state.loading = 'loaded';
                state.profileUpdateSuccess = true;
                toast.success("Lawyer Profile Update Successful", { position: "bottom-right" });
            })
            .addCase(lawyerUpdateProfile.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message);
            })

            .addCase(createLawyerService.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(createLawyerService.fulfilled, (state, action) => {
                state.loading = 'loaded'
                state.lawyerServices = action.payload.data.data
            })
            .addCase(createLawyerService.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message)
            })

            .addCase(getAllLwyerServices.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(getAllLwyerServices.fulfilled, (state, action) => {
                state.loading = 'loaded'
                state.lawyerServices = action.payload.data.data
            })
            .addCase(getAllLwyerServices.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message)
            })

            .addCase(updateLawyerService.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(updateLawyerService.fulfilled, (state, getAllLwyerServices) => {
                state.loading = 'loaded'
                toast.success("Lawyer Service Update Successful", { position: "bottom-right" });
            })
            .addCase(updateLawyerService.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message)
            })

            .addCase(deleteLawyerService.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(deleteLawyerService.fulfilled, (state, action) => {
                state.loading = 'loaded'
                // state.lawyerServices = action.payload.data.data
                state.lawyerServices = state.lawyerServices.filter(service => service.lawyerServiceId !== action.payload.lawyerServiceId);
                toast.error("Lawyer Service Deleted", { position: "bottom-right" });
            })
            .addCase(deleteLawyerService.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                console.log('error found: ')
                console.error(action.error.message)
            })

            .addCase(getLawyerReviews.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(getLawyerReviews.fulfilled, (state, action) => {
                state.loading = 'loaded'
                state.lawyerReviews = action.payload.data
            })
            .addCase(getLawyerReviews.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
            })
            .addCase(getLawyerStripeStatus.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(getLawyerStripeStatus.fulfilled, (state, action) => {
                state.loading = 'loaded';
                state.stripeStatus = action.payload.data.result;
            })
            .addCase(getLawyerStripeStatus.rejected, (state, action) => {
                state.loading = 'rejected'
                console.log('error found: ');
            })
    }
})

export const { reset, resetProfileUpdateState,lawyerRedirectNotification } = lawyerSlice.actions;
export default lawyerSlice.reducer;