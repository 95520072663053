import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import instance from "../../config/axiosConfig";

const SLICE_URL = "/reviews";

const getToken = () => Cookies.get("userToken");
const token = getToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

const initialState = {
  reviewDetails: [],
  reviewList: [],
  isError: false,
  isSuccess: false,
  isSaveSuccess: false,
  showIsSuccess: false,
  isLoading: false,
  isLoadingReview: false,
  isErrorReview: false,
  message: "",
};

export const listReviews = createAsyncThunk(
  "review/listReviews",
  async (payload, thunkAPI) => {
    try {
      const { type, persona, personaID } = payload;
      const response = await instance.get(
        `${SLICE_URL}?type=${type}&persona=${persona}&personaID=${personaID}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createReview = createAsyncThunk(
  "review/createReview",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
        config.headers.recaptchatoken=payload?.recaptchatoken;
        delete payload?.recaptchatoken
      const response = await instance.post(`${SLICE_URL}`, payload, config);
      document.getElementById("loading-indicator").classList.remove("open");
      return response.data;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleReview = createAsyncThunk(
  "review/getSingleReview",
  async (payload, thunkAPI) => {
    try {
      const response = await instance.get(`${SLICE_URL}/${payload}`, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReview: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.showIsSuccess = false;
      state.isError = false;
      state.message = "";
      state.isLoadingReview = false;
      state.isErrorReview = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.reviewList = action.payload;
      })
      .addCase(listReviews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(createReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.isLoadingReview = false;
        state.isSaveSuccess = true;
        state.reviewDetails = action.payload;
      })
      .addCase(createReview.rejected, (state, action) => {
        state.isLoadingReview = false;
        state.isErrorReview = true;
        state.message = action.payload;
      })

      .addCase(getSingleReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showIsSuccess = true;
        state.reviewDetails = action.payload;
      })
      .addCase(getSingleReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetReview } = reviewSlice.actions;

export default reviewSlice.reducer;
