/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import "./HeaderLawyer.css";

import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";

import { messageNotifications, notifications, readNotifications } from "../../../features/user/authSlice";

import { lawyerRedirectNotification } from "../../../features/user/lawyerSlice";

import SideBarMenu from "./HeaderLawyerSideBar";

import { filtersProperty, geocodeAddressLocation } from "../../../features/property/propertySlice";
import { listServiceRequests, resetServiceRequest } from "../../../features/common/serviceRequestSlice";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
import { getUserLang } from '../../../hooks/useTranslator';

/* Components */
import Notifications from "./../../common/Notifications/Notification";
import MessageNotification from "../../common/Notifications/MessageNotification";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";
import CustomPlacesAutocomplete from "../../common/CustomPlacesAutocomplete";

/* Images */
const AccountGroup = Images["account-group.svg"];
const Logo = Images["Logo.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];

const HeaderLawyer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const userLanguage = getUserLang();

    const { t } = useTranslation();

    const colorValue = useMemo(() => ({ color: "#fff" }), []);

    const activePersonaDetails = getLocalStorageItem("activePersonaDetails") ? getLocalStorageItem("activePersonaDetails") : "";

    const lawyerPersonaId = activePersonaDetails.lawyerPersonaId;

    const messageRef = useRef(null);
    const messageRef2 = useRef(null);

    const [address, setAddress] = useState("");
    const [openNotificaration, setOpenNotification] = useState(false);
    const [openNotificaration2, setOpenNotification2] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    /* Handle click search icon */
    const handleClick = () => {
        navigate("/search-properties");

        dispatch(filtersProperty({
            otherPromises: {},
            propertyTypes: [],
            propertyNeighborhood: [],
            scrollPosition: 0,
            selectCloseTo: [],
            selectConstructionStage: [],
            selectConstructionType: [],
            selectEquipment: [],
            selectFloorType: [],
            selectHeating: [],
            selectOtherPremises: [],
            valueBudget: [0, 500000],
            address: "",
            beforeSelectNeighbothood: "",
            continent: "",
            country: "",
            municipality: "",
            neighborhood: "",
            region: "",
            locationLatitude: "",
            locationLongitude: "",
            sortBy: {},
            maxSquareMeter: "",
            minSquareMeter: "",
            maxYearBuilt: "",
            minYearBuilt: "",
            originalSelectedAddress: ""
        }));

        dispatch(filtersProperty({
            scrollPosition: 0
        }))
    };

    /* Handle select cities */
    const handleSelect = async (value) => {
        let resultAddress = await geocodeAddressLocation(value);
        if (
            !resultAddress?.formatted_address &&
                !resultAddress?.latitude &&
                !resultAddress?.longitude &&
                !resultAddress?.municipality &&
                !resultAddress?.region
        ) {
            toast.error("Please choose another suitable and accurate address");
        } else {
            let newSearchQuery = {
                address: resultAddress?.formatted_address,
                originalSelectedAddress: value
            };

            if (resultAddress?.municipality) {
                newSearchQuery.municipality = resultAddress?.municipality;
            }
            else{
                newSearchQuery.municipality =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhood = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhood =""
            }

            if (resultAddress?.region) {
                newSearchQuery.region = resultAddress?.region;
            }
            else{
                newSearchQuery.region =""
            }

            if (resultAddress?.continent) {
                newSearchQuery.continent = resultAddress?.continent;
            }
            else{
                newSearchQuery.continent =""
            }

            if (resultAddress?.country) {
                newSearchQuery.country = resultAddress?.country;
            }
            else{
                newSearchQuery.country =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhoodSearchQuery = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhoodSearchQuery =""
            }tAddress("");
            navigate("/search-properties");
            dispatch(filtersProperty(newSearchQuery));
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                openNotificaration &&
                messageRef.current &&
                !messageRef.current.contains(e.target)
            ) {
                setOpenNotification(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openNotificaration]);

    /* Handle click notification */
    const handleClickNotification = (item) => {
        let redirectUrl = "";
        dispatch(listServiceRequests({ providerId: lawyerPersonaId, serviceType: "legal" }));
        dispatch(resetServiceRequest());
        if (item?.notificationType === "cancelledJobRequest") {
            redirectUrl = "/lawyer/dashboard?persona=" + item?.senderPersona + "s";
        } else if (item?.notificationType === "serviceReview") {
            redirectUrl = "/lawyer/dashboard?persona=" + item?.senderPersona + "s";
        } else if (item?.notificationType === "receivePublicReview") {
            redirectUrl = "third-party/lawyer-profile/" + lawyerPersonaId;
        } else if (item?.notificationType === "receivePayment") {
            redirectUrl = "/lawyer/dashboard?persona=" + item?.senderPersona + "s";
        } else if (item?.notificationType === "paidAgreedAmount") {
            redirectUrl = "/lawyer/dashboard?persona=" + item?.senderPersona + "s";
        }

        if (item?.notificationType === "receiveJobRequest") {
            redirectUrl = "/lawyer/dashboard?section=inquiries";
        } else if (item?.notificationType === "atomChatNewMessage") {
            if (item?.senderPersona === "buyer" || item?.senderPersona === "seller") {
                redirectUrl = `/lawyer/dashboard${item?.senderPersona ? `?customerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
            }
        }

        dispatch(lawyerRedirectNotification(redirectUrl));

        dispatch(readNotifications(item?.notificationId)).then((r) => {
            if (r?.payload?.data?.status) {
                setOpenNotification(false);
                dispatch(notifications());
                dispatch(messageNotifications({ filters: { notificationType: "atomChatNewMessage" } }));
            }
        });
    };

    /* Handle focus input search filed */
    const handleFocus = () => {
        setIsFocused(true)
    };

    /* Handle unfocus input search filed */
    const handleBlur = () => {
        setIsFocused(false);
    };

    /* Scroll to top even if I'm on the same page */
    const handleClickScrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <header className="header-lawyer">
            <IconContext.Provider value={colorValue}>
                <nav className="navbar">
                    <div className="clearfix navbar-items" style={{ width: "100%" }}>
                        <Link to="/lawyer" className="navbar-logo">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <div className="lang-switcher-desk">
                            <div className="display-translation-switcher">
                                <LangSwitcher />
                            </div>
                        </div>

                        <ul className="nav-menu">
                            <li className="nav-item">
                                <Link to="/lawyer" className={location?.pathname === "/lawyer" ? "nav-links active" : "nav-links"}> {t("Начало")} </Link>
                            </li>

                            <li className="nav-item" onClick={handleClickScrollToTop}>
                                <Link to="/lawyer/dashboard?persona=buyer" className={location?.pathname === "/third-party" && location?.search === "?persona=buyer" ? "nav-links active" : "nav-links"}> {t("Купувачи")} </Link>
                            </li>

                            <li className="nav-item" onClick={handleClickScrollToTop}>
                                <Link to="/lawyer/dashboard?persona=sellers" className={location?.pathname === "/lawyer/dashboard" && location?.search === "?persona=sellers" ? "nav-links active" : "nav-links"}> {t("Продавачи")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/third-party?lawyers" className={location?.pathname === "/lawyer/dashboard" ? "nav-links active" : "nav-links"}> {t("Професионалисти")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/blog" className={location?.pathname === "/blog" ? "nav-links active" : "nav-links"}> {t("Блог")} </Link>
                            </li>
                        </ul>

                        <form className={isFocused ? "search focus-input-filed" : "search"}>
                            <div className="search-places-auto-complete-container">
                                <CustomPlacesAutocomplete value={validateInput(address) || ""} onChange={setAddress} onSelect={handleSelect}>
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <img src={HeaderSearchIcon} className="search-icon" onClick={handleClick} alt="" />

                                            <input className="search-field" {...getInputProps({ placeholder: `${t("Град")}` })}
                                                style={{
                                                    fontSize: "18px",
                                                    fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                    color: "rgba(186, 186, 186, 1);",
                                                    border: "none !important",
                                                    outline: "none",
                                                }}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />

                                            <div className="autocomplete-dropdown-container-home">
                                                {loading ? <div>...loading</div> : null}

                                                {suggestions.map((suggestion,key) => {
                                                    return (
                                                        <div  key={key} 
                                                     {...getSuggestionItemProps(suggestion, key)}>
                                                            {suggestion.placePrediction.text.toString()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </CustomPlacesAutocomplete>
                            </div>
                        </form>

                        <div className="right-part">
                            <div className="lang-switcher-mobile">
                                <div className="display-translation-switcher">
                                    <LangSwitcher />
                                </div>
                            </div>

                            <div className="account-group account-group-buyer" onClick={handleClickScrollToTop}>
                                <Link to="/lawyer/dashboard?persona=buyer" className="clearfix link">
                                    <img loading="lazy" src={AccountGroup} alt="" />

                                    <p className="title">{t("Моите клиенти")}</p>
                                </Link>
                            </div>

                            <div className="notification-icon">
                                <Notifications handleNotification={handleClickNotification} setOpenNotification={setOpenNotification} openNotificaration={openNotificaration} messageRef={messageRef} />
                            </div>

                            <div className="notification-icon">
                                <MessageNotification handleNotification={handleClickNotification} setOpenNotification={setOpenNotification2} openNotificaration={openNotificaration2} messageRef={messageRef2} />
                            </div>

                            <div className="bell">
                                <ProfileMenu personaId={lawyerPersonaId} />
                            </div>

                            <div className="sidebar-menu-icon">
                                <SideBarMenu lawyerPersonaId={lawyerPersonaId} />
                            </div>
                        </div>
                    </div>
                </nav>
            </IconContext.Provider>
        </header>
    );
};

export default HeaderLawyer;