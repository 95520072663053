export const nomenclatures = {
    /* Property type nomenclature */
    propertyTypeData: [
        { value: 'one-room apartment', label: 'Едностаен апартамент' },
        { value: 'two-room apartment', label: 'Двустаен апартамент' },
        { value: 'two-bedroom apartment', label: 'Тристаен апартамент' },
        { value: 'four-bedroom apartment', label: 'Четиристаен апартамент' },
        { value: 'multi-bedroom apartment', label: 'Многостаен апартамент' },
        { value: 'maisonette', label: 'Мезонет' },
        { value: 'house', label: 'Къща' },
        { value: 'floor', label: 'Етаж от къща' },
        { value: 'studio', label: 'Студио/Таван/Ателие' },
        { value: 'basement', label: 'Сутерен/Мазе/Партер' },
        { value: 'building', label: 'Сграда' },
        { value: 'villa', label: 'Вила' },
        { value: 'hall', label: 'Зала' },
        { value: 'shop', label: 'Магазин' },
        { value: 'a shopping center', label: 'Търговски обект' },
        // { value: 'café', label: 'Заведение' },
        { value: 'cafe', label: 'Заведение' },
        { value: 'industrial property', label: 'Промишлен имот' },
        { value: 'gas station', label: 'Бензиностанция' },
        { value: 'hotel', label: 'Хотел' },
        { value: 'composition', label: 'Склад' },
        { value: 'factory', label: 'Фабрика' },
        { value: 'agricultural property', label: 'Земеделски имот' },
        { value: 'agricultural property/farm', label: 'Селскостопански имот/Ферма' },
        { value: 'forest', label: 'Гора' },
        { value: 'dam', label: 'Язовир' },
        { value: 'prefabricated house', label: 'Сглобяема къща' },
        { value: 'project', label: 'Проект' }
    ],

    /* Neighborhood nomenclature */
    neighborhoodData: [
        {
            id: 1,
            title: 'Най-нови'
        },
        {
            id: 2,
            title: 'Най-гледани'
        },
        {
            id: 3,
            title: 'Цена(ниска към висока)'
        },
        {
            id: 4,
            title: 'Цена(висока към ниска)'
        },
        {
            id: 5,
            title: 'Горещи обяви '
        },
        {
            id: 6,
            title: 'Само ново строителство'
        }
    ],

    /* Other premises nomenclature */
    otherPremisesData: [
        { value: 'closet', label: 'Килер' },
        { value: 'wardrobe', label: 'Дрешник' },
        { value: 'basement', label: 'Мазе' },
        { value: 'ceiling', label: 'Таван' },
        { value: 'working office', label: 'Работен кабинет' }
    ],

    /* Heating nomenclature */
    heatingData: [
        { value: 'central heating', label: 'Централно парно' },
        { value: 'electricity', label: 'Ток' },
        { value: 'wood', label: 'Дърва' },
        { value: 'gas', label: 'Газ' },
        { value: 'floor heating', label: 'Подово отопление' }
    ],

    /* Floor type nomenclature */
    floorTypeData: [
        { value: 'laminate', label: 'Ламиниран паркет' },
        { value: 'wooden parquet', label: 'Дървен паркет' },
        { value: 'wooden floor', label: 'Дюшеме' },
        { value: 'terracotta', label: 'Теракот' }
    ],

    /* CloseTo nomenclature */
    closeToData: [
        { value: 'school', label: 'Училище' },
        { value: 'kinder garden', label: 'Детска градина' },
        { value: 'food store', label: 'Хранителен магазин' },
        { value: 'subway', label: 'Метростанция' },
        { value: 'hospital', label: 'Болница' },
        { value: 'bank', label: 'Банка' },
        { value: 'gas station', label: 'Бензиностанция' },
        { value: 'fitness', label: 'Фитнес' },
        { value: 'park', label: 'Парк' },
        { value: 'airport', label: 'летище' },
        { value: 'beach', label: 'плаж' },
        { value: 'golf course', label: 'голф игрище' },
    ],

    /* Construction stage nomenclature */
    constructionStageData: [
        { value: 'project', label: 'В проект' },
        { value: 'AKT 14', label: 'Акт 14' },
        { value: 'AKT 15', label: 'Акт 15' },
        { value: 'AKT 16', label: 'Акт 16' }
    ],

    /* Construction type nomenclature */
    constructionTypeData: [
        { value: 'large-area formwork', label: 'ЕПК' },
        { value: 'brick', label: 'Тухла' },
        { value: 'panel', label: 'Панел' },
        { value: 'trimmerjoists', label: 'Гредоред' },
        { value: 'prefabricated construction', label: 'Сглобяема конструкция' }
    ],

    /* Equipment nomenclature */
    equipmentData: [
        { value: 'fully furnished', label: 'Напълно обзаведен' },
        { value: 'unfurnished', label: 'Необзаведен' },
        { value: 'partly furnished', label: 'Частично обзаведен' }
    ],

    /* Exposition nomenclature */
    expositionData: [
        { value: 'south', label: 'Южно' },
        { value: 'north', label: 'Северно' },
        { value: 'east', label: 'Източно' },
        { value: 'west', label: 'Западно' },
        { value: 'northeast', label: 'североизток' },
        { value: 'northwest', label: 'Северозападна' },
        { value: 'southeast', label: 'югоизток' },
        { value: 'southwest', label: 'югозапад' }  
    ],
    /* Area unit */

    areaUnitsData: [
        { value: 'm2', label: 'm²' },
        { value: 'ft2', label: 'f²' },
    ],
}