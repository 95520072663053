import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookie from 'js-cookie'
import instance from '../../config/axiosConfig';
import Images from "../../assets/uploaded_images.json"
const ProfilePhotoWoman = Images['profile-photo-woman.jpg'];
const ProfilePhotoMan = Images['profile-photo-man.jpg'];

const initialState = {
    messages: [
        {
            name: 'Катя Катьова',
            message_to: 'Здравейте, Стоян, ',
            message: 'Искам да получа правна консултация във връзка със закупуване на недвижим имот, който е находящ в гр. Пловдив, р-н Централен',
            time: '11:02 AM',
            status: '',
            backgroundImage: ProfilePhotoWoman,
            userRole: "Купувач"
        },
        {
            name: 'Емил Емилов',
            message_to: 'Здравейте, Стоян, ',
            message: 'Искам да получа правна консултация във връзка със закупуване на недвижим имот, който е находящ в гр. Пловдив, р-н Централен',
            time: '11:02 AM',
            status: 'waiting',
            backgroundImage: ProfilePhotoMan,
            userRole: 'Адвокат'
        }
    ],
    sender: null,
    recipient: null,
    newMessage: '',
    loading: 'idle',
    error: null,
}

export const sendMessage = createAsyncThunk(
    "messages/sendMessage",
    async (_, thunkAPI) => {
        try {
            const token = Cookie.get('userToken');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.post('/messages', config);
            return await response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);

export const getMessages = createAsyncThunk(
    "messages/getMessages",
    async (_, thunkAPI) => {
        try {
            const token = Cookie.get('userToken');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const response = await instance.get('/messages', config);
            return await response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
);

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.loading = 'loaded';
                state.messages.push(action.payload);
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.payload;
            })
            .addCase(getMessages.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(getMessages.fulfilled, (state, action) => {
                state.loading = 'loaded';
                state.messages = action.payload;
            })
            .addCase(getMessages.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.payload;
            })
    }
})

export default messageSlice.reducer;