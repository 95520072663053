import React, { useState, useEffect, useRef } from 'react';
import { getUserLang } from '../../hooks/useTranslator';
import { useMediaQuery } from 'react-responsive';

const CustomPlacesAutocomplete = ({ value, onSelect, onChange,isHomePage, children }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [shouldFetch, setShouldFetch] = useState(true);
  const containerRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(true);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!shouldFetch || inputValue.trim() === '') return;

      setLoading(true);
      try {
        const { AutocompleteSessionToken, AutocompleteSuggestion } =
          await google.maps.importLibrary("places");

        const request = {
          input: inputValue,
          sessionToken: new AutocompleteSessionToken(),
        };

        const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
        setSuggestions(suggestions);
        setShowSuggestions(true); 
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [inputValue, shouldFetch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
    setActiveSuggestion(-1);
    setShowSuggestions(true);
    setShouldFetch(true);
  };

  const handleSuggestionClick = (suggestion) => {
    const selectedAddress = suggestion.placePrediction.text.toString();
    setInputValue(selectedAddress);
    onSelect(selectedAddress);
    setSuggestions([]);
    setShowSuggestions(false);
    setShouldFetch(false);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const handleMouseEnter = (index) => {
    setActiveSuggestion(index);
  };

  const getInputProps = (props) => ({
    value: inputValue,
    onChange: handleInputChange,
    ...props,
  });
  const userLanguage = getUserLang();
  const getSuggestionItemProps = (suggestion, index) => ({
    onClick: () => handleSuggestionClick(suggestion),
    onMouseEnter: () => handleMouseEnter(index),
    style: {
      backgroundColor: activeSuggestion === index ? "#00BCD4" : "#ffffff",
      fontSize: isHomePage?isMobile ? "20px" : "30px": "18px",
      fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
      fontFamily: "Manrope",
      color: "#000000",
      padding: "5px",
      cursor: "pointer",
      marginLeft:isHomePage?"15px":"0px"
    },
  });

  return (
    <div ref={containerRef}>
      {children({
        getInputProps,
        suggestions: showSuggestions ? suggestions : [],
        getSuggestionItemProps,
        loading,
        suggestionsRef, // Pass ref for height adjustment
      })}
    </div>
  );
};

export default CustomPlacesAutocomplete;
