import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookie from 'js-cookie'
import instance from '../../config/axiosConfig'
import { getLocalStorageItem } from '../../config/localStorageEncryption';

let initialState = {
    contractDetails: {
        activePersona: null,
        isOpenModal: false
    }
}

const getToken = () => Cookie.get('userToken');
let activePersona = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser")?.activePersona : null;

export const commonSignContractWithAbidors = createAsyncThunk(
    `${activePersona}/signAgreement`,
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add('open');
        try {
            const token = getToken();

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                   "Content-Type":"multipart/form-data"
                },
            }

            if (getLocalStorageItem("authenticatedUser") ) {
                activePersona = getLocalStorageItem("authenticatedUser")?.activePersona;
            }

            const response = await instance.post(`/${activePersona}/signAgreement`, payload?.body, config);

            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });


export const commonSignContractWithAbidorsLawyer = createAsyncThunk(
    `${activePersona}/signAgreement`,
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add('open');
        try {
            const token = getToken();

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type":"multipart/form-data"
                },
            }

            const lawyerId = getLocalStorageItem("activePersonaDetails") 

            const response = await instance.put(`/lawyers/${lawyerId?.lawyerPersonaId}/documents`, payload?.body, config);
            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const commonSignContractWithAbidorsPhotographer = createAsyncThunk(
    `${activePersona}/signAgreement`,
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add('open');
        try {
            const token = getToken();

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type":"multipart/form-data"
                },
            }

            const photographerId = getLocalStorageItem("activePersonaDetails") 

            const response = await instance.put(`/photographers/${photographerId?.photographerPersonaId}/agreement`, payload?.body, config);
            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const commonSignContractWithAbidorsVirtualAssistant = createAsyncThunk(
    `${activePersona}/signAgreement`,
    async (payload, thunkAPI) => {
        document.getElementById("loading-indicator").classList.add('open');
        try {
            const token = getToken();

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type":"multipart/form-data"
                },
            }

            const virtualAssistantId = getLocalStorageItem("activePersonaDetails") 

            const response = await instance.put(`/virtual-assistants/${virtualAssistantId?.virtualAssistantId}/documents`, payload?.body, config);
            document.getElementById("loading-indicator").classList.remove('open');

            return response
        } catch (error) {
            document.getElementById("loading-indicator").classList.remove('open');
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

const contractSlice = createSlice({
    name: `${activePersona}`,
    initialState,
    reducers: {
        contractDetails: (state, action) => {
            return {
                ...state,
                contractDetails: {
                    activePersona: activePersona,
                    ...state.contractDetails,
                    ...action?.payload
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(commonSignContractWithAbidors.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(commonSignContractWithAbidors.fulfilled, (state, { payload }) => {
                state.loading = 'loaded'
                state.contractDetails = payload.data
            })
            .addCase(commonSignContractWithAbidors.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                state.contractDetails = []
                console.error(action.error.message)
            });

    }
})

export const { contractDetails } = contractSlice.actions;
export default contractSlice.reducer