import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  messageNotifications,
  notifications,
  readNotifications,
} from "../../../features/user/authSlice";
import "./HeaderVirtualAssistant.css";

import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";
import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../features/property/propertySlice";
import {
  getVirtualAssistantCertifications,
  virtualAssistantRedirectNotification,
} from "../../../features/user/virtualAssistantSlice";
import {
  listServiceRequests,
  resetServiceRequest,
} from "../../../features/common/serviceRequestSlice";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
import { getUserLang } from "../../../hooks/useTranslator";

/* Components */
import MessageNotification from "../../common/Notifications/MessageNotification";
import Notifications from "./../../common/Notifications/Notification";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import SideBar from "./sideBar";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";
import CustomPlacesAutocomplete from "../../common/CustomPlacesAutocomplete";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import { BookOpenIcon as BookOpenIconOutline } from "@heroicons/react/24/outline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BookIcon from "../../common/BookIcon/BookIcon";
/* Images */
const AccountGroup = Images["account-group.svg"];
const TextBoxCheckOutline = Images["text-box-check-outline.svg"];
const Logo = Images["Logo.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];
const GroupPropertyDetails = Images["GroupPropertyDetails.svg"];
const SendButtonArrow = Images["favicon.png"];

const HeaderVirtualAssistant = () => {
  const showMenu = useMediaQuery({ query: "(max-width: 1127px)" });
  const isMobilePhone = useMediaQuery({ query: "(max-width: 767px)" });

  const colorValue = useMemo(() => ({ color: "#fff" }), []);

  const userLanguage = getUserLang();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const persona = getLocalStorageItem("activePersonaDetails");
  const virtualAssistantId = persona?.virtualAssistantId;

  const [address, setAddress] = useState("");

  const [openNotificaration, setOpenNotification] = useState(false);
  const [openNotificaration2, setOpenNotification2] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const messageRef = useRef(null);
  const messageRef2 = useRef(null);

  useEffect(() => {
    dispatch(getVirtualAssistantCertifications(virtualAssistantId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualAssistantId]);

  /* Handle click search icon */
  const handleClick = () => {
    navigate("/search-properties");

    dispatch(
      filtersProperty({
        otherPromises: {},
        propertyTypes: [],
        propertyNeighborhood: [],
        scrollPosition: 0,
        selectCloseTo: [],
        selectConstructionStage: [],
        selectConstructionType: [],
        selectEquipment: [],
        selectFloorType: [],
        selectHeating: [],
        selectOtherPremises: [],
        valueBudget: [0, 500000],
        address: "",
        beforeSelectNeighbothood: "",
        continent: "",
        country: "",
        municipality: "",
        neighborhood: "",
        region: "",
        locationLatitude: "",
        locationLongitude: "",
        sortBy: {},
        maxSquareMeter: "",
        minSquareMeter: "",
        maxYearBuilt: "",
        minYearBuilt: "",
        originalSelectedAddress: "",
      })
    );

    dispatch(
      filtersProperty({
        scrollPosition: 0,
      })
    );
  };

  /* Handle select cities */
  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);

    if (
      !resultAddress?.formatted_address &&
      !resultAddress?.latitude &&
      !resultAddress?.longitude &&
      !resultAddress?.municipality &&
      !resultAddress?.region
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: resultAddress?.formatted_address,
        originalSelectedAddress: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      } else {
        newSearchQuery.municipality = "";
      }

      if (resultAddress?.neighborhood) {
        newSearchQuery.neighborhood = resultAddress?.neighborhood;
      } else {
        newSearchQuery.neighborhood = "";
      }

      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      } else {
        newSearchQuery.region = "";
      }

      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      } else {
        newSearchQuery.continent = "";
      }

      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      } else {
        newSearchQuery.country = "";
      }

      if (resultAddress?.neighborhood) {
        newSearchQuery.neighborhoodSearchQuery = resultAddress?.neighborhood;
      } else {
        newSearchQuery.neighborhoodSearchQuery = "";
      }
      dispatch(filtersProperty(newSearchQuery));
      setAddress("");
      navigate("/search-properties");
    }
  };

  /* Handle click notification */
  const handleClickNotification = (item) => {
    let redirectUrl = "";

    if (item?.notificationType === "cancelledJobRequest") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/virtual/dashboard?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "receivePublicReview") {
      redirectUrl =
        "third-party/virtual-assistant-profile/" + virtualAssistantId;
    } else if (item?.notificationType === "receivePayment") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );

      dispatch(resetServiceRequest());
      redirectUrl = "/virtual/dashboard?persona=" + item?.senderPersona + "s";
    }

    if (item?.notificationType === "signViaReferralLink") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );
      dispatch(resetServiceRequest());
      if(item?.senderPersona==="virtualassistant"){
        redirectUrl="/virtual/dashboard?tabPassed=referrals-tab"
      }else{
        redirectUrl = `/virtual/dashboard${
          item?.senderPersona ? `?customerType=${item?.senderPersona}` : ""
        }${
          item?.serviceRequestID
            ? `&activeServiceRequest=${item?.serviceRequestID}`
            : ""
        }`;
      }
    }

    if (item?.notificationType === "receivePayment") {
      redirectUrl = `/property-details?${item.propertyId}`;
    } else if (item?.notificationType === "atomChatNewMessage") {
      if (item?.senderPersona === "buyer" || item?.senderPersona === "seller") {
        redirectUrl = `/virtual/dashboard${
          item?.senderPersona ? `?customerType=${item?.senderPersona}` : ""
        }${
          item?.serviceRequestID
            ? `&activeServiceRequest=${item?.serviceRequestID}`
            : ""
        }`;
      }
    } else if (item?.notificationType === "receiveJobRequest") {
      redirectUrl = "/virtual/dashboard?receiveRequest";
    }

    dispatch(virtualAssistantRedirectNotification(redirectUrl));

    dispatch(readNotifications(item?.notificationId)).then((r) => {
      if (r?.payload?.data?.status) {
        setOpenNotification(false);
        dispatch(notifications());
        dispatch(
          messageNotifications({
            filters: { notificationType: "atomChatNewMessage" },
          })
        );
      }
    });
  };

  /* Handle focus input search filed */
  const handleFocus = () => {
    setIsFocused(true);
  };

  /* Handle unfocus input search filed */
  const handleBlur = () => {
    setIsFocused(false);
  };

  /* Scroll to top even if I'm on the same page */
  const handleClickScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickMobileBookIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScrollToPricingPage = () => {
    navigate("/pricing", { state: { commingHomePage: true } });
  };
  const handleOpenWelcomeToAbidorspage = () => {
    navigate("/welcome-to-abidors");
    window.scrollTo(0, 0);
  };
  return (
    <header className="header-virtual-assistant">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="clearfix" style={{ width: "100%" }}>
            <Link to="/virtual" className="navbar-logo">
              {isMobilePhone ? (
                <img
                  loading="lazy"
                  src={SendButtonArrow}
                  className="logo-mobile"
                  alt=""
                />
              ) : (
                <img loading="lazy" src={Logo} alt="" />
              )}
            </Link>

            <ul className="nav-menu-mobile">
              <li className="languages">
                <LangSwitcher />
              </li>
            </ul>
            <ul className="nav-menu">
              <li className="languages">
                <LangSwitcher />
              </li>

              <li className="nav-item">
                <Link
                  to="/virtual"
                  className={
                    location?.pathname === "/virtual"
                      ? "nav-links active"
                      : "nav-links"
                  }
                >
                  {" "}
                  {t("Начало")}{" "}
                </Link>
              </li>

              <li className="nav-item" onClick={handleClickScrollToTop}>
                <Link
                  to="/virtual/dashboard?tabPassed=dashboard-tab"
                  className={
                    location?.pathname === "/virtual/dashboard" &&
                    location?.search === "?tabPassed=dashboard-tab"
                      ? "nav-links active"
                      : "nav-links"
                  }
                >
                  {" "}
                  {t("Табло за управление")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/third-party?lawyers"
                  className={
                    location?.pathname === "/third-party"
                      ? "nav-links active"
                      : "nav-links"
                  }
                >
                  {" "}
                  {t("Професионалисти")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/blog"
                  className={
                    location?.pathname === "/blog"
                      ? "nav-links active"
                      : "nav-links"
                  }
                >
                  {" "}
                  {t("Блог")}{" "}
                </Link>
              </li>
              <li className="nav-item">
                <div
                  className="va-bonuses-button"
                  onClick={handleOpenWelcomeToAbidorspage}
                >
                  <BookIcon className="icon" />{" "}
                  <p className="text">{t("VA книга")}</p>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="va-bonuses-button"
                  onClick={handleScrollToPricingPage}
                >
                  <BookIcon className="icon" />{" "}
                  <p className="text">{t("Бонуси")}</p>
                </div>
              </li>
            </ul>

            <div className="right-part">
              {getLocalStorageItem("activePersonaDetails") ? (
                getLocalStorageItem("activePersonaDetails")?.isCertified ? (
                  <div
                    className="account-group"
                    onClick={handleClickScrollToTop}
                  >
                    <Link
                      to="/virtual/dashboard?tabPassed=referrals-tab"
                      className="clearfix link"
                    >
                      <img loading="lazy" src={AccountGroup} alt="" />

                      <p className="title">{t("МОИТЕ РЕФЕРАЛИ")}</p>
                    </Link>
                  </div>
                ) : (
                  <div className="account-group">
                    <Link to="/virtual/education" className="clearfix link">
                      <img loading="lazy" src={TextBoxCheckOutline} alt="" />

                      <p className="title">{t("Изкарай теста")}</p>
                    </Link>
                  </div>
                )
              ) : null}
              <div>
                <div
                  className="mobile-book-icon"
                  onClick={handleClickMobileBookIcon}
                >
                  <BookIcon />
                </div>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "fit-content",
                        gap: "7px",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenWelcomeToAbidorspage}
                    >
                      <BookIcon />
                      <p
                        style={{
                          height: "fit-content",
                          margin: "auto",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {t("VA книга")}
                      </p>
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleScrollToPricingPage}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "fit-content",
                        gap: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <BookIcon />
                      <p
                        style={{
                          height: "fit-content",
                          margin: "auto",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {t("Бонуси")}
                      </p>
                    </div>
                  </MenuItem>
                </Menu>
              </div>

              <div className="notification-icon">
                <Notifications
                  handleNotification={handleClickNotification}
                  setOpenNotification={setOpenNotification}
                  openNotificaration={openNotificaration}
                  messageRef={messageRef}
                />
              </div>

              <div className="notification-icon">
                <MessageNotification
                  handleNotification={handleClickNotification}
                  setOpenNotification={setOpenNotification2}
                  openNotificaration={openNotificaration2}
                  messageRef={messageRef2}
                />
              </div>

              <div className="bell">
                <ProfileMenu personaId={virtualAssistantId} />
              </div>

              {showMenu && (
                <div className="sidebar-menu-icon">
                  <SideBar virtualAssistantId={virtualAssistantId} />
                </div>
              )}
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default HeaderVirtualAssistant;
